import MaterialTable, { type Localization } from "material-table";
import type { MTQuery, MTResponse } from "components/Controller/UController";
import type { SeatGroup } from "components/ClassWrapper/SeatGroup";
import UController from "components/Controller/UController";
import Material from "components/ClassWrapper/Material";
import { BED, CHAIR } from "components/ClassWrapper/SeatType";
import { useCallback, useRef, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { logErrorGroup } from "Utils";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";

const fetchPage = (query: MTQuery<SeatGroup>): MTResponse<SeatGroup> => {
    const filters = query.filters;
    // Build search query
    const searchQuery: string[] = ["type==WING"];
    filters.forEach(filter => {
        switch (filter.column.field) {
            case "name":
            case "description":
            case "externalId.value":
                searchQuery.push(`${filter.column.field}=eqnc="*${filter.value.trim()}*"`);
                break;
            case "enabled":
                searchQuery.push(`enabled==${filter.value === "checked" ? "true" : "false"}`);
                break;
            default:
                break;
        }
    });
    return UController.seatGroup.iQuery(query, searchQuery);
};

const LOCALIZATION: Localization = {
    pagination: {
        labelDisplayedRows: "{from} - {to} / {count}",
        labelRowsSelect: "lignes",
        labelRowsPerPage: "lignes par page",
        firstAriaLabel: "1er page",
        firstTooltip: "1er page",
        previousAriaLabel: "Page précédente",
        previousTooltip: "Page précédente",
        nextAriaLabel: "Page suivante",
        nextTooltip: "Page suivante",
        lastAriaLabel: "Dernière page",
        lastTooltip: "Dernière page",
    },
    body: {
        emptyDataSourceMessage: "Aucune aile trouvée",
        editRow: {
            deleteText: "Êtes-vous sûr de supprimer cette aile ?",
            cancelTooltip: "Annuler",
            saveTooltip: "Sauvegarder",
        },
        deleteTooltip: "Désactiver",
        editTooltip: "Modifier",
        filterRow: {
            filterTooltip: "Filtrer"
        },
    },
    header: {
        actions: "Actions",
    },
}

const renderSeat = (seat: Material, selected?: boolean = true, onClick?: (e: Event) => void) => <span className="label" key={seat.id} style={{ background: !selected ? "rgba(47, 53, 66,.05)" : undefined }} onClick={typeof onClick === "function" ? onClick : undefined}><i className={`text-center fas ${seat.type.seatType === BED ? "fa-bed"
    : seat.type.seatType === CHAIR ? "fa-chair"
        : "fa-question-circle"
    }`} /> {seat.number}</span>

const renderSeats = (rowData: SeatGroup) => rowData.seats && <p className="d-flex align-content-start align-items-center flex-wrap">{rowData.seats.map(s => renderSeat(s))}</p>

type SeatGroupWingFormProps = {
    target?: SeatGroup,
    closeForm: () => void,
    selectableSeats: Material[],
    onSubmitFormSuccess: () => void,
}

const SeatGroupWingForm = (props: SeatGroupWingFormProps) => {
    const [error, setError]: [String, (e: String) => void] = useState();
    const nameRef = useRef();
    const descriptionRef = useRef();
    const externalIdValueRef = useRef();
    const [selectedSeatIds, setSelectedSeatIds] = useState(props.target?.seats?.map(s => s.id) ?? []);

    const toggleSelectedSeatId = useCallback((seat: Material) => setSelectedSeatIds(selectedIds => selectedIds.includes(seat.id) ? selectedIds.filter(id => id !== seat.id) : selectedIds.concat(seat.id)), [setSelectedSeatIds]);

    const handleSubmit = useCallback(() => {
        // Value check
        if (!nameRef.current?.value?.trim()) {
            setError("L'aile devrait avoir un nom défini");
            return;
        }
        // Submit
        (props.target ? UController.seatGroup.patch : UController.seatGroup.post)({
            ...props.target,
            type: "WING",
            name: nameRef.current?.value?.trim(),
            description: descriptionRef.current?.value?.trim(),
            externalId: {
                ...props.target?.externalId,
                value: externalIdValueRef.current?.value?.trim()
            },
            seats: selectedSeatIds.map(id => ({ id })) // UController will map id to URI later
        })
            .then(() => props.onSubmitFormSuccess())
            .catch(error => {
                logErrorGroup(error, "seat_groups.upsert");
                setError("Une erreur est survenue. Veuillez ressayer ultérieurement");
                return;
            });
    }, [props, nameRef, descriptionRef, externalIdValueRef, selectedSeatIds]);

    return (
        <>
            <ModalHeader toggle={props.closeForm}>{!props.target ? "Créer une nouvelle aile" : `Modifier l'aile ${props.target.name}`}</ModalHeader>
            <ModalBody>
                {!!error && <p style={{ background: "#f5365c", padding: 5, color: "#fff", textAlign: 'center', fontWeight: 400 }}>{error}</p>}
                <Col xs={12}>
                    <FormGroup key={"name"}>
                        <Label>Nom de l'aile (*)</Label>
                        <Input defaultValue={props.target?.name ?? ""}
                            innerRef={nameRef}
                        />
                    </FormGroup>
                    <FormGroup key={"description"}>
                        <Label>Description de l'aile</Label>
                        <Input defaultValue={props.target?.description ?? ""}
                            innerRef={descriptionRef}
                        />
                    </FormGroup>
                    <FormGroup key={"externalIdValue"}>
                        <Label>Transcodage de l'aile</Label>
                        <Input defaultValue={props.target?.externalId?.value ?? ""}
                            innerRef={externalIdValueRef}
                        />
                    </FormGroup>
                    <FormGroup key={"seats"}>
                        <Label>Hébergement(s) affecté(s) à l'aile: </Label>
                        <p className="d-flex align-content-start align-items-center flex-wrap">
                            {
                                props.selectableSeats.map(s => renderSeat(s, selectedSeatIds.includes(s.id), () => toggleSelectedSeatId(s)))
                            }
                        </p>
                    </FormGroup>
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.closeForm}>Annuler</Button>
                <Button color="primary" onClick={handleSubmit}>{props.target ? "Modifier" : "Ajouter"}</Button>
            </ModalFooter>
        </>
    );
}

type OpenFormType = {
    action: "create" | "modify",
    target?: SeatGroup,
}

const SyncSeatGroupWingManager = (props: {
    selectableSeats: Material[],
}) => {
    const tableRef = useRef();
    const [openForm, setOpenForm]: [OpenFormType | null, (of?: OpenFormType) => void] = useState(null);

    const closeForm = useCallback(() => setOpenForm(() => null), [setOpenForm]);
    const toggleStatus = useCallback((rowData: SeatGroup) => {
        UController.seatGroup.patch({
            ...rowData,
            enabled: !rowData.enabled,
        }).then(() => tableRef.current?.onQueryChange())
            .catch(error => {
                logErrorGroup(error, "seat_groups.toggle_status");
                alert("Une erreur est survenue. Veuillez ressayer ultérieurement");
            });
    }, [tableRef]);
    const onSubmitFormSuccess = useCallback(() => {
        closeForm();
        tableRef.current?.onQueryChange();
    }, [closeForm, tableRef]);

    return (<>
        <Modal style={{ maxWidth: '1200px', width: '100%' }} isOpen={!!openForm} toggle={closeForm}>
            {
                openForm &&
                <SeatGroupWingForm
                    selectableSeats={props.selectableSeats}
                    target={openForm.target}
                    closeForm={closeForm}
                    onSubmitFormSuccess={onSubmitFormSuccess} />
            }
        </Modal>
        <MaterialTable
            title={"Ailes"}
            tableRef={tableRef}
            columns={[
                {
                    field: "enabled",
                    title: "Active",
                    type: "boolean",
                    width: "5%",
                    align: "center"
                },
                {
                    field: "name",
                    title: "Nom",
                    width: "15%",
                },
                {
                    field: "description",
                    title: "Description",
                    width: "20%",
                },
                {
                    field: "externalId.value",
                    title: "Transcodage",
                    width: "20%",
                },
                {
                    field: "seats",
                    title: "Hébergement(s)",
                    render: renderSeats,
                    filtering: false,
                    width: "30%",
                }
            ]}
            data={fetchPage}
            options={{
                exportButton: false,
                filtering: true,
                search: false,
                debounceInterval: 1000,
                draggable: false,
                addRowPosition: "first",
                tableLayout: "fixed"
            }}
            localization={LOCALIZATION}
            actions={[
                {
                    icon: "add",
                    tooltip: "Ajouter",
                    isFreeAction: true,
                    onClick: () => setOpenForm({
                        action: "create"
                    })
                },
                (rowData) => ({
                    icon: "edit",
                    tooltip: "Modifier",
                    onClick: (event, rowData) => setOpenForm({
                        action: "modify",
                        target: rowData
                    })
                }),
                (rowData) => ({
                    icon: rowData.enabled ? "delete" : "rotate_left",
                    tooltip: rowData.enabled ? "Désactiver" : "Réactiver",
                    onClick: (event, rowData) => toggleStatus(rowData)
                }),
            ]}
        />
    </>)
}

const SeatGroupWingManager = () => (
    <AsyncLoaderWrapper loader={() =>
        UController.material.getAllActive()
            .then((seats) => ({
                selectableSeats: seats.sort((s1, s2) => s1.number.localeCompare(s2.number))
            }))}>
        <SyncSeatGroupWingManager />
    </AsyncLoaderWrapper>
)

export default SeatGroupWingManager;