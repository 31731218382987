import React, { useEffect, useState } from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import UController from "components/Controller/UController";

/**
 *
 * @param {function(Sector)} onSectorChange
 * @param {number} initialSectorId
 * @param {number} _key to reset value
 * @param {Dict<Sector>?} providedDict provided the list instead of fetching from server
 * @return {React.Component}
 * @constructor
 */
const SectorPicker = ({ onSectorChange, initialSectorId, _key, providedDict }) => {
    const [sectorId, setSectorId] = useState(-1);
    const [sectorDict, setSectorDict] = useState({});
    useEffect(() => {
        if (!providedDict)
            UController.sector.getDict().then(setSectorDict).catch(console.error);
        else setSectorDict(providedDict);
    }, [providedDict]);
    useEffect(() => {
        setSectorId(sectorDict[initialSectorId] ? initialSectorId : Object.keys(sectorDict)[0]);
    }, [initialSectorId, sectorDict, _key]);
    useEffect(() => {
        sectorDict && sectorDict[sectorId] &&
            onSectorChange(sectorDict[sectorId]);
    }, [onSectorChange, sectorId, sectorDict]);
    return (
        <FormGroup>
            <Label className="pr-2" style={{ width: '95px' }}>
                Spécialité :
            </Label>
            {
                Object.keys(sectorDict).length === 0 ?
                    <p>Chargement...</p> :
                    <select
                        className="d-inline-block w-auto form-control"
                        value={sectorId}
                        onChange={event => setSectorId(event.target.value)}>
                        {Object.keys(sectorDict)
                            .map(id => <option value={id} key={id}>{sectorDict[id].name}</option>)}
                    </select>
            }
        </FormGroup>
    );
};

export default SectorPicker;