import React from "react";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import ButtonGroup from "reactstrap/es/ButtonGroup";
import Button from "reactstrap/es/Button";
import FullCalendar from "@fullcalendar/react";
import rrulePlugin from "@fullcalendar/rrule";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrap from "@fullcalendar/bootstrap";
import frLocale from '@fullcalendar/core/locales/fr';
import Form from "reactstrap/es/Form";
import { RRule } from "rrule";
import "assets/css/OptaFullCalendar.css";
import Session from "components/ClassWrapper/Session";
import StartRecur from "views/hrmanage/StartRecur";
import EndRecur from "views/hrmanage/EndRecur";
import WorkHour from "views/hrmanage/WorkHour";
import ExDates from "views/hrmanage/ExDates";
import FreqRecur from "views/hrmanage/FreqRecur";
import SectorPicker from "components/picker/SectorPicker";
import ProfessionPicker from "components/picker/ProfessionPicker";
import UController, { ProfessionDescriptors } from "components/Controller/UController";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import Sector from "components/ClassWrapper/Sector";
import Profession from "components/ClassWrapper/Profession";
import { addAllToMap } from "Utils";
import memoize from "memoize-one";
import _ from "lodash";
import { FormGroup, Input, Label } from "reactstrap";
import { Tooltip, Typography } from "@material-ui/core";

// Day of week
const EVENT_STATUS = {
    CONFIRMED: "confirmed",
    TO_CREATE: "to-create",
    TO_DELETE: "to-delete",
    TO_MODIFY: "to-modify",
};

/**
 *
 * @param {Session} session
 * @param {string} id
 * @param {string} color
 * @param {string} title
 * @param {string[]} classNames
 * @return {EventInput}
 */
const getEventInputFrom = (session, id, color, title, classNames) =>
({
    id,
    title: session.name ?? title,
    classNames,
    color,
    duration: new Date("1970-01-01T" + session.range.daily.end + "Z").valueOf()
        - new Date("1970-01-01T" + session.range.daily.start + "Z").valueOf(),
    rrule: [
            "DTSTART:" + session.range.horizon.start.replace(/-/g, "") + "T" + session.range.daily.start.replace(/:/g, "") + "00",
            session.recurrenceRule + ";UNTIL=" + session.range.horizon.end.replace(/-/g, "")
        ]
        .concat(session.exdates.map(exdate => "EXDATE:" + exdate.replace(/-/g, "") + "T" + session.range.daily.start.replace(/:/g, "") + "00Z"))
        .join("\n"),
    extendedProps: session
});

/**
 * @typedef FullCalendarEventObject
 * @property {string} id
 * @property {string} title
 * @property {string} backgroundColor
 * @property {string[]} classNames
 * @property {string} rrule
 * @property {number} duration
 * @property {Session} extendedProps
 */

/**
 *
 */
class SessionEditor extends React.PureComponent {

    props: {
        selectedSession: Session | null,
        /**
         *
         * @param securedSession
         * @param color from Sector
         * @param title from Profession
         */
        onAddSession: (Session, string, string) => void,
        onDeleteSession: Session => void,
        onCancelSessionChanges: () => void,
        /**
         *
         * @param securedSession
         * @param color from Sector
         * @param title from Profession
         */
        onModifySession: (Session, string, string) => void,
        professionDict: Dict<Profession>,
        sectorDict: Dict<Sector>,
        config: OptaConfig
    };

    state : {
        _counter: Number,
        name?: String,
        description?: String
    } = {
        _counter: 1, // Trick to force rerender
        name: this.props.selectedSession?.name,
        description: this.props.selectedSession?.description,
    };

    /**
     * Temporary values for securedSession
     * @type {{owner: {profession: Profession, sector: Sector}, dstart: string, rrule: string, timeIntervals: CompressedTimeInterval[], rruleEnd: string, exdates: string[]}}
     * @private
     */
    _state = {
        owner: {
            /**
             * @type {Sector}
             */
            sector: {},
            /**
             * @type {Profession}
             */
            profession: {},
        },
        /**
         * @type {CompressedTimeInterval[]}
         */
        timeIntervals: [],
        /**
         * @type {string} date in ISO format
         */
        dstart: null,
        /**
         * @type {string} RRule string in RFC-5545
         */
        rrule: null,
        /**
         * @type {string} specifies the last date (exclusive) in ISO format
         */
        rruleEnd: null,
        /**
         * @type {string[]} dates in ISO format
         */
        exdates: [],
    };

    flush = () => {
        this._state = {
            owner: {
                sector: {},
                profession: {},
            },
            timeIntervals: [],
            dstart: null,
            exdates: [],
            rrule: null,
            rruleEnd: null,
        };
        this.setState({
            _counter: this.state._counter + 1,
            name: null,
            description: null
        });
    };

    onSectorChange = sector => this._state.owner.sector = sector;

    onProfessionChange = profession => this._state.owner.profession = profession;

    setTimeIntervals = timeIntervals => this._state.timeIntervals = timeIntervals;

    setDStart = dstart => this._state.dstart = dstart;

    setRRule = rrule => this._state.rrule = rrule;

    setRRuleEnd = rruleEnd => this._state.rruleEnd = rruleEnd;

    setExDates = exDates => this._state.exdates = exDates;

    setName = name => this.setState({name});

    setDescription = description => this.setState({description});

    /**
     *
     * @return {SessionEditorResult} return an error message if any field is left unfilled
     * @private
     */
    getNewSessionFromTempOrErrorMsg = () => {
        if (this._state.timeIntervals.length === 0) return { errorMessage: "Les créneaux horaires doivent être précisés" };
        if (!this._state.dstart) return { errorMessage: "Le début de récurrence doit être précisé" };
        if (!this._state.rrule) return { errorMessage: "La récurrence doit être précisée" };
        if (!this._state.rruleEnd) return { errorMessage: "La fin de récurrence doit être précisée" };
        // Find the start of block
        let tStart = this._state.timeIntervals[0].start;
        this._state.timeIntervals.forEach(interval => interval.start < tStart ? tStart = interval.start + "" : null);
        // Find the end of block
        let tEnd = this._state.timeIntervals[0].end;
        this._state.timeIntervals.forEach(interval => interval.end > tEnd ? tEnd = interval.end + "" : null);
        return {
            result: {
                id: this.props.selectedSession?.id,
                _id: this.props.selectedSession?._id,
                professionId: this._state.owner.profession.id ?? parseInt(Object.keys(this.props.professionDict)[0]),
                sectorId: this._state.owner.sector.id ?? parseInt(Object.keys(this.props.sectorDict)[0]),
                intervals: this._state.timeIntervals,
                range: {
                    horizon: {
                        start: this._state.dstart,
                        end: this._state.rruleEnd,
                    },
                    daily: {
                        start: tStart,
                        end: tEnd,
                    },
                },
                recurrenceRule: this._state.rrule,
                exdates: this._state.exdates.filter(d => d.length > 0),
                name: this.state.name,
                description: this.state.description,
            }
        };
    };

    addSession = () => {
        let newSession = this.getNewSessionFromTempOrErrorMsg();
        if (newSession.result) {
            this.props.onAddSession(
                newSession.result,
                this.props.sectorDict[newSession.result.sectorId].rgbColor,
                this.props.professionDict[newSession.result.professionId].name
            );
            this.flush();
        } else
            alert(newSession.errorMessage ?? "Une erreur est survenue");
    };

    modifySession = () => {
        let newSession = this.getNewSessionFromTempOrErrorMsg();
        if (newSession.result) {
            this.props.onModifySession(
                newSession.result,
                this._state.owner.sector.rgbColor,
                this._state.owner.profession.name,
            );
            this.flush();
        } else
            alert(newSession.errorMessage ?? "Une erreur est survenue");
    };

    deleteSession = () => this.props.onDeleteSession(this.props.selectedSession);

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps !== this.props) {
            this._state = {
                owner: {
                    sector: this.props.sectorDict[this.props.selectedSession?.sectorId] ?? {},
                    profession: this.props.professionDict[this.props.selectedSession?.professionId] ?? {},
                },
                timeIntervals: this.props.selectedSession?.intervals ?? [],
                dstart: this.props.selectedSession?.range.horizon.start,
                exdates: this.props.selectedSession?.exdates ?? [],
                rrule: this.props.selectedSession?.recurrenceRule,
                rruleEnd: this.props.selectedSession?.range.horizon.end ?? null,
            }
        }
    };

    render() {
        /**
         * @type {Session | null}
         */
        let selectedSession = this.props.selectedSession;
        return (
            <Card outline={true}>
                <CardHeader className="mb-1 d-flex align-items-center">
                    <h2 className="text-white flex-grow-1">
                        {
                            selectedSession ?
                                "Modifier/Supprimer le créneau" :
                                "Créer les créneaux"
                        }
                    </h2>
                </CardHeader>
                <CardBody>
                    <Form className="compact-form">
                        <FormGroup row={true}>
                            <Label for="session-name" sm={2} className="mr-0 pr-0">
                                Nom :
                            </Label>
                            <Col sm={9}>
                                <Input
                                    id="session-name"
                                    type="text"
                                    placeholder={"(optionnel)"}
                                    className="mx-auto my-1"
                                    value={this.state.name ?? ""}
                                    onChange={e => this.setName(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row={true}>
                            <Label for="session-description" sm={4}>
                                Description :
                            </Label>
                            <Col xs={12}>
                                <Input
                                    id="session-description"
                                    type="textarea"
                                    placeholder={"(optionnel)"}
                                    className="mx-auto my-1"
                                    value={this.state.description ?? ""}
                                    onChange={e => this.setDescription(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                        <ProfessionPicker onProfessionChange={this.onProfessionChange}
                            initialProfessionId={selectedSession ? selectedSession.professionId : undefined}
                            _key={this.state._counter}
                            providedDict={this.props.professionDict}
                        />
                        <SectorPicker onSectorChange={this.onSectorChange}
                            initialSectorId={selectedSession ? selectedSession.sectorId : undefined}
                            _key={this.state._counter}
                            providedDict={this.props.sectorDict}
                        />
                        <StartRecur onStartRecurChange={this.setDStart}
                            initialStartRecur={selectedSession ? selectedSession.range.horizon.start : undefined}
                            _key={this.state._counter}
                        />
                        <WorkHour onTimeIntervalsChange={this.setTimeIntervals}
                            defaultIntervalStart={this.props.config.openingHour}
                            defaultIntervalEnd={this.props.config.closingHour}
                            initialTimeIntervals={selectedSession ? JSON.parse(JSON.stringify(selectedSession.intervals)) : []}
                            _key={this.state._counter}
                        />
                        <FreqRecur onFreqChange={this.setRRule}
                            initialFreq={selectedSession ? RRule.parseString(selectedSession.recurrenceRule) : {}}
                            _key={this.state._counter}
                        />
                        <EndRecur onEndRecurChange={this.setRRuleEnd}
                            initialEndRecur={selectedSession ? selectedSession.range.horizon.end : undefined}
                            _key={this.state._counter}
                        />
                        <ExDates
                            initialExDates={selectedSession ? selectedSession.exdates : []}
                            onExDatesChange={this.setExDates}
                            _key={this.state._counter}
                        />
                    </Form>
                </CardBody>
                <CardFooter className="border-primary">
                    <ButtonGroup className="float-right">
                        {
                            !selectedSession ?
                                <>
                                    <Button className="bg-danger text-white"
                                        onClick={this.flush}>
                                        Effacer
                                    </Button>
                                    <Button className="bg-success text-white"
                                        onClick={this.addSession}>
                                        Créer
                                    </Button>
                                </>
                                :
                                <>
                                    <Button className="bg-info text-white"
                                        id="reset"
                                        onClick={this.props.onCancelSessionChanges}>
                                        Annuler
                                    </Button>
                                    <Button className="bg-danger text-white"
                                        id="delete-all-occurences"
                                        onClick={this.deleteSession}>
                                        Supprimer
                                    </Button>
                                    <Button className="bg-success text-white"
                                        id="modify-all-occurences"
                                        onClick={this.modifySession}>
                                        Modifier
                                    </Button>
                                </>
                        }
                    </ButtonGroup>
                </CardFooter>
            </Card>
        );
    }
}

class SyncSessionAvailabilityManager extends React.PureComponent {

    props: {
        config: OptaConfig,
        sectorDict: Dict<Sector>,
        professionDict: Dict<Profession>
    };

    state = {
        /**
         * @type {FullCalendarEventObject[]} Sessions to be created, with supplementary attributes for FullCalendar
         */
        eventsToCreate: [],
        /**
         * @type {FullCalendarEventObject[]} Sessions to be deleted, with supplementary attributes for FullCalendar
         */
        eventsToDelete: [],
        /**
         * @type {FullCalendarEventObject[]} Sessions to be modified, with supplementary attributes for FullCalendar
         */
        eventsToModify: [],
        /**
         * @type {FullCalendarEventObject[]} Sessions confirmed on server, with supplementary attributes for FullCalendar
         */
        confirmedSessions: [],
        /**
         * @type {Session | null}
         */
        selectedSession: null,
    };

    /**
     * Id for the sessions to create
     * @type {number}
     * @private
     */
    _counter = 1;

    _state = {
        /**
         * @type {FullCalendarEventObject[]} Cache of sessions created in server, with supplementary attributes for FullCalendar
         */
        eventsConfirmed: [],
    };

    calendarRef = React.createRef();

    /**
     *
     * Fetch confirmed sessions from server
     * @return {Promise<FullCalendarEventObject[]>}
     */
    getConfirmedEvents = () =>
        UController.session.getAll({
            professionIds: Object.keys(this.props.professionDict)
        })
            .then((sessions) => sessions.map(session =>
                getEventInputFrom(
                    session,
                    EVENT_STATUS.CONFIRMED + "-" + session.id,
                    this.props.sectorDict[session.sectorId].rgbColor,
                    this.props.professionDict[session.professionId].name,
                    [EVENT_STATUS.CONFIRMED]
                ))
            );

    getConfirmedEventsAndResetState = (): Promise<Void> =>
        this.getConfirmedEvents()
            .then(confirmedSessions =>
                this.setState({
                    eventsToCreate: [],
                    eventsToModify: [],
                    eventsToDelete: [],
                    selectedSession: null,
                    confirmedSessions
                }));

    /**
     * Flush all temporary modifications
     */
    onFlush = () =>
        (
            this.state.eventsToCreate.length > 0
            || this.state.eventsToDelete.length > 0
            || this.state.eventsToModify.length > 0
            || this.state.selectedSession !== null
        )
        && this.getConfirmedEventsAndResetState();

    /**
     * Register all temporary modifications with server
     */
    onCommit = () => {
        Promise.all([
            this.state.eventsToCreate.length > 0 ?
                UController.session.postAll(this.state.eventsToCreate.map(c => c.extendedProps))
                : null,
            this.state.eventsToDelete.length > 0 ?
                UController.session.deleteAll(this.state.eventsToDelete.map(c => c.extendedProps))
                : null,
            this.state.eventsToModify.length > 0 ?
                UController.session.patchAll(this.state.eventsToModify.map(c => c.extendedProps))
                : null
        ])
        .then(() => setTimeout(this.getConfirmedEventsAndResetState, 300))
        .catch(console.error);
    };

    /**
     *
     * @param {Session} securedSession with a private field _id to identify
     * @param {string} color
     * @param {string} title
     */
    onAddSessionToCreate = (securedSession, color, title) =>
        this.setState({
            eventsToCreate: this.state.eventsToCreate.concat(
                getEventInputFrom(
                    { ...securedSession, _id: this._counter },
                    EVENT_STATUS.TO_CREATE + "-" + (this._counter),
                    color,
                    title,
                    [EVENT_STATUS.TO_CREATE]
                )
            )
        }, () => this._counter++);

    /**
     *
     * @param {Session} securedSession
     */
    onDeleteSession = (securedSession) => {
        if (!!securedSession.id) {
            // A confirmed event => ready to delete
            // Check if is already in to-delete list
            if (this.state.eventsToDelete.find(event => event.extendedProps.id === securedSession.id)) return;
            // Remove visual representation
            if (!this.calendarRef.current) return;
            let fcApi = this.calendarRef.current.getApi();
            let eventApi = fcApi.getEvents().find(event => event._def.extendedProps.id === securedSession.id);
            if (!!eventApi) eventApi.remove(); else return;
            this.setState({
                // Move to to-delete list
                eventsToDelete: this.state.eventsToDelete.concat(
                    getEventInputFrom(
                        securedSession,
                        EVENT_STATUS.TO_DELETE + "-" + securedSession.id,
                        "black",
                        eventApi._def.title,
                        [EVENT_STATUS.TO_DELETE]
                    )
                ),
                // Remove from to-modify list
                eventsToModify: this.state.eventsToModify.filter(
                    event => event.extendedProps.id !== securedSession.id,
                ),
                selectedSession: null,
            });
        } else if (securedSession["_id"]) {
            // A to-create event => removed from candidate list
            this.setState({
                eventsToCreate: this.state.eventsToCreate.filter(event => event.extendedProps._id !== securedSession["_id"]),
                selectedSession: null,
            });
        }
    };

    onCancelSessionChanges = () => this.setState({ selectedSession: null });

    /**
     *
     * @param {Session} securedSession
     * @param {string} color
     * @param {string} title
     */
    onModifySession = (securedSession, color, title) => {
        if (!!securedSession.id) {
            // A confirmed event => ready to modify
            // Remove last visual representation
            if (!this.calendarRef.current) return;
            let fcApi = this.calendarRef.current.getApi();
            let eventApi = fcApi.getEvents().find(event => event._def.extendedProps.id === securedSession.id);
            if (!!eventApi) eventApi.remove(); else return;
            // Update state
            this.setState({
                // Append to to-modify list
                eventsToModify: this.state.eventsToModify.filter(
                    event => event.extendedProps.id !== securedSession.id
                ).concat(
                    getEventInputFrom(
                        securedSession,
                        EVENT_STATUS.TO_MODIFY + "-" + securedSession.id,
                        color,
                        title,
                        [EVENT_STATUS.TO_MODIFY]
                    )
                ),
                // Remove from to-delete list
                eventsToDelete: this.state.eventsToDelete.filter(
                    event => event.extendedProps.id !== securedSession.id
                ),
                selectedSession: null,
            });
        } else if (securedSession["_id"]) {
            // A to-create event
            // Just apply new changes
            this.setState({
                eventsToCreate: this.state.eventsToCreate.filter(
                    event => event.extendedProps._id !== securedSession["_id"]
                ).concat(
                    getEventInputFrom(
                        securedSession,
                        EVENT_STATUS.TO_CREATE + "-" + securedSession["_id"],
                        color,
                        title,
                        [EVENT_STATUS.TO_CREATE],
                    )
                ),
            });
        }
    };

    /**
     * @param {EventApi} event
     */
    selectSession = ({ event }) =>
        this.setState({
            selectedSession: event._def.extendedProps
        });

    componentDidMount = this.getConfirmedEventsAndResetState;

    memo_cloneSelectedSession = memoize(selectedSession => !selectedSession ? null : _.cloneDeep(selectedSession));

    renderEventContent = (innerProps) => (
        innerProps.event.extendedProps.description ?
            <Tooltip title={<Typography color="inherit">{innerProps.event.extendedProps.description}</Typography>}>
                {this.renderEventContentInner(innerProps)}
            </Tooltip>
            : this.renderEventContentInner(innerProps)
    );

    renderEventContentInner = (innerProps) => (
        <div className='fc-event-main-frame'>
            { innerProps.timeText &&
            <div className='fc-event-time'>{ innerProps.timeText }</div>
            }
            <div className='fc-event-title-container'>
                <div className='fc-event-title fc-sticky'>
                    { innerProps.event.title }
                </div>
            </div>
        </div>
    );

    render() {
        const modifyingSessionIds = [...this.state.eventsToDelete, ...this.state.eventsToModify].map(event => event.extendedProps.id);
        const filteredConfirmedSessions = this.state.confirmedSessions.filter(session => !modifyingSessionIds.includes(session.extendedProps.id));
        const selectedSession : Session = this.memo_cloneSelectedSession(this.state.selectedSession);
        return (
            <Card color="primary" outline={true}>
                <CardBody className="pb-5">
                    <Row>
                        <Col xl={8} xs={12}>
                            <FullCalendar
                                ref={this.calendarRef}
                                initialView={"timeGridWeek"}
                                plugins={[dayGridPlugin, timeGridPlugin, bootstrap, rrulePlugin]}
                                themeSystem={"bootstrap"}
                                allDaySlot={false}
                                height={"auto"}
                                locales={[frLocale]}
                                locale={"fr"}
                                events={[
                                    ...this.state.eventsToCreate,
                                    ...this.state.eventsToDelete,
                                    ...this.state.eventsToModify,
                                    ...filteredConfirmedSessions
                                ]}
                                eventClick={this.selectSession}
                                slotMinTime={this.props.config.openingHour}
                                slotMaxTime={this.props.config.closingHour}
                                slotEventOverlap={false}
                                headerToolbar={{
                                    left: "dayGridMonth,timeGridWeek,timeGridDay",
                                    center: "title",
                                    right: "today prev,next"
                                }}
                                eventContent={this.renderEventContent}
                            />
                        </Col>
                        <Col xl={4} xs={12}>
                            <SessionEditor selectedSession={selectedSession}
                                key={selectedSession?.id}
                                onAddSession={this.onAddSessionToCreate}
                                onDeleteSession={this.onDeleteSession}
                                onCancelSessionChanges={this.onCancelSessionChanges}
                                onModifySession={this.onModifySession}
                                professionDict={this.props.professionDict}
                                sectorDict={this.props.sectorDict}
                                config={this.props.config}
                            />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="border-primary">
                    <ButtonGroup className="float-right">
                        <Button className="bg-danger text-white" onClick={this.onFlush}>
                            Annuler
                        </Button>
                        <Button className="bg-success text-white" onClick={this.onCommit}>
                            Appliquer
                        </Button>
                    </ButtonGroup>
                </CardFooter>
            </Card>
        );
    }

    forceCalendarUpdate = () => this.calendarRef.current && this.calendarRef.current.getApi().refetchEvents();
}

const TARGET_SYSTEM: AVAILABILITY_SYSTEM = "SESSION";

class SessionAvailabilityManager extends React.PureComponent {
    render() {
        return (
            <AsyncLoaderWrapper loader={
                () => Promise.all([
                    UController.planning.getConfig(),
                    UController.sector.getDict(),
                    UController.profession.getDict()
                ]).then(([config, sectorDict, professionDict]) => {
                    // Filter profession
                    const selectedProfessionLabels =
                        Object.keys(ProfessionDescriptors)
                            .filter(professionEnum => config.professionAvailabilitySystem[professionEnum] === TARGET_SYSTEM
                                || (!config.professionAvailabilitySystem[professionEnum] && config.professionAvailabilitySystemDefault === TARGET_SYSTEM))
                            .map(professionEnum => ProfessionDescriptors[professionEnum].label);
                    const filteredProfessionDict = addAllToMap(Object.values(professionDict).filter(p => selectedProfessionLabels.includes(p.name)));
                    return ({ config, sectorDict, professionDict: filteredProfessionDict })
                })
            }
                onLoadingMessage={"En cours de chargement de calendrier"}
            >
                <SyncSessionAvailabilityManager />
            </AsyncLoaderWrapper>
        );
    }
}

export default SessionAvailabilityManager;