import React, {PureComponent} from 'react';
import MaterialTable from "material-table";
import {deepClone, MATERIAL_TABLE_LOCALIZATION_FR} from "Utils";
import UController from "components/Controller/UController";
import Sector from "components/ClassWrapper/Sector";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import type {Dict} from "Utils";
import Profession from "components/ClassWrapper/Profession";
import {ROLE_SET, ROLE_TRANSLATE} from "Constants";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import AccountForm from 'components/Forms/AccountForm';
import AccountsAPI from 'api/AccountsAPI';

const localization = deepClone(MATERIAL_TABLE_LOCALIZATION_FR);


/**
 *
 * @type {Object<string, string>}
 */
const ROLE_LOOKUP = {};
ROLE_SET.forEach(r => ROLE_LOOKUP[r] = ROLE_TRANSLATE[r.replace("ROLE_", "")]);

/**
 * Interactive list for account management
 */
class SyncAccountsList extends PureComponent {

    constructor(props){
        super(props);
        this.state = {
            tableRef: React.createRef(),
            modal: false,
            selectedAccount: null
        }
        this.toggle = this.toggle.bind(this);
    }
    

    props: {
        sectorDict?: Dict<Sector>,
        professionDict?: Dict<Profession>,
    };

    
    toggle() {
        this.setState({
          modal: !this.state.modal
        });
    }

    toggleStaffStatus(currentAccount){
        if(currentAccount.enabled){
            if(window.confirm("Voulez-vous désactiver ce compte ?")){
                AccountsAPI.desactivateAccount(currentAccount.id) 
                .then(() => this.state.tableRef.current && this.state.tableRef.current.onQueryChange())
                .catch(error => {console.error(error); alert("Un erreur est survenue. Veuillez ressayer ultérieurement")})
            }
        } else {
            AccountsAPI.reactivateAccount(currentAccount.id) 
            .then(() => this.state.tableRef.current && this.state.tableRef.current.onQueryChange())
            .catch(error => {console.error(error); alert("Un erreur est survenue. Veuillez ressayer ultérieurement")})
        }
    }

    onDataQuery = query => {
        /**
         *
         * @type {Filter[]}
         */
        let filters = query.filters;
        // Build search query
        let searchQuery = [];
        if (filters.length > 0)
            filters.forEach(filter => {
                switch (filter.column.field) {
                    case "staff.firstName":
                    case "staff.lastName":
                    case "username":
                        // Find all protocol including given input
                        searchQuery.push(filter.column.field + "=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    case "staff.professionId":
                        filter.value.length > 0 &&
                        searchQuery.push("staff.profession.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "staff.sectorId":
                        filter.value.length > 0 &&
                        searchQuery.push("staff.sector.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "role":
                        filter.value.length > 0 &&
                        searchQuery.push("role=in=(" + filter.value.join(",") + ")");
                        break;
                    case "enabled":
                        searchQuery.push("enabled==" + (filter.value === "checked" ? "true" : "false"));
                        break;
                    case "idpSource":
                        filter.value.length > 0 &&
                        searchQuery.push("idpSource==(" + filter.value.join(",") + ")");
                        break;
                    default:
                        break;
                }
            });
        return UController.crudAccount.iQuery(query, searchQuery);
    };

    title = "Comptes";

    render() {
        // noinspection JSUnresolvedFunction
        return (
                <>
                <p>
                    <i className="fas fa-info-circle fa-lg mx-2"/>
                    Seuls les comptes « <span className="font-weight-bold text-success">Interne</span> » sont modifiables. 
                    Les comptes « <span className="font-weight-bold text-info">Externe</span> » sont importés depuis votre établissement, ils ne sont donc pas modifiables directement dans l'application Opta HDJ-Planner.
                </p>
                <MaterialTable
                        title={this.title}
                        columns={[
                            {
                                field: "id",
                                hidden: true,
                            },
                            {
                                field: "idpSource",
                                title: "Source",
                                editable: false,
                                lookup: {
                                    "INTERNAL": "Interne",
                                    "EXTERNAL": "Externe"
                                },
                                render: rowData => rowData.idpSource === "INTERNAL" ? <span className="font-weight-bold text-success">Interne</span> : <span className="font-weight-bold text-info">Externe</span>
                            },
                            {
                                field: "enabled",
                                title: "Actif",
                                initialEditValue: true,
                                type: "boolean",
                                filtering: true,
                            },
                            {
                                field: "username",
                                title: "Identifiant",
                                editable: "onAdd",
                            },
                            {
                                field: "role",
                                title: "Role",
                                lookup: ROLE_LOOKUP,
                                filtering: true,
                            },
                            {
                                field: "staff.lastName",
                                title: "Nom",
                                filtering: true,
                            },
                            {
                                field: "staff.firstName",
                                title: "Prénom",
                                filtering: true,
                            },
                            {
                                field: "staff.sectorId",
                                title: "Spécialité",
                                sorting: false,
                                lookup: this.props.sectorDict,
                            },
                            {
                                field: "staff.professionId",
                                title: "Profession",
                                sorting: false,
                                lookup: this.props.professionDict,
                            }
                        ]}
                        data={this.onDataQuery}
                        tableRef={this.state.tableRef}
                        options={{
                            exportButton: true, exportDelimiter: ';',
                            exportFileName: this.title,
                            filtering: true,
                            grouping: false,
                            search: false,
                            debounceInterval: 1000,
                            draggable: false,
                        }}
                        actions={[
                            // {
                            //     icon: "refresh",
                            //     tooltip: "Rafraîchir",
                            //     isFreeAction: true,
                            //     onClick: () => this.onDataQuery()
                            // },
                            {
                                icon: "add",
                                tooltip: "Ajouter",
                                isFreeAction: true,
                                onClick: () => {
                                    this.setState({selectedAccount: null})
                                    this.toggle()
                                }
                            },
                            (rowData) => {
                                return {
                                    disabled: rowData.idpSource === "EXTERNAL",
                                    icon: rowData.enabled ? "delete" : "rotate_left",
                                    tooltip: rowData.enabled ? "Désactiver" : "Activer",
                                    onClick: (event, rowData) => this.toggleStaffStatus(rowData)};
                            },
                            (rowData) => {
                                return {
                                  icon: "edit",
                                  tooltip: "Modifier",
                                  disabled: rowData.idpSource === "EXTERNAL",
                                  onClick: (event, rowData) =>{
                                    this.setState({selectedAccount: rowData})
                                    this.toggle()
                                  }
                                };
                            },
                        ]}
                        localization={localization}
                />
                 <Modal style={{maxWidth: '1000px', width: '100%'}} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.state.selectedAccount ? "Modifier" : "Ajouter"} un compte</ModalHeader>
                    <ModalBody>
                        <AccountForm 
                            account={this.state.selectedAccount}
                            handleSuccess={() => {
                                this.toggle();
                                this.state.tableRef.current && this.state.tableRef.current.onQueryChange()
                                this.setState({selectedAccount: null})
                            }}/>
                    </ModalBody>
                 
                </Modal>
            </>
        );
    }
}

class AccountsList extends React.PureComponent {
    render() {
        return (
                <AsyncLoaderWrapper loader={() =>
                        Promise.all([
                            UController.profession.getNameDict(),
                            UController.sector.getNameDict()])
                                .then(([professionDict, sectorDict]) => ({professionDict, sectorDict}))}
                                    onLoadingMessage={"Liste des comptes en cours de chargement"}>
                    <SyncAccountsList/>
                </AsyncLoaderWrapper>
        );
    }
}

export default AccountsList;