import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader, Form, FormGroup, ButtonGroup, Button} from "reactstrap";
import Staff from "components/ClassWrapper/Staff";
import EntityPicker from "components/picker/EntityPicker";
import {getFullNameWithPrefix} from "Utils";

const StaffPicker = ({onFindSuggestion, onChooseSuggestion, renderStaff}: {
    onFindSuggestion: (hint: string) => Promise<Array<Staff>>,
    onChooseSuggestion: (selectedStaff: Staff) => void,
    renderStaff?: (s: Staff) => any,
}) => {
    const getFilteredSuggestions = (hint: string): Promise<Array<Staff>> => {
        hint = hint.trim();
        if (hint.length === 0)
            return Promise.resolve([]);
        else
            return onFindSuggestion(hint);
    }
    const staffIdGetter = (staff: Staff): string => staff.id;
    const staffRenderer = renderStaff ?? getFullNameWithPrefix;

    return <EntityPicker async
                         onChooseValue={onChooseSuggestion}
                         getFilteredSuggestions={getFilteredSuggestions}
                         placeholder={"Rechercher par une partie du nom ou prénom"}
                         renderSuggestion={staffRenderer}
                         getSuggestionValue={staffIdGetter}/>
};

const AssignmentBox = ({onCancel, onConfirm, message, onFindSuggestion, initialSelectedStaff, renderStaff}: {
    onCancel: () => void,
    onConfirm: (staff: Staff) => void,
    message: React.ReactNode,
    onFindSuggestion: (hint: string) => Promise<Array<Staff>>,
    initialSelectedStaff: ?Staff,
    renderStaff?: (s: Staff) => any,
}) => {
    const [selectedStaff, setSelectedStaff] = useState(initialSelectedStaff);
    const onConfirmWrap = (e) => {
        e.preventDefault();
        onConfirm(selectedStaff);
    }
    const onCancelWrap = (e) => {
        e.preventDefault();
        onCancel();
    }
    const unassignCurrentStaff = (e) => {
        e.preventDefault();
        onConfirm(null);
    };
    const staffRenderer = renderStaff ?? getFullNameWithPrefix;
    return (
            <Modal isOpen={true} zIndex={20000}>
                <ModalHeader className="bg-primary" toggle={onCancel}>
                    <span className="text-white display-4">Affectation de personnel</span>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={onConfirmWrap} className="d-flex flex-column">
                        <FormGroup className="d-flex flex-column justify-content-between">
                            <p>{message}</p>
                            <StaffPicker onFindSuggestion={onFindSuggestion}
                                         onChooseSuggestion={setSelectedStaff}
                                         renderStaff={staffRenderer}/>
                            <p>Sélection actuelle:&nbsp;
                                <span className="text-primary font-weight-bold">
                                    {!!selectedStaff ? staffRenderer(selectedStaff)
                                            : "Personne"
                                    }
                                </span>
                            </p>
                        </FormGroup>
                        <ButtonGroup className="mx-auto">
                            <Button className="bg-success text-white" type={"submit"}>OK</Button>
                            {
                                !!initialSelectedStaff &&
                                <Button className="bg-warning text-white" type={"button"}
                                        onClick={unassignCurrentStaff}>Désaffecter l'actuel</Button>
                            }
                            <Button className="bg-danger text-white" type={"button"}
                                    onClick={onCancelWrap}>Annuler</Button>
                        </ButtonGroup>
                    </Form>
                </ModalBody>
            </Modal>
    )
};

export default AssignmentBox;