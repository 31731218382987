import React from "react";
import { MATERIAL_TABLE_LOCALIZATION_FR, extractHourAndMinFromISOTime, getFullNameWithPrefix, reduceDict, OptaEnumDict } from "Utils";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import Material from "components/ClassWrapper/Material";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import type { DateString } from "components/ClassWrapper/TimeClasses";
import { RdvStatusReduced } from "components/ClassWrapper/RdvStatus";
import MaterialTable from "material-table";

type JobsTableProps = {
    onRdvSelected: (rdv: CompleteRdv) => void,
    dict: OptaEnumDict,
    date: DateString,
    config: OptaConfig,
    completeRdvArray: CompleteRdv[]
}

const JobsTable = (props: JobsTableProps) => {
    return (
        <MaterialTable
            data={props.completeRdvArray}
            columns={[
                {
                    field: "id",
                    hidden: true,
                },
                {
                    field: "patient",
                    title: "Patient",
                    render: (rowData: CompleteRdv): React.ReactNode =>
                        <span>{getFullNameWithPrefix(rowData.patient)}</span>,
                    sorting: false,
                    filtering: false,
                },
                {
                    field: "protocol.name",
                    title: "Protocole",
                },
                {
                    field: "protocol.sectorId",
                    title: "Spécialité",
                    lookup: reduceDict(props.dict.sector, "name"),
                },
                {
                    field: "rdv.status",
                    title: "Etat",
                    sorting: false,
                    lookup: RdvStatusReduced,
                },
                {
                    field: "rdv.place",
                    title: "Place",
                    render: (rowData: CompleteRdv): React.ReactNode =>
                        <span>{Material.getCompoundName(rowData.rdv.place)}</span>,
                    sorting: false,
                    filtering: false,
                },
                {
                    field: "rdv.session.start",
                    title: "Début",
                    render: (rowData: CompleteRdv): React.ReactNode =>
                        <span>{extractHourAndMinFromISOTime(rowData.rdv.session.start)}</span>,
                    filtering: false,
                },
                {
                    field: "rdv.session.end",
                    title: "Fin",
                    render: (rowData: CompleteRdv): React.ReactNode =>
                        <span>{extractHourAndMinFromISOTime(rowData.rdv.session.end)}</span>,
                    filtering: false,
                },
                {
                    field: "rdv.nurse",
                    title: "Infirmière",
                    render: (rowData: CompleteRdv): React.ReactNode =>
                        <span>{getFullNameWithPrefix(rowData.rdv.nurse)}</span>,
                    sorting: false,
                    filtering: false,
                }
            ]}
            options={{
                showTitle: false,
                columnsButton: true,
                search: false,
                filtering: false,
                sorting: true,
                draggable: false,
            }}
            onRowClick={(event, rowData) => props.onRdvSelected(rowData)}
            localization={MATERIAL_TABLE_LOCALIZATION_FR}
        />
    )
}

export default JobsTable;