import React from "react";
import EntityPicker from "components/picker/EntityPicker";
import Protocol from "components/ClassWrapper/Protocol";
import UController from "components/Controller/UController";
import Sector from "components/ClassWrapper/Sector";
import SectorBadge from "views/planning/SectorBadge";

type ProtocolePickerProps = {
    onChooseProtocol: (Protocol) => void,
    sectorDict: {[key: number]: Sector},
}

export default class ProtocolPicker extends React.PureComponent<ProtocolePickerProps> {

    render() {
        return <EntityPicker
            async
            placeholder={"Protocole (obligatoire)"}
            onChooseValue={this.props.onChooseProtocol}
            getFilteredSuggestions={this.getFilteredProtocols}
            getSuggestionValue={this.getProtocolName}
            fillInputValue={this.getProtocolName}
            renderSuggestion={this.renderProtocol}/>;
    }

    getFilteredProtocols = (hint: string): Promise<Protocol[]> => {
        hint = hint.trim();
        if (hint.length === 0)
            return Promise.resolve([]);
        else
            return UController.protocol.searchSuggest(hint);
    };

    getProtocolName = (protocol: Protocol): string => protocol.name;

    renderProtocol = (protocol: Protocol): React.ReactHTMLElement => <span>{protocol.name} {!!this.props.sectorDict[protocol.sectorId] && <SectorBadge sector={this.props.sectorDict[protocol.sectorId]}/>}</span>;
}