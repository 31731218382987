import * as React from "react";
import {Alert, UncontrolledAlert} from "reactstrap";

type AsyncLoaderWrapperProps = {
    /**
     * Async loader. Should return an object in order to destructure
     * @type Function<Promise<T>>
     */
    loader: () => Promise<T>,
    /**
     * A constant string message
     */
    onLoadingMessage?: string,
    /**
     * A constant string message or a function depending on returned error
     * @type string | Function
     */
    onErrorMessage?: string | any => void,
    /**
     * CSS customization
     */
    className?: string,
    /**
     * CSS customization
     */
    style?: Object,
};

class AsyncLoaderWrapper extends React.PureComponent<AsyncLoaderWrapperProps> {

    static defaultProps = {
        onLoadingMessage: "En cours de chargement de composant",
        /**
         * A constant message or a function handling error returned by loader function.
         * @type {string | Function<string>} return a message for user
         */
        onErrorMessage: "Erreur de chargement",
        className: "async-box",
        style: {}
    };

    state = {
        /**
         * @type {boolean}
         */
        resolvedError: false,
        /**
         * @type {boolean}
         */
        resolvedSuccess: false,
        /**
         * @type {Object<*, any>}
         */
        data: null,
        /**
         * @type {any}
         */
        error: null
    };

    componentDidMount() {
        this.props.loader()
                .then(data => this.setState({resolvedSuccess: true, data}))
                .catch(error => {console.error(error); this.setState({resolvedError: true, error})});
    }

    render() {
        if (this.state.resolvedError)
            return (
                    <UncontrolledAlert color="danger">
                        {typeof this.props.onErrorMessage === "string"
                                ? this.props.onErrorMessage
                                : typeof this.props.onErrorMessage === "function"
                                        ? this.props.onErrorMessage(this.state.error)
                                        : "Erreur de chargement"}
                    </UncontrolledAlert>
            );
        else if (this.state.resolvedSuccess)
            return (
                    <div className={this.props.className}
                         style={{...this.props.style}}>
                        {React.Children.map(this.props.children,
                                child => React.cloneElement(child, {...this.state.data}))}
                    </div>
            );
        else
            return (
                    <Alert color="info">
                        {this.props.onLoadingMessage}
                    </Alert>
            );
    }
}

export default AsyncLoaderWrapper;