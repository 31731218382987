import React from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import MaterialsList from "components/InteractiveList/MaterialsList";
import SectorsList from "components/InteractiveList/SectorsList";
import MotivesList from "components/InteractiveList/MotivesList";
import EnvConfigList from "views/Configurations/EnvConfigList";
import {
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import PublicHolidaysManager from './PublicHolidaysManager';
import memoize from "memoize-one";
import SeatGroupWingManager from './SeatGroupWingManager';

type TabDefiniton = {
    key: String,
    label: String,
    component: ReactNode
}

const TABS: TabDefiniton[] = [
    {
        key: "envConfig",
        label: "Planification",
        component: EnvConfigList,
    },
    {
        key: "sectors",
        label: "Spécialités",
        component: SectorsList,
    },
    {
        key: "materials",
        label: "Hébergements",
        component: MaterialsList,
    },
    {
        key: "seatGroups",
        label: "Ailes",
        component: SeatGroupWingManager
    },
    {
        key: "motives",
        label: "Motifs",
        component: MotivesList,
    },
    {
        key: "publicHolidays",
        label: "Jours fériés",
        component: PublicHolidaysManager,
    }
];

/**
 * Page of organization management
 */
export default class Configurations extends React.Component {

    state = {
        activeTab: TABS[0].key
    }

    getTab = (key: String): TabDefiniton => TABS.find(t => t.key === key) ?? TABS[0];

    memo_getTab = memoize(this.getTab);

    render() {
        const currentTab = this.memo_getTab(this.state.activeTab);
        const TabComponent = currentTab.component;
        return (
            <div className='m-3'>
                <Nav tabs className="nav-tabs">
                    {
                        TABS.map(t => <NavItem key={t.key}>
                            <NavLink active={this.state.activeTab === t.key} onClick={() => this.setState({ activeTab: t.key })}>
                                {t.label}
                            </NavLink>
                        </NavItem>)
                    }
                </Nav>
                {
                    <Row className="my-4">
                        <Col xs={12} className="mb-4">
                            <TabComponent/>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}