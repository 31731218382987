import React from "react";
import * as PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import "assets/css/OptaAutoSuggest.css";

/**
 * Picker for entity of class {@link T}
 * @param {any} T entity
 */
export default class EntityPicker extends React.PureComponent {
    static propTypes = {
        async: PropTypes.bool,
        debounce: PropTypes.number,
        className: PropTypes.string,
        clearOnSelect:PropTypes.bool,
        placeholder: PropTypes.string.isRequired,
        /**
         * @function
         * @param {T} chosenSuggestion
         */
        onChooseValue: PropTypes.func.isRequired,
        /**
         * @function
         * @param {string} inputValue given from input field
         * @return {T[] | Promise<T[]>} filtered values from initial suggestions. Promise if async
         */
        getFilteredSuggestions: PropTypes.func.isRequired,
        /**
         * @function
         * @param {T} an object from initial suggestions
         * @return {string} characteristic value of suggestion
         */
        getSuggestionValue: PropTypes.func.isRequired,
        /**
         * @function
         * @param {T} a value from initial suggestions
         * @return {string|ReactElement}
         */
        renderSuggestion: PropTypes.func.isRequired,
        /**
         * @function
         * @param {T} an object from initial suggestions
         * @return {string} characteristic value of suggestion
         */
        fillInputValue: PropTypes.func,
    };

    static defaultProps = {
        className: "",
        async: false,
        debounce: 200,
        clearOnSelect: false
    };

    state = {
        /**
         * Current typed value
         * @type {string}
         */
        value: "",
        /**
         * Initial suggestions
         * @type T[]
         */
        suggestions: [],
    };

    lastRequestId = null;

    /**
     * Debounce if async is set
     * @param value
     */
    fetchSuggestion = (value) => {
        if (this.props.async) { // Given suggest fetching function should return promise
            if (this.lastRequestId !== null)
                clearTimeout(this.lastRequestId);
            this.lastRequestId = setTimeout(() => {
                this.props.getFilteredSuggestions(value)
                    .then(suggestions => this.setState({suggestions}))
                    .catch(() => this.setState({suggestions: []}));
            }, this.props.debounce);
        } else
            this.setState({suggestions: this.props.getFilteredSuggestions(value)})
    };

    onChange = ({type}, {newValue}) => {
        if (type === "change")
            this.setState({value: newValue});
    };

    onSuggestionsFetchRequested = ({value}) => this.fetchSuggestion(value);

    onSuggestionsClearRequested = () => this.setState({suggestions: []});

    onSuggestionSelected = (event, {suggestion}) => {
        this.props.onChooseValue(suggestion);
        !!this.props.clearOnSelect && this.setState({value: ""})
        !!this.props.fillInputValue && this.setState({value: this.props.fillInputValue(suggestion)})
    }

    render() {
        return (
            <Autosuggest
                className={this.props.className}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.props.getSuggestionValue}
                renderSuggestion={this.props.renderSuggestion}
                inputProps={{
                    placeholder: this.props.placeholder,
                    value: this.state.value,
                    onChange: this.onChange
                }}
                onSuggestionSelected={this.onSuggestionSelected}
                focusInputOnSuggestionClick={false}
            />
        );
    }
}