import React, {Component} from 'react';
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import ProtocolsList from "components/InteractiveList/ProtocolsList";

/**
 * Page of protocols management
 */
export default class Protocols extends Component {
    render() {
        return (
            <>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs={12}>
                                <ProtocolsList/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}