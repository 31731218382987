import { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Button,
    ButtonGroup,
    Label,
    Row
} from "reactstrap";
import { AvailabilitySystem } from "components/ClassWrapper/OptaConfig";
import { AVAILABILITY_SYSTEM_LIST, PLANNING_PROFESSION_ENUM, ROLE_TRANSLATE } from "Constants";
import { FormGroup, MenuItem, Select, Radio, RadioGroup, FormControlLabel } from "@material-ui/core"
import UController from "components/Controller/UController";

const Container = styled('div')`

`

const AvailabilitySystemTranslation: Object<AvailabilitySystem, String> = {
    "AVAILABILITY_STAFF": "Nominatif",
    "SESSION": "Non nominatif"
}

const PlanningConfigurationManager = () => {

    const [planningConfig, setPlanningConfig] = useState();

    const [planningConfigModification, setPlanningConfigModification] = useState();

    const handleRadioChange = (profession: string, e: ChangeEvent<HTMLInputElement>) => {
        let draftConfigSystemMap = {...planningConfigModification?.professionAvailabilitySystem} ?? {}
        draftConfigSystemMap[profession] = e.currentTarget.value === "null" ? undefined : e.currentTarget.value;
        setPlanningConfigModification({
            ...planningConfigModification,
            professionAvailabilitySystem: draftConfigSystemMap
        })
    }

    useEffect(() => {
        UController.planning.getConfig().then(config => {
            setPlanningConfig({
                professionAvailabilitySystemDefault: config.professionAvailabilitySystemDefault,
                professionAvailabilitySystem: {...config.professionAvailabilitySystem}
            });
            setPlanningConfigModification({
                professionAvailabilitySystemDefault: config.professionAvailabilitySystemDefault,
                professionAvailabilitySystem: {...config.professionAvailabilitySystem}
            });
        })
    }, []);

    const handleResetValues = () => setPlanningConfigModification({...planningConfig})

    const handleSubmit = () => {
        UController.planning.saveConfig(planningConfigModification)
            .then(config => {
                setPlanningConfig({
                    professionAvailabilitySystemDefault: config.professionAvailabilitySystemDefault,
                    professionAvailabilitySystem: {...config.professionAvailabilitySystem}
                });
                setPlanningConfigModification({
                    professionAvailabilitySystemDefault: config.professionAvailabilitySystemDefault,
                    professionAvailabilitySystem: {...config.professionAvailabilitySystem}
                });
            })
    }


    if (!planningConfigModification)
        return (<></>)

    return (
        <Container>
            <FormGroup>
                <Label for="openingHour">Système par défaut</Label>
                <Select
                    value={planningConfigModification.professionAvailabilitySystemDefault}
                    onChange={e => setPlanningConfig({ ...planningConfigModification, professionAvailabilitySystemDefault: e.target.value })}>
                    {AVAILABILITY_SYSTEM_LIST.map(s => (
                        <MenuItem key={s} value={s}>{AvailabilitySystemTranslation[s]}</MenuItem>
                    ))}
                </Select>
            </FormGroup>

            <FormGroup style={{ marginTop: 30 }}>
                <Label for="openingHour">Système par profession</Label>
                {PLANNING_PROFESSION_ENUM.map((profession: string) => {
                    return (
                        <div key={profession} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                            <span>{ROLE_TRANSLATE[profession] ?? profession}</span>
                            <RadioGroup
                                row={true}
                                aria-label="profession"
                                name={`profession_${profession}`}
                                color="primary"
                                value={planningConfigModification.professionAvailabilitySystem?.[profession] ?? "null"}
                                onChange={e => handleRadioChange(profession, e)}>
                                <FormControlLabel key={"null"} value={"null"} control={<Radio color="primary" />} label="Par défaut" />
                                {
                                    AVAILABILITY_SYSTEM_LIST.map(as => <FormControlLabel key={as} value={as} control={<Radio color="primary" />} label={AvailabilitySystemTranslation[as] ?? as} />)
                                }
                            </RadioGroup>
                        </div>
                    )
                })}
            </FormGroup>

            <Row form className="d-flex flex-row-reverse">
                <ButtonGroup>
                    <Button className="bg-danger text-white" onClick={handleResetValues}>
                        Annuler
                    </Button>
                    <Button className="bg-success text-white" onClick={handleSubmit}>
                        Sauvegarder
                    </Button>
                </ButtonGroup>
            </Row>
        </Container>
    )

}

export default PlanningConfigurationManager