import React, {PureComponent} from 'react';
import MaterialTable from "material-table";
import {MATERIAL_TABLE_LOCALIZATION_FR} from "Utils";
import UController from "components/Controller/UController";
import MaterialType from "components/ClassWrapper/MaterialType";
import Sector from "components/ClassWrapper/Sector";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import type {Dict} from "Utils";

/**
 * Interactive list for protocols management
 */
class SyncMaterialsList extends PureComponent {

    state = {
        tableRef: React.createRef(),
    };

    props: {
        materialTypeDict?: Dict<MaterialType>,
        sectorDict?: Dict<Sector>
    };

    onRowAdd = (newData: Object) => UController.material.post(newData);

    onRowUpdate = (newData) => UController.material.patch(newData);

    onDataQuery = query => {
        /**
         *
         * @type {Filter[]}
         */
        let filters = query.filters;
        // Build search query
        let searchQuery = [];
        if (filters.length > 0)
            filters.forEach(filter => {
                switch (filter.column.field) {
                    case "typeId":
                        if (filter.value.length > 0)
                            searchQuery.push("type.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "sectorId":
                        if (filter.value.length > 0)
                            searchQuery.push("sector.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "enabled":
                        searchQuery.push("enabled==" + (filter.value === "checked" ? "true" : "false"));
                        break;
                    case "number":
                    case "externalId.value":
                        searchQuery.push(`${filter.column.field}=eqnc="*${filter.value.trim()}*"`);
                        break;
                    default:
                        break;
                }
            });
        return UController.material.iQuery(query, searchQuery);
    };

    title = "Hébergements";

    render() {
        // noinspection JSUnresolvedFunction
        return (
                <MaterialTable
                        title={this.title}
                        columns={[
                            {
                                field: "id",
                                title: "Id",
                                hidden: true,
                            },
                            {
                                field: "enabled",
                                title: "Actif",
                                initialEditValue: true,
                                type: "boolean",
                            },
                            {
                                field: "number",
                                title: "Numéro",
                            },
                            {
                                field: "typeId",
                                title: "Type",
                                emptyValue: "",
                                lookup: this.props.materialTypeDict,
                            },
                            {
                                field: "sectorId",
                                title: "Spécialité",
                                emptyValue: "",
                                sorting: false,
                                lookup: this.props.sectorDict,
                            },
                            {
                                field: "externalId.value",
                                title: "Transcodage",
                                type: "string"
                            }
                        ]}
                        data={this.onDataQuery}
                        tableRef={this.state.tableRef}
                        editable={{
                            onRowAdd: this.onRowAdd,
                            onRowUpdate: this.onRowUpdate,
                        }}
                        options={{
                            exportButton: true, exportDelimiter: ';',
                            exportFileName: this.title,
                            filtering: true,
                            grouping: false,
                            search: false,
                            debounceInterval: 1000,
                            draggable: false,
                            addRowPosition: "first"
                        }}
                        actions={[
                            {
                                icon: "refresh",
                                tooltip: "Rafraîchir",
                                isFreeAction: true,
                                onClick: () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange()
                            }
                        ]}
                        localization={MATERIAL_TABLE_LOCALIZATION_FR}
                />
        );
    }
}

class MaterialsList extends React.PureComponent {
    render() {
        return (
                <AsyncLoaderWrapper loader={() =>
                        Promise.all([
                            UController.materialType.getNameDict(),
                            UController.sector.getNameDict()])
                                .then(([materialTypeDict, sectorDict]) => ({materialTypeDict, sectorDict}))}
                                    onLoadingMessage={"En cours de chargement de la list des hébergements"}>
                    <SyncMaterialsList/>
                </AsyncLoaderWrapper>
        );
    }
}

export default MaterialsList;