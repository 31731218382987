import {authCaller as axiosInstance} from "components/Controller/AuthProvider";

function deleteCyclesAndAppointments(cycleIds: string, from: string){
    return axiosInstance.delete(`/planning/cycles/${cycleIds}`, {params: {from}})
    .then(response => response.data)    
}

const CyclesAPI = {
    deleteCyclesAndAppointments
}

export default CyclesAPI;