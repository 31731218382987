if (!window.env) {
    const request = new XMLHttpRequest();
    request.open("GET", "env.json", false); // `false` makes the request synchronous
    request.send(null);
    if (request.status === 200) {
        window.env = Object.freeze(JSON.parse(request.responseText));
    }
}
/**
 * Environment variables
 */
export const env : {
    API_BASE_URL: string,
    API_AUTH_URL: string,
    API_AUTH_KC_REALM: string,
    API_AUTH_KC_CLIENT_ID: string,
    ENABLE_NOMINATIVE_AVAILABILITIES: string,
    AUTH_EXTERNAL_PROVIDER: string,
    DISPLAY_OPTIONS?: {
        PLANNING? : {
            RDV_COLOR?: "BY_SECTOR" | "BY_DOCTOR"
        }
    }
} = window.env;

export const LAYOUT: { [string]: string } = {
    MAIN: "/main",
    AUTH: "/auth",
    PAGE: "/page"
};

/**
 * All roles of application
 * @readonly
 * @type {Object<String, String>}
 */
const ROLE = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    NURSE: "NURSE",
    PHARMACIST: "PHARMACIST",
    DOCTOR: "DOCTOR",
    CARE_GIVER: "CARE_GIVER",
    SHARED_ACCESS: "SHARED_ACCESS",
    SECRETARIAT: "SECRETARIAT",
};
export {ROLE};

export const ROLE_SET: string[] = Object.values(ROLE);

/**
 * Set of roles allowed of full access on this interface
 * @type {string[]}
 */
export const highProfiles: string[] = [ROLE.ADMIN];

/**
 * Set of roles allowed to execution action
 * @type {Object<string, Set<string>>}
 */
export const requiredProfileForAction = {
    MARKING_PRESENCE: new Set([...highProfiles, ROLE.NURSE, ROLE.SHARED_ACCESS, ROLE.CARE_GIVER, ROLE.SECRETARIAT]),
    MARKING_CONSULTATION: new Set([...highProfiles, ROLE.DOCTOR]),
    MARKING_MED_PREP: new Set([...highProfiles, ROLE.PHARMACIST]),
    MARKING_MED_RECEPTION: new Set([...highProfiles, ROLE.NURSE]),
    MARKING_INSTALLATION: new Set([...highProfiles, ROLE.NURSE, ROLE.CARE_GIVER]),
    MARKING_TREATMENT: new Set([...highProfiles, ROLE.NURSE]),
    MARKING_FINISHED: new Set([...highProfiles, ROLE.NURSE, ROLE.CARE_GIVER, ROLE.SECRETARIAT]),
};

export const HOME_PAGE: string = "/";

export const sex: { [string]: string } = {
    "M": "M",
    "F": "F"
};

export const SITE_LOCAL: string = 'fr';

function trimTrailingSlash(url) {
    return url.substr(0, url.endsWith("/") ? url.length - 1 : url.length);
}
export const SERVER_HOST: string = trimTrailingSlash(env.API_BASE_URL);
export const DOMAIN_HOST: string = SERVER_HOST.replace("http://", "").replace("https://", "");
export const WITH_SSL: boolean = SERVER_HOST.startsWith("https");
export const DateTimeLocalFormat = 'YYYY-MM-DDTHH:mm:ss'

/**
 * All roles of application translated by key
 * @readonly
 * @type {Object<String, String>}
 */
export const ROLE_TRANSLATE = {
    SUPER_ADMIN: "Super admin",
    ADMIN: "Administrateur",
    NURSE: "Infirmière",
    PHARMACIST: "Pharmacien",
    DOCTOR: "Médecin",
    CARE_GIVER: "Aide soignant",
    SHARED_ACCESS: "Accès partagé",
    SECRETARIAT: "Secrétaire",
}

export const REGEX_FULLSTRING = /[\w[\]`!@#$%^-]*/
export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_DATE_INPUT_SEARCH = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
export const REGEX_DATE_QUERY = /\d{4}-\d{2}-\d{2}/

export const AVAILABILITY_SYSTEM_LIST = ["AVAILABILITY_STAFF", "SESSION"]
export const PLANNING_PROFESSION_ENUM = ['NURSE', 'DOCTOR', 'PHARMACIST']
/**
 * Internal id of Profession "Doctor"
 */
export const PROFESSION_IID_DOCTOR = "DOCTOR";
/**
 * Internal id of Profession "Nurse"
 */
export const PROFESSION_IID_NURSE = "NURSE";
/**
 * Internal id of Profession "Pharmacist"
 */
export const PROFESSION_IID_PHARMACIST = "PHARMACIST";