import {authCaller as axiosInstance} from "components/Controller/AuthProvider";


function getProtocolById(){
    return axiosInstance.get('/protocols')
    .then(response => response.data)    
}

function createProtocol(payload){
    return axiosInstance.post('/protocols', payload)
    .then(response => response.data)    
}

function patchProtocol(id, payload){
    return axiosInstance.patch('/protocols/'+id, payload)
    .then(response => response.data)    
}

const ProtocolsAPI = {
    getProtocolById,
    createProtocol,
    patchProtocol
}

export default ProtocolsAPI;