/**
 * Simplified Rendezvous in order to send to server
 */
import Rendezvous from "components/ClassWrapper/Rendezvous";

export default class RendezvousWrapper {
    constructor(o: Rendezvous) {
        /**
         * @type {number}
         */
        this.id = o.id;
        /**
         *
         * @type {number}
         */
        this.placeId = o.place.id;
        /**
         * @type {Material}
         */
        this.place = o.place;
        /**
         *
         * @type {boolean}
         */
        this.inNeedConsultation = !!o.inNeedConsultation;
        /**
         *
         * @type {boolean}
         */
        this.consultationInPlace = !!o.consultationInPlace;
        /**
         *
         * @type {boolean}
         */
        this.canPrePrepMed = !!o.canPrePrepMed;
        /**
         *
         * @type {number}
         */
        this.treatmentDuration = o.treatmentDuration;
        /**
         *
         * @type {number}
         */
        this.medPrepDuration = o.medPrepDuration;
        /**
         * iso date
         * @type {string}
         */
        this.sessionDay = o.sessionDay;
        /**
         * @type {{start: string, end: string}}
         */
        this.session = o.session;
        /**
         * @type {{start: string, end: string}}
         */
        this.consultation = o.consultation;
        /**
         * @type {{start: string, end: string}}
         */
        this.installation = o.installation;
        /**
         * iso date
         * @type {string}
         */
        this.medPrepDay = o.medPrepDay;
        /**
         * @type {{start: string, end: string}}
         */
        this.medPrep = o.medPrep;
        /**
         * @type {{start: string, end: string}}
         */
        this.treatment = o.treatment;
    }
}