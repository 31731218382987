import React from "react";
import {Card, CardHeader, CardBody, Alert, CardFooter, Spinner, ButtonGroup, Button, UncontrolledTooltip} from "reactstrap";
import Patient from "components/ClassWrapper/Patient";
import PatientPicker from "components/picker/PatientPicker";
import CycleDemandCreator from "views/planning/CycleDemandCreator";
import DateRangePicker from "components/picker/DateRangePicker";
import {getFullNameWithPrefix, getISODateFromISODateTime, throwTypeError} from "Utils";
import CyclePlanningRequest from "components/LogicClass/CyclePlanningRequest";
import styled from "styled-components";
import Sector from "components/ClassWrapper/Sector";
import type {Dict} from "Utils";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import moment from "moment";
import type { SeatGroup } from "components/ClassWrapper/SeatGroup";

const Container = styled('div')`
    padding: 10px;
  .title{
        margin-bottom: 10px;
        display: block;
        i{
            margin-right: 10px;
            cursor: pointer;
        }
}
`

export default class CycleDemandListCreator extends React.PureComponent {

    props: {
        sendCycleRequests: (Array<CyclePlanningRequest>, string, string, Function) => void,
        lock?: boolean,
        close(): void,
        sectorDict: Dict<Sector>,
        config: OptaConfig,
        seatWings: SeatGroup[],
    };

    static defaultProps = {
        lock: false,
    };

    state = {
        /**
         * @type Patient[]
         */
        selectedPatients: [],
        planningDateRange: {
            /**
             * @type {string}
             */
            startDate: "",
            /**
             * @type {string | null}
             */
            endDate: "",
        },
        msg: "",
        /**
         * @type {Dict<CyclePlanningRequest>} bind patient's id with cycle request
         */
        temporaryCycleRequests: {},
    };

    onChoosePatient = (newChosenPatient: Patient) => {
        // If not found any duplicate
        if (!this.state.selectedPatients.find(sp => sp.id === newChosenPatient.id)) {
            this.setState({
                selectedPatients: this.state.selectedPatients.concat(newChosenPatient),
                temporaryCycleRequests: {
                    ...this.state.temporaryCycleRequests,
                    [newChosenPatient.id]: {
                        patient: newChosenPatient
                    }
                }
            });
        }
    };

    onRemovePatient = (patientToDelete: Patient) => {
        delete this.state.temporaryCycleRequests[patientToDelete.id];
        this.setState({
            selectedPatients: this.state.selectedPatients.filter(v => v.id !== patientToDelete.id),
            temporaryCycleRequests: {
                ...this.state.temporaryCycleRequests
            },
        });
    };

    onChoosePlanningDateRange = (startDate: string, endDate: string | null) =>
            this.setState({
                planningDateRange: {
                    startDate,
                    endDate,
                }
            });

    onClearPlanningRequest = () =>
            this.setState({
                selectedPatients: [],
                msg: "",
                temporaryCycleRequests: {},
            });

    /**
     *
     * @param {CyclePlanningRequest} newCycleRequest
     */
    onUpdateCycleRequest = (newCycleRequest) =>
            this.setState({
                temporaryCycleRequests: {
                    ...this.state.temporaryCycleRequests,
                    [newCycleRequest.patient.id]: newCycleRequest,
                }
            });

    /**
     *
     * @returns {boolean} <tt>true</tt> when all inputs are valid
     * @throws {TypeError} on invalid input
     */
    isAllRequestsValid = (): boolean => {
        Object.keys(this.state.temporaryCycleRequests).length === 0 && throwTypeError("Il faut avoir au moins une requête de planification");
        Object.keys(this.state.temporaryCycleRequests)
                .forEach((patientId: number) => {
                    let req = this.state.temporaryCycleRequests[patientId];
                    (!req.patient || !req.patient.id) && throwTypeError(`Le patient ayant pour l'id ${patientId} n'est pas valide`);
                    let patient = req.patient;
                    (!req.protocol || !req.protocol.id) && throwTypeError(`Le protocole de ${getFullNameWithPrefix(patient)} n'est pas valide`);
                    req.rdvRequests.length === 0 && throwTypeError(`La requête de ${getFullNameWithPrefix(patient)} doit avoir au moins un rendez-vous`);
                    // Check day indexes
                    let rdvRequestCountByDayIndex = {};
                    req.rdvRequests.forEach(rdvReq => {
                        if (!!rdvRequestCountByDayIndex[rdvReq.dayIndex])
                            throwTypeError(`${getFullNameWithPrefix(patient)} ne doit pas avoir 2 rendezvous de même jour`);
                        else
                            rdvRequestCountByDayIndex[rdvReq.dayIndex] = true;
                    })
                });
        return true;
    };

    onSendCycleRequests = () => {
        if (this.props.lock) {
            this.setState({
                msg: "En cours de calcul. Veuillez patienter."
            });
        } else {
            try {
                this.isAllRequestsValid();
                this.props.sendCycleRequests(
                        Object.values(this.state.temporaryCycleRequests),
                        this.state.planningDateRange.startDate,
                        this.state.planningDateRange.endDate,
                        this.onClearPlanningRequest
                );
            } catch (e) {
                this.setState({
                    msg: "Entrée(s) invalide(s): " + e.message
                });
            }
        }
    };

    render() {
        return (
            <Container>
                <span className='title'>
                    <i className='fas fa-arrow-left' onClick={this.props.close}/>
                    Requete de planification
                </span>
                <CardBody>
                    {
                        this.state.selectedPatients.length > 0 ?
                                this.state.selectedPatients.map((patient: Patient) =>
                                        <CycleDemandCreator patient={patient}
                                                            onRemovePatient={this.onRemovePatient}
                                                            onUpdateCycleRequest={this.onUpdateCycleRequest}
                                                            key={patient.id}
                                                            config={this.props.config}
                                                            sectorDict={this.props.sectorDict}
                                                            seatWings={this.props.seatWings}/>)
                                : <p className="text-warning">Aucun patient sélectionné</p>
                    }
                    <Card className="my-1">
                        <CardHeader
                            style={{paddingBottom: "0px !important"}}
                                className="bg-success d-flex">
                            <h3 className="text-white my-auto mr-3">
                                Chercher un patient:
                            </h3>
                            <div style={{width: "50%"}}>
                                <PatientPicker onPatientChosen={this.onChoosePatient}/>
                            </div>
                        </CardHeader>
                        <small>Nom(s) / Prénom / IPP / Date de naissance (JJ/MM/YYYY)</small>
                    </Card>
                </CardBody>
                <CardFooter className="d-flex align-items-start border-primary">
                    <div className="d-flex align-items-baseline ml-auto">
                        {
                            !!this.state.msg &&
                            <Alert color="warning" className="mr-2">
                                {this.state.msg}
                            </Alert>
                        }
                        {
                            this.props.lock && <Spinner color="primary" className="align-self-center mr-2"/>
                        }
                        <ButtonGroup>
                            <Button className="bg-danger text-white" onClick={this.onClearPlanningRequest}>
                                Effacer
                            </Button>
                            <Button className="bg-success text-white" onClick={this.onSendCycleRequests}>
                                Demander
                            </Button>
                        </ButtonGroup>
                        <i className="fas fa-info-circle ml-2"/>
                        <span className="ml-1 my-auto" id="planning-start-date-label">Planification à partir du</span>
                        <UncontrolledTooltip target={"planning-start-date-label"} style={{ width: "max-content" }}>
                            <p>Planification du premier rendez-vous à la date la plus proche possible à partir de celle-ci.</p>
                        </UncontrolledTooltip>
                        <DateRangePicker onRangeChosen={this.onChoosePlanningDateRange}
                                            className="d-flex flex-column flex-shrink-1 ml-2"
                                            startTextPlaceholder="Début (demain, par défaut)"
                                            startDefaultValue={getISODateFromISODateTime(moment().add(1, 'd').toISOString())}
                                            openRight={true}
                        />
                    </div>
                </CardFooter>
            </Container>
        );
    }
}