import axios from "axios";
import {authCaller as axiosInstance} from "../components/Controller/AuthProvider";

function getAvailabilitiesById(type: "regular" | "exceptional", id){
    return axiosInstance.get(`/availability-${type}/${id}`)
    .then(response => response.data)
}

function getAvailabilitiesByQuery(type: "regular" | "exceptional", query, size){
    return axiosInstance.get(`/rsql`, {
        params: {
            type: `availability/${type}`,
            search: query,
            size: size ?? 999
        }
    })
    .then(response => response.data.content)

}

function createAvailability(type: "regular" | "exceptional", payload){
    return axiosInstance.post(`/availability-${type}`, payload)
    .then(response => response.data)
}

function patchAvailability(type: "regular" | "exceptional", id, payload){
    return axiosInstance.patch(`/availability-${type}/${id}`, payload)
    .then(response => response.data)
}

function deleteAvailability(type: "regular" | "exceptional", id){
    return axiosInstance.delete(`/availability-${type}/${id}`)
    .then(response => response.data)
}

function getPublicHolidaysFromPublicAPI(year): Promise<{[date:string]: string}>{
    return axios.get(`https://calendrier.api.gouv.fr/jours-feries/metropole/${year}.json`)
            .then(response => response.data)
}

type AbsenceExceptionalPayload = {
    sectorId: number,
    enabled: boolean,
    comment: string,
    interval: {
        start: string,
        end: string
    }
}

export type AbsenceExceptional = {
    id: number,
    createdDateTime: string,
    lastModifiedDateTime: string,
    sectorId: number,
    capacity: number,
    enabled: boolean,
    comment: string,
    interval: {
        start: string,
        end: string
    },
    multi: boolean,
    new: boolean,
    content?: any,
    links?: any
}

function createAvailabilityAbsenceExceptional(payload: AbsenceExceptionalPayload): Promise<AbsenceExceptional>{
    return axiosInstance.post(`/availability-absence-exceptional`, payload)
    .then(response => response.data)
}

function getAvailabilityAbsenceExceptionalById(id: number): Promise<AbsenceExceptional>{
    return axiosInstance.get(`/availability-absence-exceptional/${id}`)
    .then(response => response.data)
}

function getAvailabilityAbsenceExceptionalByQuery(query: string): Promise<AbsenceExceptional[]>{
    return axiosInstance.get(`/rsql`, {
        params: {
            type: "availability/absence/exceptional",
            search: query
        }
    })
    .then(response => response.data.content)
}

function patchAvailabilityAbsenceExceptional(): Promise<any>{

}

function deleteAvailabilityAbsenceExceptional(id: number){
    return axiosInstance.delete(`/availability-absence-exceptional/${id}`)
    .then(response => response.data)
}

const AvailabilitiesAPI = {
    getAvailabilitiesById,
    getAvailabilitiesByQuery,
    createAvailability,
    patchAvailability,
    deleteAvailability,
    getPublicHolidaysFromPublicAPI,
    getAvailabilityAbsenceExceptionalById,
    getAvailabilityAbsenceExceptionalByQuery,
    patchAvailabilityAbsenceExceptional,
    deleteAvailabilityAbsenceExceptional,
    createAvailabilityAbsenceExceptional
}

export default AvailabilitiesAPI;