import React from "react";
import MaterialTable from "material-table";
import {
    Input,
    Card,
    CardHeader,
    Button,
    CardBody,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";
import Patient from "components/ClassWrapper/Patient";
import Protocol from "components/ClassWrapper/Protocol";
import CyclePlanningRequest, { RdvPlanningRequestStringified } from "components/LogicClass/CyclePlanningRequest";
import Motive from "components/ClassWrapper/Motive";
import type { Dict } from "Utils";
import {
    getFullNameWithPrefix,
    getFullNameWithPrefixDoctor,
    getLocalDateStrFromISO,
    parseSingleTimeStrToMinutes,
    stringifyTimeToHourMin,
} from "Utils";
import ProtocolPicker from "components/picker/ProtocolPicker";
import MotivePicker from "components/picker/MotivePicker";
import UController from "components/Controller/UController";
import Sector from "components/ClassWrapper/Sector";
import CenterDiv from "components/utility/CenterDiv";
import { BED } from "components/ClassWrapper/SeatType";
import { LibraryAdd } from "@material-ui/icons";
import Staff from "components/ClassWrapper/Staff";
import AssignmentBox from "views/jobs/AssignmentBox";
import { PROFESSION_IID_DOCTOR } from "Constants";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import SectorBadge from "views/planning/SectorBadge";
import "assets/css/OptaMTable.css";
import type { SeatGroup } from "components/ClassWrapper/SeatGroup";

const isValidDurationExpression = (inputDurationExpression: string): boolean => {
    try {
        parseSingleTimeStrToMinutes(inputDurationExpression.trim());
    } catch (e) {
        return false;
    }
    return true;
}

const MATERIAL_TABLE_LOCALIZATION_FR = {
    body: {
        emptyDataSourceMessage: "Aucune séance définie",
        editRow: {
            deleteText: "Êtes-vous sûr de supprimer cette séance ?",
            cancelTooltip: "Annuler",
            saveTooltip: "Sauvegarder",
        },
        addTooltip: "Ajouter un RDV libre",
        deleteTooltip: "Supprimer le RDV",
    },
    header: {
        actions: "Actions",
    },
}

const parseIntOrNull = (v: string | null | undefined): number | null => {
    if (v === undefined || v === null || v.trim().length === 0) return null;
    let val = parseInt(v);
    if (isNaN(val)) return null;
    return val;
};

export default class CycleDemandCreator extends React.PureComponent {

    props: {
        patient: Patient,
        onRemovePatient: (patient: Patient) => void,
        onUpdateCycleRequest: (request: CyclePlanningRequest) => void,
        sectorDict: Dict<Sector>,
        config: OptaConfig,
        seatWings: SeatGroup[]
    };

    state: {
        protocol: Protocol,
        motive: Motive | null,
        rdvRequests: RdvPlanningRequestStringified[],
        idCounter: number,
        comment: string | null,
        referentDoctor: Staff | null,
        referentDoctorPermanent: boolean,
        showAssignmentBox: boolean,
        action: string | null,
        patientCommentRef: Ref,
        patientCommentSaved: Boolean,
    } = {
            protocol: null,
            motive: null,
            rdvRequests: [],
            idCounter: 0,
            comment: null,
            referentDoctor: this.props.patient.referentDoctor,
            referentDoctorPermanent: true,
            showAssignmentBox: false,
            action: "",
            patientCommentRef: React.createRef(),
            patientCommentSaved: true,
        };

    onRemovePatient = () => this.props.onRemovePatient(this.props.patient);

    onUpdateCycleRequest = () => this.props.onUpdateCycleRequest({
        patient: this.props.patient,
        protocol: this.state.protocol,
        motive: this.state.motive,
        comment: this.state.comment?.trim(),
        rdvRequests: this.state.rdvRequests.map(RdvPlanningRequestStringified.parse),
        referentDoctor: this.state.referentDoctor
    });

    onChooseProtocol = (chosenProtocol: Protocol) =>
        this.setState({
            protocol: chosenProtocol,
            rdvRequests: CyclePlanningRequest.getInitialRdvRequestsStringifiedFrom(chosenProtocol, this.props.patient, this.props.config),
            idCounter: chosenProtocol.prescriptions.length
        }, this.onUpdateCycleRequest);

    onChooseMotive = (chosenMotive: Motive) => this.setState({ motive: chosenMotive }, this.onUpdateCycleRequest);

    onChooseSeatWing = (choice: SeatGroup) => this.setState({ preferredSeatGroupId: choice }, this.onUpdateCycleRequest);

    handleRowAdd = () => new Promise((resolve) =>
        this.setState({
            idCounter: this.state.idCounter + 1,
            rdvRequests: this.state.rdvRequests.concat({
                id: this.state.idCounter + 1,
                dayIndex: this.state.rdvRequests[this.state.rdvRequests.length - 1].dayIndex + 1,
                daysDelayLimit: "",
                consultationDuration: stringifyTimeToHourMin(this.props.config.consultationLength),
                consultationInPlace: true,
                medPrepAfterInstallation: false,
                medPrepDuration: stringifyTimeToHourMin(45),
                medPrePrep: false,
                treatmentDuration: stringifyTimeToHourMin(60),
                bedRequirement: false,
                preferredSeatGroupId: -1,
                comment: ""
            })
        }, () => {
            resolve();
            this.onUpdateCycleRequest();
        })
    );

    handleRowFieldUpdate = (rowDataId: number, field: string, value: any) =>
        this.setState({
            rdvRequests: this.state.rdvRequests.map((req: RdvPlanningRequestStringified): RdvPlanningRequestStringified =>
                req.id === rowDataId
                    ? {
                        ...req,
                        [field]: value
                    }
                    : req)
        }, this.onUpdateCycleRequest)

    handleRowDelete = oldData =>
        new Promise((resolve) =>
            this.setState({
                rdvRequests: this.state.rdvRequests.filter(req => req.id !== oldData.id)
            }, () => {
                resolve();
                this.onUpdateCycleRequest();
            })
        );

    handleRowDuplicateMulti = () => new Promise((resolve) => {
        const cycleAddInput = prompt(`Saisissez le nombre de jours de séparation entre 2 cures, ou/et le nombre de cures à ajouter (ex: "7 2" pour ajouter 2 prochaines cures, séparées par 7 jours`);
        if (!cycleAddInput) return;
        const [cycleDeltaStr, cycleCountStr] = cycleAddInput.trim().split(/\s+/);
        const cycleDelta = parseIntOrNull(cycleDeltaStr);
        if (isNaN(cycleDelta) || cycleDelta <= 0) {
            alert("Il faut un nombre entier positif pour le nombre de jours de séparation");
            return;
        }
        const cycleCount = !cycleCountStr ? 1 : parseIntOrNull(cycleCountStr);
        if (isNaN(cycleCount) || cycleCount <= 0) {
            alert("Il faut un nombre entier positif pour le nombre de cures à ajouter");
            return;
        }
        let currentMaxDayIndex = Math.max(...this.state.rdvRequests.map(r => r.dayIndex));
        let nextRdvReqs: RdvPlanningRequestStringified[] = [];
        for (let nextCycleIdx = 0; nextCycleIdx < cycleCount; nextCycleIdx++) {
            const dayDelta = cycleDelta + currentMaxDayIndex - 1;
            const currentRdvReqCount = this.state.rdvRequests.length + nextRdvReqs.length;
            nextRdvReqs = nextRdvReqs.concat(CyclePlanningRequest.getInitialRdvRequestsStringifiedFrom(this.state.protocol, this.props.patient, this.props.config)
                .map(rdvReq => ({
                    ...rdvReq,
                    id: currentRdvReqCount + rdvReq.id,
                    dayIndex: rdvReq.dayIndex + dayDelta,
                })));
            currentMaxDayIndex = nextRdvReqs[nextRdvReqs.length - 1].dayIndex;
        }
        this.setState({
            rdvRequests: this.state.rdvRequests.concat(nextRdvReqs)
        }, () => {
            resolve();
            this.onUpdateCycleRequest();
        });
    });

    assignDoctor = () => this.setState({
        showAssignmentBox: true,
        boxMessage: <span>
            {`Veuillez sélectionner un médecin à affecter au patient ${getFullNameWithPrefix(this.props.selectedPatient)}`}
        </span>,
        assignmentType: "DOCTOR",
        initialSelectedStaff: this.state.referentDoctor,
    });

    closeBox = () => this.setState({
        showAssignmentBox: false,
        action: "",
    });

    beforeSavingAction = () => {
        this.closeBox();
        this.setState({
            lock: true,
            message: "En cours d'enregistrement",
        });
    };

    onConfirmAssignment = (selectedStaff: Staff): void => {
        if (selectedStaff?.id === this.state.referentDoctor?.id) return;
        this.beforeSavingAction();
        this.setState({
            referentDoctor: selectedStaff,
            referentDoctorPermanent: false,
        }, this.onUpdateCycleRequest);
    };

    onFindSuggestion = (hint: string): Promise<Array<Staff>> => (
        UController.staff.iQuery(
            { page: 0, pageSize: 5 },
            [`profession.internalId==${PROFESSION_IID_DOCTOR}`,
            `(lastName=like="*${hint.trim()}*",firstName=like="*${hint.trim()}*")`,
                `enabled==true`,
            this.state.referentDoctor ? `id!=${this.state.referentDoctor.id}` : null])
            .then(res => res.data)
    );

    persistPatientComment = () => this.state.patientCommentRef.current && UController.patient.patch({
        id: this.props.patient.id,
        comment: this.state.patientCommentRef.current.value,
    }).then(() => this.setState({
        patientCommentSaved: true,
    }));

    persistPatientDoctorReferent = () => this.state.referentDoctor && UController.patient.patch({
        id: this.props.patient.id,
        referentDoctor: this.state.referentDoctor,
    }).then(() => {
        this.setState({
            referentDoctorPermanent: true,
        });
    });

    reactivateButtonSavingPatientComment = () => this.setState({
        patientCommentSaved: false,
    });

    render() {
        const patient: Patient = this.props.patient;
        const protocol: Protocol | undefined = this.state.protocol;
        const sector: Sector = protocol ? this.props.sectorDict[this.state.protocol.sectorId] : null;
        return <>
            <Card className="my-1" outline color="info">
                <CardHeader className="bg-info d-flex align-items-start">
                    <h3 className="text-white mr-auto my-auto">
                        {
                            getFullNameWithPrefix(patient)
                            + (!!patient.externalId?.value ? ` - ${patient.externalId.value}` : "")
                            + (!!patient.birthday ? ` (${getLocalDateStrFromISO(patient.birthday)})` : "")
                        }
                        {
                            patient.seatTypeRequirement === BED && <i className="ml-2 fas fa-bed" />
                        }
                    </h3>
                    <Button className="my-auto" color="danger" size="sm" onClick={this.onRemovePatient}>
                        X
                    </Button>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs={12} md={6}>
                            <p>
                                <span className="font-weight-600">Médecin référent : </span>
                                <span>{getFullNameWithPrefixDoctor(this.state.referentDoctor, null, "Non assigné")}</span>
                                <Button size="sm" color="primary" onClick={this.assignDoctor} style={{ marginLeft: 10 }}><i className="fas fa-pen" /></Button>
                                {
                                    !!this.state.referentDoctor &&
                                    <>
                                        <Button size="sm" color="success" outline={this.state.referentDoctorPermanent} disabled={this.state.referentDoctorPermanent} onClick={this.persistPatientDoctorReferent} id={"btn-persist-doctor-referent-patient-" + patient.id}><i className="fas fa-check" /></Button>
                                        <UncontrolledTooltip target={"btn-persist-doctor-referent-patient-" + patient.id}>Affecter le médecin en tant que le référent de ce patient de manière récurrente</UncontrolledTooltip>
                                    </>
                                }
                            </p>
                        </Col>
                        <Col md={12} className="d-flex align-items-center">
                            <Input
                                type="textarea"
                                defaultValue={patient.comment || ""}
                                innerRef={this.state.patientCommentRef}
                                placeholder={"Note de patient (optionnel)"}
                                className="mx-auto my-1"
                                onChange={this.reactivateButtonSavingPatientComment}
                            />
                            <Button id={"btn-persist-comment-patient-" + patient.id} disabled={this.state.patientCommentSaved} outline={this.state.patientCommentSaved} size="sm" color="success" onClick={this.persistPatientComment} className="m-1"><i className="fas fa-check" /></Button>
                            <UncontrolledTooltip target={"btn-persist-comment-patient-" + patient.id}>Sauvegarder la note du patient</UncontrolledTooltip>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col md={6}>
                            <ProtocolPicker sectorDict={this.props.sectorDict} onChooseProtocol={this.onChooseProtocol} />
                        </Col>
                        <Col md={6}>
                            <MotivePicker onChooseMotive={this.onChooseMotive} />
                        </Col>
                        <Col md={12}>
                            <Input
                                type="textarea"
                                value={this.state.comment || undefined}
                                onChange={e => this.setState({ comment: e.target.value }, this.onUpdateCycleRequest)}
                                placeholder={"Un commentaire de cycle (optionnel)"}
                                className="mx-auto my-1"
                            />
                        </Col>
                    </Row>
                    {
                        <Row className="my-1">
                            <Col xs={12} className="d-flex flex-column">
                                <div className="d-flex flex-wrap justify-content-between">
                                    {
                                        <>
                                            <p className="my-1">
                                                <span className="font-weight-600">Protocole<span className="text-danger">*</span> : </span>
                                                {protocol?.name}
                                            </p>
                                            <p className="my-1">
                                                <span className="font-weight-600">Spécialité<span className="text-danger">*</span> : </span>
                                                {
                                                    sector && <SectorBadge sector={sector} />
                                                }
                                            </p>
                                        </>
                                    }
                                    {
                                        <p className="my-1">
                                            <span className="font-weight-600">Motif : </span>
                                            {this.state.motive && this.state.motive.name}
                                        </p>
                                    }
                                </div>
                                {
                                    !protocol ? <p className="my-1"><span className="text-warning">En attente de sélection de protocole</span></p> :
                                        <MaterialTable
                                            title={"Rdvs à planifier"}
                                            columns={[
                                                {
                                                    field: "id",
                                                    hidden: true,
                                                    type: "numeric",
                                                },
                                                {
                                                    field: "dayIndex",
                                                    title: <i className="fas fa-2x fa-calendar-day" />,
                                                    tooltip: "Indice du jour (J...)",
                                                    align: "center",
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <Input
                                                            type="number"
                                                            value={data["dayIndex"]}
                                                            onChange={e => this.handleRowFieldUpdate(data.id, "dayIndex", parseInt(e.target.value, 10))}
                                                            min={1}
                                                            className="mx-auto"
                                                        />,
                                                    initialEditValue: 1,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "daysDelayLimit",
                                                    title: <i className="fas fa-2x fa-calendar-plus" />,
                                                    tooltip: `Décalage toléré pour un rdv (en nombre de jours). Laisser vide pour prendre la valeur globale (${this.props.config.daysDelayLimit})`,
                                                    align: "center",
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <Input
                                                            type="text"
                                                            value={data.daysDelayLimit ?? ""}
                                                            onChange={e => this.handleRowFieldUpdate(data.id, "daysDelayLimit", parseIntOrNull(e.target.value))}
                                                            maxLength={2}
                                                            pattern="\d+"
                                                            className="mx-auto"
                                                        />,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "consultationDuration",
                                                    tooltip: "Consultation médecin (min) ?",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-stethoscope" />,
                                                    type: "string",
                                                    initialEditValue: "" + this.props.config.consultationLength,
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <Input
                                                            type="text"
                                                            value={data["consultationDuration"]}
                                                            onChange={e => this.handleRowFieldUpdate(
                                                                data.id,
                                                                "consultationDuration",
                                                                isValidDurationExpression(e.target.value)
                                                                    ? e.target.value.trim()
                                                                    : data["consultationDuration"]
                                                            )}
                                                            className="mx-auto my-auto"
                                                            placeholder={"..h.."}
                                                        />,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "consultationInPlace",
                                                    tooltip: "Consultation sur place ?",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-chair" />,
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <CenterDiv>
                                                            <Input
                                                                type="checkbox"
                                                                checked={data["consultationInPlace"]}
                                                                onChange={e => this.handleRowFieldUpdate(data.id, "consultationInPlace", e.target.checked)}
                                                                className="mx-auto my-auto"
                                                            />
                                                        </CenterDiv>,
                                                    type: "boolean",
                                                    initialEditValue: false,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "medPrepAfterInstallation",
                                                    tooltip: "Préparation après installation ?",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-link" />,
                                                    type: "boolean",
                                                    initialEditValue: false,
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <CenterDiv>
                                                            <Input
                                                                type="checkbox"
                                                                checked={data["medPrepAfterInstallation"]}
                                                                onChange={e => this.handleRowFieldUpdate(data.id, "medPrepAfterInstallation", e.target.checked)}
                                                                className="mx-auto my-auto"
                                                            />
                                                        </CenterDiv>,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "medPrepDuration",
                                                    tooltip: "Durée de préparation du médicament (minutes)",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-vial" />,
                                                    type: "string",
                                                    initialEditValue: "30",
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <Input
                                                            type="text"
                                                            value={data["medPrepDuration"]}
                                                            onChange={e => this.handleRowFieldUpdate(
                                                                data.id,
                                                                "medPrepDuration",
                                                                isValidDurationExpression(e.target.value)
                                                                    ? e.target.value.trim()
                                                                    : data["medPrepDuration"]
                                                            )}
                                                            className="mx-auto my-auto"
                                                            placeholder={"..h.."}
                                                        />,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "medPrePrep",
                                                    tooltip: "Préparation anticipée ?",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-calendar-check" />,
                                                    type: "boolean",
                                                    initialEditValue: false,
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <CenterDiv>
                                                            <Input
                                                                type="checkbox"
                                                                checked={data["medPrePrep"]}
                                                                onChange={e => this.handleRowFieldUpdate(data.id, "medPrePrep", e.target.checked)}
                                                                className="mx-auto my-auto"
                                                            />
                                                        </CenterDiv>,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "treatmentDuration",
                                                    tooltip: "Durée de traitement (minutes)",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-clock" />,
                                                    type: "string",
                                                    initialEditValue: "30",
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <Input
                                                            type="text"
                                                            value={data["treatmentDuration"]}
                                                            onChange={e => this.handleRowFieldUpdate(
                                                                data.id,
                                                                "treatmentDuration",
                                                                isValidDurationExpression(e.target.value)
                                                                    ? e.target.value.trim()
                                                                    : data["treatmentDuration"]
                                                            )}
                                                            className="mx-auto my-auto"
                                                            placeholder={"..h.."}
                                                        />,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "bedRequirement",
                                                    tooltip: "Requis de lit",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-bed" />,
                                                    type: "boolean",
                                                    initialEditValue: false,
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <CenterDiv>
                                                            <Input
                                                                type="checkbox"
                                                                checked={data["bedRequirement"]}
                                                                onChange={e => this.handleRowFieldUpdate(data.id, "bedRequirement", e.target.checked)}
                                                                className="mx-auto my-auto"
                                                            />
                                                        </CenterDiv>,
                                                    width: "6%",
                                                },
                                                {
                                                    field: "preferredSeatGroupId",
                                                    tooltip: "Aile préférée",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-feather" />,
                                                    width: "10%",
                                                    initialEditValue: "0",
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <CenterDiv>
                                                            <Input
                                                                type="select"
                                                                onChange={e => this.handleRowFieldUpdate(data.id, "preferredSeatGroupId", parseIntOrNull(e.target.value))}
                                                                className="mx-auto my-auto"
                                                            >
                                                                <option key={-1} value={-1} defaultChecked={true}>Aile aléatoire</option>
                                                                {
                                                                    this.props.seatWings.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
                                                                }
                                                            </Input>
                                                        </CenterDiv>,
                                                },
                                                {
                                                    field: "comment",
                                                    tooltip: "Commentaire du RDV",
                                                    align: "center",
                                                    title: <i className="fas fa-2x fa-comment" />,
                                                    type: "string",
                                                    initialEditValue: "",
                                                    render: (data: RdvPlanningRequestStringified) =>
                                                        <Input
                                                            type="textarea"
                                                            value={data["comment"]}
                                                            onChange={e => this.handleRowFieldUpdate(data.id, "comment", e.target.value)}
                                                            placeholder={"Un commentaire"}
                                                            className="mx-auto my-auto"
                                                        />,
                                                    width: "30%",
                                                }
                                            ]}
                                            data={this.state.rdvRequests}
                                            options={{
                                                emptyRowsWhenPaging: false,
                                                grouping: false,
                                                paging: false,
                                                search: false,
                                                sorting: false,
                                                showTitle: true,
                                                draggable: false,
                                                padding: "dense",
                                                tableLayout: "fixed",
                                            }}
                                            editable={{
                                                onRowDelete: this.handleRowDelete,
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <LibraryAdd />,
                                                    tooltip: "Ajouter la prochaine cycle",
                                                    isFreeAction: true,
                                                    onClick: this.handleRowDuplicateMulti,
                                                },
                                                {
                                                    icon: "add",
                                                    tooltip: "Ajouter un RDV libre",
                                                    isFreeAction: true,
                                                    onClick: this.handleRowAdd,
                                                },
                                            ]}
                                            localization={MATERIAL_TABLE_LOCALIZATION_FR}
                                        />
                                }
                            </Col>
                        </Row>
                    }
                </CardBody>
            </Card>
            {
                !!this.state.showAssignmentBox &&
                <AssignmentBox
                    onCancel={this.closeBox}
                    onConfirm={this.onConfirmAssignment}
                    message={this.state.boxMessage}
                    onFindSuggestion={this.onFindSuggestion}
                    initialSelectedStaff={this.state.referentDoctor}
                    renderStaff={getFullNameWithPrefixDoctor} />
            }
        </>;
    }
}
