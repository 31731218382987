import React from "react"
import EntityPicker from "./EntityPicker"
import StaffsAPI from "api/StaffsAPI"
import Staff from "components/ClassWrapper/Staff"
import { getFullNameWithPrefix } from "Utils"
import { styled } from 'styled-components';

const SuggestionItem = styled('div')`
    span{
        display: block;
        &:nth-of-type(1){
            font-weight: 700;
        }
        &:nth-of-type(2){
            font-style: italic;
        }
    }
`

type Props = {
    handleSelect(staff: Staff): void
}

const StaffPicker = (props: Props) => {
    
    const {handleSelect} = props

    const getFilteredStaffs = (inputValue: string): Promise<Staff[]> => {
        inputValue = inputValue.trim();
        if (inputValue.length === 0)
            return Promise.resolve([]);
        else{
            return StaffsAPI.getStaffsByRSQL({
                type: "staff",
                size: 5,
                search: `externalId.value=eqnc="*${inputValue}*"`
            }).then(data => data.content);
        }
    };

    const getStaffName = (suggestion: Staff): string => getFullNameWithPrefix(suggestion);

    const renderStaff = (suggestion: Staff): string => (
        <SuggestionItem>
            <span>{getFullNameWithPrefix(suggestion)}</span>
            <span>Transcodage : {suggestion?.externalId?.value}</span>
        </SuggestionItem>
    );

    return (
        <EntityPicker
            async
            placeholder={"Recherche par transcodage"}
            onChooseValue={handleSelect}
            getFilteredSuggestions={getFilteredStaffs}
            getSuggestionValue={getStaffName}
            renderSuggestion={renderStaff}/>
    )
}

export default StaffPicker