import React, {useEffect, useState} from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Button from "reactstrap/es/Button";
import InputGroup from "reactstrap/es/InputGroup";
import Input from "reactstrap/es/Input";

const getInitialExDatesMap = (initialExDates) => {
    let initialExDatesMap = {};// Initialize temporary ids
    initialExDates.forEach((exdate, id) => initialExDatesMap[id] = exdate);
    return initialExDatesMap;
};
/**
 *
 * @param {function(string[])} onExDatesChange
 * @param {string[]} initialExDates
 * @param {number} _key to reset
 * @return React.Component
 * @constructor
 */
const ExDates = ({onExDatesChange, initialExDates, _key}) => {
    const [exDateIds, setExDateIds] = useState({
        values: Array.from(Array(initialExDates.length).keys()),
        counter: initialExDates.length,
    });
    const [exDatesMap, setExDatesMap] = useState(getInitialExDatesMap(initialExDates));
    useEffect(() => {
        onExDatesChange(Object.values(exDatesMap).filter(v => !!v));
    }, [exDatesMap, onExDatesChange]);
    useEffect(() => {
        setExDateIds({
            values: Array.from(Array(initialExDates.length).keys()),
            counter: initialExDates.length,
        });
        setExDatesMap(getInitialExDatesMap(initialExDates));
    }, [initialExDates, _key]);
    return (
        <FormGroup>
            <div className="d-block w-100">
                <Label>
                    Dates à exclure :
                </Label>
                {" "}
                <Button size="sm" color="success"
                        onClick={() => setExDateIds({
                            values: exDateIds.values.concat(exDateIds.counter + 1),
                            counter: exDateIds.counter + 1,
                        })}>
                    <i className="fas fa-plus-square"/>
                </Button>
            </div>
            <div className="d-flex flex-wrap justify-content-around w-100">
                {
                    exDateIds.values.map(id =>
                        <InputGroup key={_key + id} className="w-auto m-0 p-1">
                            <Button size="sm" color="danger"
                                    onClick={() => {
                                        setExDateIds({
                                            counter: exDateIds.counter,
                                            values: exDateIds.values.filter(_id => _id !== id),
                                        });
                                        delete exDatesMap[id];
                                        setExDatesMap({...exDatesMap});
                                    }}
                            >
                                <i className="fas fa-minus-square"/>
                            </Button>
                            <Input
                                className="form-control form-control-sm"
                                type="date" defaultValue={exDatesMap[id]}
                                onChange={event => setExDatesMap({...exDatesMap, [id]: event.target.value})}
                            />
                        </InputGroup>
                    )
                }
            </div>
        </FormGroup>
    );
};

export default ExDates;