export default class SecurityException extends Error {
}

export class UnreachableServerError extends SecurityException {
    constructor() {
        super("Unreachable server");
    }
}

export class InvalidUser extends SecurityException {
    constructor(supplementaryMessage: string) {
        super(supplementaryMessage ?
                "Invalid user. Reason: " + supplementaryMessage
                : "Invalid user");
    }
}

export class InvalidToken extends SecurityException {
    constructor(src: Object) {
        super("Invalid token. "
                + (!src["accessToken"] && "No access token. ")
                + (!src["refreshToken"] && "No refresh token. "));
    }
}