import moment from "moment";
import Sector from "components/ClassWrapper/Sector";
import MaterialType from "components/ClassWrapper/MaterialType";
import {SITE_LOCAL} from "Constants";
import type {GenericTimeInterval} from "components/ClassWrapper/TimeClasses";
import type { ModelId } from "components/ClassWrapper/BasicModel";
import {env} from "Constants";

/**
 * Dictionary with "id" as key
 */
export type Dict<T> = {[key: ModelId]: T};

/**
 * Dictionary of Sector and MaterialType
 */
export type OptaEnumDict = {
    sector: Dict<Sector>,
    materialType: Dict<MaterialType>
};

/**
 *
 * @param inp input read from server
 * @param def default value for input
 * @returns {*}
 */
export const defVal = (inp, def) => inp ? inp : def;

/*
 * Custom requirements. Throw error on unacceptable value
 */
export const _require = {
    /**
     *
     * @param inp
     * @returns {string}
     * @throws TypeError if not receiving an non empty string
     */
    nonEmptyString: (inp: any): string => {
        if (inp && typeof inp === "string" && inp.trim().length > 0) return inp;
        else throwTypeError("Required non empty string but received " + inp + " instead");
    },

    /**
     *
     * @param inp
     * @param enumSet
     * @returns {*}
     * @throws TypeError if not receiving a recognized value
     */
    enum: (inp: any, enumSet: Array<any>): any => {
        if (enumSet.includes(inp)) return inp;
        else throwTypeError("Required an enum from predefined set but received " + inp + " instead");
    },

    /**
     *
     * @param inp
     * @param elementType correspond to typeof operator
     * @param elementRequirement custom verification
     * @returns {*}
     */
    array: (inp: any, elementType: ?string, elementRequirement: ?(any => boolean)): Array<T> => {
        if (Array.isArray(inp)) {
            if (elementType)
                for (let i = 0; i < inp.length; i++) {
                    if (typeof inp[i] !== elementType)
                        throwTypeError("Required element type of " + elementType + " but received " + inp[i] + " instead");
                }
            if (typeof elementRequirement === "function") {
                for (let i = 0; i < inp.length; i++) {
                    if (!elementRequirement(inp[i]))
                        throwTypeError(inp[i] + " did not satisfy requirement");
                }
            }
        } else
            throwTypeError("Required an array but received " + inp + " instead");
        return inp;
    },
};

export const toISODate = d => d.getUTCFullYear()
    + "-" + (d.getUTCMonth() + 1 < 10 ? "0" : "") + (d.getUTCMonth() + 1)
    + "-" + (d.getUTCDate() + 1 < 10 ? "0" : "") + (d.getUTCDate() + 1);
/**
 *
 * @return {string} tomorrow date in iso format
 */
export const getTomorrowDate = () => toISODate(new Date(Date.now() + 86400000));
/**
 *
 * @return {string} date of 30 days later in iso format
 */
export const getNextMonthDate = () => toISODate(new Date(Date.now() + 86400000 * 30));

/**
 * @param {{sex: string, firstName: string, lastName: string}} person
 * @param {number} [maxLength] to truncate (and fill up with unbreakable space if necessary) the return
 * @param {string} [defaultStr = "?"] default string to show instead of nothing
 * @param {string} prefix to replace prefix of sex. Null|undefined to use prefix of sex. Empty to remove prefix
 * @return {string} concatenation of first name and uppercase of last name.
 */
export const getFullNameWithPrefix = (person: {sex: ?string, firstName: ?string, lastName: ?string, partnerName: ?string}, maxLength?: number, defaultStr: string = "?", prefix?: string) => {
    if (!person) return defaultStr;
    let _prefix = typeof prefix === "string" ? prefix : person.sex === "M" ? "M." : "Mme.";
    let completeName = (_prefix.length > 0 ? _prefix + " " : "") + person.firstName
        + " " + getLastNames(person);
    if (typeof maxLength === "number" && completeName.length > maxLength)
        completeName = completeName.substr(0, maxLength - 3) + "...";
    return completeName;
};

export const getLastNames = (person: {lastName: ?string, partnerName: ?string}, upper?: boolean = true, separator?: string = "-") => {
    const lastNames = ((person.lastName ?? "") + (person.partnerName && person.partnerName !== person.lastName ? separator + person.partnerName : ""));
    if (upper) return lastNames.toUpperCase();
    return lastNames;
};

/**
 * @param {{sex: string, firstName: string, lastName: string}} person
 * @param {number} [maxLength] to truncate (and fill up with unbreakable space if necessary) the return
 * @param {string} [defaultStr = "?"] default string to show instead of nothing
 * @param {string} [prefix = "Dr."] to replace prefix of sex. Null|undefined to use prefix of sex. Empty to remove prefix
 * @return {string} concatenation of first name and uppercase of last name.
 */
export const getFullNameWithPrefixDoctor = (person: {sex: ?string, firstName: ?string, lastName: ?string}, maxLength?: number, defaultStr: string = "?", prefix?: string = "Dr.") => getFullNameWithPrefix(person, maxLength, defaultStr, prefix);

const isoDateRegex = /(\d+)-(\d+)-(\d+)/g;
const localDateRegexDest = "$3/$2/$1";
const localDateWithoutYearRegexDest = "$3/$2";
/**
 *
 * @param {string} date in ISO format
 * @param {boolean} [withoutYear] to ignore the year
 */
export const getLocalDateStrFromISO = (date: string, withoutYear: boolean = false) =>
    date.replace(isoDateRegex, withoutYear ? localDateWithoutYearRegexDest : localDateRegexDest);

const localDateRegex = /(\d+)\/(\d+)\/(\d+)/g;
const isoDateRegexDest = "$3-$2-$1";
export const getISODateFromLocalDate = (date: string) => date.replace(localDateRegex, isoDateRegexDest);

/**
 * 
 * @param {string} date in ISO date
 * @param {boolean?} endOfDay to suffix with 00:00:00 or 23:59:59.999
 * @returns number of milliseconds in Unix
 */
export const parseISODateToMilliseconds = (date: string, endOfDay?: boolean = false) => new Date(date + "T" + (!endOfDay ? "00:00:00" : "23:59:59.999")).valueOf();

export const parseMillisecondsToISOTime = (milliseconds: number): string =>
    new Date(milliseconds)
        .toLocaleTimeString(undefined, {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });

export const parseISODateTimeToMilliseconds = (isoDate: string, isoTime: string): number =>
    new Date(isoDate + "T" + isoTime).valueOf();

export const parseMillisecondsToISODate = (milliseconds: number): string => {
    let date = new Date(milliseconds);
    return date.getFullYear() + "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) + "-" +
        (date.getDate() < 10 ? "0" : "") + date.getDate();
};

/**
 *
 * @param isoDate
 * @param isoTime
 * @param milliseconds
 * @return {string} iso format at local timezone
 */
export const pushISODateTimeByMilliseconds = (isoDate: string, isoTime: string, milliseconds: number): string =>
    moment(isoDate + "T" + isoTime).add(milliseconds, "ms").format("YYYY-MM-DDTHH:mm:ss");

export const pushISODateByDays = (isoDate: string, days: Number): string =>
    moment(isoDate).add(days, "days").format(moment.HTML5_FMT.DATE);
/**
 * @param {string} isoDateTime date time as ISO
 * @returns date part
 */
export const getISODateFromISODateTime = (isoDateTime: string): string => isoDateTime.substring(0,10);
/**
 * @param {string} isoDateTime date time as ISO
 * @returns time part
 */
export const getISOTimeFromISODateTime = (isoDateTime: string): string => isoDateTime.substring(11);

/**
 * Transforms array into map
 * @param arr each element should have a field id
 * @param map initial map
 * @param idField marks which field of element is id. "id" by default
 * @return {Dict<T>}
 */
export const addAllToMap = (arr: Array<T>, map: Dict<T> = {}, idField = "id"): Dict<T> => {
    let _map = {...map};
    arr.forEach(ele => _map[ele[idField]] = ele);
    return _map;
};
/**
 *
 * @type {Localization}
 */
export const MATERIAL_TABLE_LOCALIZATION_FR = {
    toolbar: {
        searchPlaceholder: "Recherche",
        searchTooltip: "Recherche",
        addRemoveColumns: "Ajouter ou supprimer de colonnes",
        nRowsSelected: "{0} ligne(s) sélectionnées",
        showColumnsTitle: "Afficher colonnes",
        showColumnsAriaLabel: "Afficher colonnes",
        exportTitle: "Exporter",
        exportAriaLabel: "Exporter",
        exportName: "Exporter en CSV",
    },
    pagination: {
        labelDisplayedRows: "{from} - {to} / {count}",
        labelRowsSelect: "lignes",
        labelRowsPerPage: "lignes par page",
        firstAriaLabel: "1er page",
        firstTooltip: "1er page",
        previousAriaLabel: "Page précédente",
        previousTooltip: "Page précédente",
        nextAriaLabel: "Page suivante",
        nextTooltip: "Page suivante",
        lastAriaLabel: "Dernière page",
        lastTooltip: "Dernière page",
    },
    body: {
        emptyDataSourceMessage: "Aucune donnée",
        editRow: {
            deleteText: "Êtes-vous sûr de supprimer cette ligne ?",
            cancelTooltip: "Annuler",
            saveTooltip: "Sauvegarder",
        },
        addTooltip: "Ajouter",
        deleteTooltip: "Supprimer",
        editTooltip: "Modifier",
        filterRow: {
            filterTooltip: "Filtrer"
        },
    },
    grouping: {
        placeholder: "Glisser les entêtes"
    },
    header: {
        actions: "Actions",
    },
};

export const extractHourAndMinFromISOTime = (isoTime: string): string => isoTime.substr(0, 5);

export const reduceDict = <T, R>(dict: Dict<T>, fieldToKeep: string, idField: string = "id"): Dict<R> => {
    let _dict = {};
    Object.values(dict).forEach(t => _dict[t[idField]] = t[fieldToKeep]);
    return _dict;
};

/**
 *
 * @param msg
 * @returns {void}
 * @throws {TypeError} based on passed argument
 */
export const throwTypeError = (msg: string) => {
    throw new TypeError(msg);
};

const TIME_EXPRESSION = /\d+(h\d*)?/gi;
/**
 * Convert time expression "..h.." into minutes
 * @param timeStr
 * @returns {number}
 * @private
 * @throws {TypeError} if negative hours / minutes or minutes >= 60 (when hours > 0)
 */
export const parseSingleTimeStrToMinutes = (timeStr: (string | number)): number => {
    timeStr = (timeStr + "").trim().toLowerCase();
    if (timeStr.length === 0) return 0;
    let hours = 0, minutes;
    if (!timeStr.match(TIME_EXPRESSION))
        throwTypeError(`Invalid time expression: ${timeStr}`);
    if (timeStr.includes("h")) {
        let splitTable = timeStr.split("h").map(v => parseInt(v, 10));
        hours = splitTable[0];
        minutes = splitTable[1] || 0;
        if (hours < 0)
            throwTypeError(`Invalid time expression: ${timeStr}`);
    } else
        minutes = parseInt(timeStr, 10);
    if (hours > 0 && (minutes < 0 || minutes >= 60))
        throwTypeError(`Invalid time expression: ${timeStr}`);
    return hours * 60 + minutes;
};

/**
 *
 * @param t
 * @returns {string} "..h.."
 */
export const stringifyTimeToHourMin = (t: number): string => t < 0
    ? throwTypeError("Time must not negative")
    : t < 60
        ? t
        : Math.floor(t / 60) + "h" + (t % 60 === 0 ? "" : t % 60);

/**
 * Expensive function
 * @param obj
 * @returns {{}|[]|*}
 */
export const deepClone = (obj: any) => {
    if (Array.isArray(obj)) {
        let arr = [];
        for (let i = 0; i < obj.length; i++) {
            arr[i] = deepClone(obj[i]);
        }
        return arr;
    }

    if (typeof (obj) == "object") {
        let cloned = {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                cloned[key] = deepClone(obj[key])
        }
        return cloned;
    }
    return obj;
}

/**
 *
 * @param a1
 * @param a2
 * @returns {boolean} <tt>true</tt> if <tt>a1</tt> contains all <tt>a2</tt>
 */
export const checkArrayInclusion = (a1: any[], a2: any[]): boolean => {
    const a1AsSet = new Set(a1);
    return a2.every(el => a1AsSet.has(el));
}

export const compareEnumStringLocalNoCase = (s1: ?string, s2: ?string): boolean =>
    s1 === null || s2 === null ? false : s1.localeCompare(s2, SITE_LOCAL, {sensitivity: 'base'}) === 0;

export const isDevEnabled = (): boolean => env.NODE_ENV === "development";

/**
 *
 * @param s1
 * @param s2
 * @return {boolean} <tt>true</tt> if <tt>s1</tt> contains <tt>s2</tt> ignoring cases
 */
export const includeIgnoreCases = (s1?: string, s2?: string): boolean => s1?.toLowerCase().includes(s2?.toLowerCase());

export const printIsoTime = isoTime => !!isoTime ? extractHourAndMinFromISOTime(isoTime) : "?";
export const printIsoInterval = (interval: GenericTimeInterval): string => !interval ? "? - ?" : `${printIsoTime(interval.start)} - ${printIsoTime(interval.end)}`;
export const printIsoDate = (isoDateStr: ?string, withoutYear: ?boolean = true): string => isoDateStr ? getLocalDateStrFromISO(isoDateStr, withoutYear) : "?";
export const printIsoDateTime = (isoDateTimeStr: ?string): string => isoDateTimeStr ? `${printIsoDate(isoDateTimeStr.substring(0, 10))} ${printIsoTime(isoDateTimeStr.substring(11))}` : "?";

export const logErrorGroup = (e: any, actionCode: string) => {
    console.group(actionCode);
    console.error(e);
    console.groupEnd();
}