import React, {useState} from "react"
import styled from "styled-components"
import {Button, Input, FormGroup} from "@material-ui/core"
import {
    Row,
    Col,
    Label,
} from "reactstrap";
import moment from "moment";
import CyclesAPI from "api/CyclesAPI";
import Cycle from "components/ClassWrapper/Cycle";

const Container = styled('div')`
    .buttons-container{
        padding-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
`

type Props = {
    cycle: Cycle,
    handleClose(): void,
    handleCloseAndReload(): void
}

const CancelCycleForm = (props: Props) => {

    const {cycle, handleCloseAndReload} = props
    const [inputDate, setInputDate] = useState(moment().format("YYYY-MM-DD"))

    const handleSubmit = (type: "full" | "limited") => {
        CyclesAPI.deleteCyclesAndAppointments(cycle.id, type === "full" ? null : moment(inputDate).format("YYYY-MM-DD"))
        .then(handleCloseAndReload)
        .catch(() => {})
    }

    return (
        <Container>
            <h4>Voulez pouvez annuler le cycle entièrement ou annuler les rendez-vous à partir d'une date.</h4>

            <Row style={{padding: "20px 0"}}>
                <Col md={4}></Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>Date d'annulation</Label>
                        <Input type='date' value={inputDate} onChange={e => setInputDate(e.currentTarget.value)}/>
                    </FormGroup>
                </Col>
            </Row>

            <div className="buttons-container">
                <Button 
                    color='primary' 
                    variant="outlined" 
                    onClick={() => handleSubmit("full")}
                    style={{marginRight: 20}}>Annuler entièrement</Button>
                <Button 
                    color='primary' 
                    variant="contained" 
                    onClick={() => {handleSubmit("limited")}}>À partir de la date choisie</Button>
            </div>
        </Container>
    )
}

export default CancelCycleForm