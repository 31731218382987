import RendezvousWrapper from "components/ClassWrapper/RendezvousWrapper";
import Cycle from "components/ClassWrapper/Cycle";

class CycleWrapper {
    constructor(id: number,
                sectorId: number,
                rendezvousList: Array<RendezvousWrapper>,
                patientId: number) {
        /**
         *
         * @type {number}
         */
        this.id = id;
        /**
         *
         * @type {number}
         */
        this.sectorId = sectorId;
        /**
         *
         * @type {Array<RendezvousWrapper>}
         */
        this.rendezvousList = rendezvousList;
        /**
         * @type {number}
         */
        this.patientId = patientId;
    }

    static from(cycle: Cycle): CycleWrapper {
        return {
            id: cycle.id,
            sectorId: cycle.protocol.sectorId,
            patientId: cycle.patient.id,
            rendezvousList: cycle.rendezvousList.map(rdv => new RendezvousWrapper(rdv)),
        }
    }
}

export default CycleWrapper;