import axios from "axios";
import * as qs from "qs";
import {InvalidToken} from "components/Controller/SecurityException";
import config from "config";
import Account from "components/ClassWrapper/Account";
import {_require} from "Utils";
import ULiveFeed from "components/Controller/ULiveFeed";
import {SERVER_HOST} from "Constants";

class CoupleToken {
    constructor(o) {
        if (!o.accessToken || !o.refreshToken)
            throw new InvalidToken(o);
        /**
         *
         * @type {string}
         */
        this.accessToken = _require.nonEmptyString(o.accessToken);
        /**
         *
         * @type {string}
         */
        this.refreshToken = _require.nonEmptyString(o.refreshToken);
    }

}

/**
 * Interact with localStorage
 */
class TokenStorage {
    constructor() {
        throw new TypeError("Final class");
    }

    static ACCESS_TOKEN_STORAGE_KEY = config.appName + ".access-token";
    static REFRESH_TOKEN_STORAGE_KEY = config.appName + ".refresh-token";

    static _storage = localStorage;

    static _getAccessToken = (): string => TokenStorage._storage.getItem(TokenStorage.ACCESS_TOKEN_STORAGE_KEY);
    static _getRefreshToken = (): string => TokenStorage._storage.getItem(TokenStorage.REFRESH_TOKEN_STORAGE_KEY);
    static _saveToken = (token: CoupleToken) => {
        TokenStorage._storage.setItem(TokenStorage.ACCESS_TOKEN_STORAGE_KEY, token.accessToken);
        TokenStorage._storage.setItem(TokenStorage.REFRESH_TOKEN_STORAGE_KEY, token.refreshToken);
    };
    static _clearToken = () => {
        TokenStorage._storage.removeItem(TokenStorage.ACCESS_TOKEN_STORAGE_KEY);
        TokenStorage._storage.removeItem(TokenStorage.REFRESH_TOKEN_STORAGE_KEY);
    }
}

/**
 * Automatically add the token from storage as the authorization header
 */
export const authCaller = axios.create({
    baseURL: SERVER_HOST + "/" + config.api,
    paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
});

// authCaller.interceptors.request.use(config => {
//     config.headers.Authorization = `Bearer ${(TokenStorage._getAccessToken())}`;
//     return config;
// });

// authCaller.interceptors.response.use(
//         response => response,
//         error => {
//             if (!error || !error.response || !error.config)
//                 throw new UnreachableServerError();
//             if (error.response.status !== 401) {
//                 return Promise.reject(error);
//             } else if (error.config.url.includes("/token/refresh")) {
//                 TokenStorage._clearToken(); // clear storage
//                 throw new InvalidUser("Impossible to refresh token");
//             } else {
//                 // Wait an arbitrary time before retrying
//                 // In order to reduce concurrency
//                 if (!!TokenStorage._getRefreshToken())
//                     // Refresh access token
//                     return AccountController.refreshToken()
//                             // Retry request
//                             .then(() => authCaller.request(error.config));
//                 else
//                     return Promise.reject(error);
//             }
//         });

/**
 * No authorization attached before calling api
 */
export const openCaller = axios.create({baseURL: SERVER_HOST + "/" + config.openApi});

export type Credential = {
    username: string,
    password: string,
};

export class AccountController {
    constructor() {
        throw new TypeError("Final class");
    }

    /**
     *
     * @type {Account}
     * @private
     */
    static _currentAccount = null;

    /**
     *
     * @type {string}
     * @private
     */
    static _accountEndpoint = "accounts/";

    /**
     *
     * @returns {void}
     * @private
     */
    static _clearCurrentAccount = () => AccountController._currentAccount = null;

    /**
     *
     * @param account
     * @returns {void}
     */
    static _storeCurrentAccount = (account: Account) => AccountController._currentAccount = account;

    /**
     * Get information about currently logged in user from server and store into session memory
     * @returns {Promise<void>}
     * @private
     */
    static _requestCurrentAccount = (): Promise<void> =>
            authCaller.get(AccountController._accountEndpoint + "current")
                    .then(response => response.data)
                    .then(data => new Account(data))
                    .then(AccountController._storeCurrentAccount);

    /**
     * Get information about currently logged in user from memory
     * @returns {Account}
     */
    static getCurrentAccount = (): Account => AccountController._currentAccount;

    /**
     * Get information about currently logged in user from server, store it and return
     * @returns {Promise<Account>}
     */
    static getCurrentAccountFromServer = (): Account => AccountController.getCurrentAccount()
           

    /**
     * Remove all registered tokens
     * @returns {Promise<void>}
     */
    static logout = (): Promise<void> =>
            authCaller.post(AccountController._accountEndpoint + "logout")
                    .finally(() => {
                        TokenStorage._clearToken();
                        AccountController._clearCurrentAccount();
                        ULiveFeed.closeAllLiveFeed();
                    });

    /**
     * Login and save the token into TokenStorage. Retrieve the user's info as well
     * @returns {Promise<Account>}
     * @param credential
     */
    static login = (credential: Credential): Promise<Account> =>
            openCaller.post("token/login",
                    credential)
                    .then(res => res.data)
                    .then(receivedCoupleToken => TokenStorage._saveToken(receivedCoupleToken))
                    .then(AccountController._requestCurrentAccount)
                    .then(AccountController.getCurrentAccount);

    /**
     * Demand a new CoupleToken and save into TokenStorage
     * @param refreshToken
     * @returns {Promise<void>}
     * @private
     */
    static _requestRefreshingToken = (refreshToken: string): Promise<void> =>
            openCaller.post("token/refresh",
                    null,
                    {
                        params: {
                            token: refreshToken
                        }
                    })
                    .then(res => res.data)
                    .then(TokenStorage._saveToken);

    static refreshToken = (): Promise<void> => AccountController._requestRefreshingToken(TokenStorage._getRefreshToken());

    static isAuthenticated = (): boolean => !!AccountController.getCurrentAccount();

    static getAccessToken = (): string => TokenStorage._getAccessToken();
}