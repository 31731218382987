import BasicModel from "components/ClassWrapper/BasicModel";
import {_require} from "Utils";

export type IdpSource = "INTERNAL" | "EXTERNAL";

export default class Account extends BasicModel {
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         * @type {Staff}
         */
        this.staff = o["staff"];
        /**
         * @type {string}
         */
        this.username = _require.nonEmptyString(o["username"]);
        /**
         *
         * @type {string}
         */
        this.role = o.role;
        /**
         * @type {string | null}
         */
        this.externalAuthId = o.externalAuthId;
        /**
         * @type {IdpSource}
         */
        this.idpSource = o.idpSource;
    }
}