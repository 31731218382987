import React, { useEffect, useState } from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";

/**
 * Defines the limit occurrence of event
 * @param {Object} props
 * @param {function(string)} props.onEndRecurChange take one object arg following rrule as the new value received
 * @param {{until: string}} props.initialEndRecur
 * @param {number} props._key to reset
 * @return {React.Component}
 * @constructor
 */
const EndRecur = ({ onEndRecurChange, initialEndRecur, _key }) => {
    const [until, setUntil] = useState("");
    useEffect(() => {
        until && onEndRecurChange(until);
    }, [onEndRecurChange, until]);
    useEffect(() => {
        setUntil(typeof initialEndRecur === "string" ? initialEndRecur : "");
    }, [initialEndRecur, _key]);
    return (
        <FormGroup>
            <Label>
                Fin de récurrence (exclusive):
            </Label>
            {" "}
            <input
                type="date"
                className="w-auto d-inline-block form-control"
                value={until ?? ""}
                onChange={event => setUntil(event.target.value)}
            />
        </FormGroup>
    );
};

export default EndRecur;