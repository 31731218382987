import {authCaller as axiosInstance} from "../components/Controller/AuthProvider";

export type StaffPayload = {
    firstName: string,
    lastName: string,
    sex: string,
    sector: string,
    profession: string
}

export type Staff = {
    id: number,
    createdDateTime: string,
    lastModifiedDateTime: string,
    enabled: boolean,
    lastName: string,
    firstName: string,
    sex: string,
    sectorId: number,
    professionId: number,
    new: boolean,
    content: any[],
    links: {
        rel: "self" | "staff" | "profession" | "sector",
        href: string
    }[]
}

function getStaffsByRSQL(params){
    return axiosInstance.get(`/rsql`, {params})
    .then(response => response.data)
}

function createStaff(payload: StaffPayload): Promise<Staff>{
    return axiosInstance.post("/staffs", payload)
    .then(response => response.data)
}

function patchStaff(staffId: number, payload: StaffPayload): Promise<Staff>{
    return axiosInstance.patch(`/staffs/${staffId}`, payload)
    .then(response => response.data)
}

const StaffsAPI = {
    getStaffsByRSQL,
    createStaff,
    patchStaff
}

export default StaffsAPI;