import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader, Form, FormGroup, Input, Button, ButtonGroup} from "reactstrap";
import type {Credential} from "components/Controller/AuthProvider";

const AuthenticationBox = ({onCancel, onConfirm, message}: {
    onCancel: () => void,
    onConfirm: (credential: Credential) => void,
    message: React.ReactNode
}) => {
    const [username, setId] = useState("");
    const [password, setPw] = useState("");
    const onConfirmWrap = () => onConfirm({username, password});
    const onCancelWrap = (e) => {
        e.preventDefault();
        onCancel();
    }
    return (
            <Modal isOpen={true}>
                <ModalHeader className="bg-primary" toggle={onCancel}>
                    <span className="text-white display-4">Authentification</span>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={onConfirmWrap} className="d-flex flex-column">
                        {message}
                        <FormGroup inline={true} className="d-flex justify-content-between">
                            <Input type="text" name="id" required
                                   className="mx-2"
                                   placeholder={"Identifiant"}
                                   onChange={event => setId(event.target.value)}/>
                            <Input type="password" name="password"
                                   className="mx-2"
                                   placeholder="Code confidentiel" required
                                   onChange={event => setPw(event.target.value)}/>
                        </FormGroup>
                        <ButtonGroup className="mx-auto">
                            <Button className="bg-success mx-aut text-white" type={"submit"}>OK</Button>
                            <Button className="bg-danger text-white" type={"button"} onClick={onCancelWrap}>Annuler</Button>
                        </ButtonGroup>
                    </Form>
                </ModalBody>
            </Modal>
    )
};

export default AuthenticationBox;