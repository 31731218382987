import React from "react";

export default class OptaNavbarBrand extends React.Component {
    render() {
        const {logo} = this.props;
        return (
            <>
                <img
                    alt={logo.imgAlt}
                    className="navbar-brand-img mx-auto d-inline-block"
                    src={logo.imgSrc}
                />
                {/* <h1 className="mx-auto d-inline-block">{config.appName}</h1> */}
            </>
        )
    }
}