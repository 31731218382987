import React from "react";
import Sector from "components/ClassWrapper/Sector";
import { transparentize } from "polished";

type SectorBadgeProps = {
    sector: Sector,
}

const SectorBadge = ({sector}: SectorBadgeProps) => {
    return (
        <span className="label"
            style={{
                background: transparentize(.8, sector.rgbColor),
                color: sector.rgbColor,
            }}
        >
            {sector.name}
        </span>
    )
}

export default SectorBadge;