import styled from "styled-components"

export const Container = styled("div")`
    height: ${props => props.fullHeight ? '100%' : 'calc(100% - 55px)'};
    display: block;
    background: #fff;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    @media only screen and (max-width: 540px) {
        /* height: auto !important; */
    }
`

export const TopContainer = styled("div")`
    height: 50px;
    width:100%;
    display: flex;
    border-bottom: 1px solid #c6c6c6;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    position: relative;
    z-index: 1021 !important;
`

export const BottomContainer = styled("div")`
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    height: ${props => props.isMobile ? "100vh" : props.fullHeight ? "100%" : "calc(100% - 50px)"};
    @media only screen and (max-width: 540px) {
        flex-direction: column;
    }
`

export const SubBottomContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    height: ${props => props.isMobile ? "100vh" : "50px"};
    align-items: center;
`

export const LeftSidebarContainer = styled("div")`
    flex: ${props => props.isMobile ? "initial" : "0 1 16%"};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${props => props?.justifyContent ?? "space-between"};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-right: 1px solid #C6C6C6;
`

export const RightSidebarContainer = styled("div")`
    flex: 0 1 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-right: 1px solid #C6C6C6;
    @media only screen and (max-width: 540px) {
        position: absolute;
        top: 0;
        width: 100%;
        background: ${props => props.darkMode ? "#141b24" :"#fff"};
        height: 500%;
        z-index: 9;
    }
    button {
        margin-top: auto;
        margin-bottom: 20px;
    }
`

export const MainContainer = styled("div")`
    flex: 1 1 84%;
    overflow-y: auto !important; 
    position: relative;
    .alternative-content{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
            z-index: 4;
    }
`

export const HeaderContainer = styled.div`
    span{
        display: block;
        font-weight: ${props=> props.isToday ?  "600" :"400"};
        color: ${props=> props.isToday ? "#d20073" : "#949494"};
        text-transform: capitalize;
        &:first-of-type{
            font-size: .95rem;
            padding-top: 5px;
        }
        &:last-of-type{
            font-size: 1.1rem;
            padding-bottom: 5px;
        }
    }
`

export const LeftAppointmentContainer = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 125px;
    margin:5%;
    border-left: solid 3px;
    border-color: #d20073;
    padding-left: 5%;
    margin-top: 100px;
    .cross{
        position : absolute;
        top : 50%;
        transform: translate(-200%, -50%);
        font-size: 30px;
        font-weight: bold;
        color: #ee5253;
    }
`

export const CustomHeaderContainer = styled("div")`
    span{
        display: inline;
        font-weight: ${props=> props.isToday ?  "500" :"400"};
        color: ${props=> props.isToday ?  "#65ADC9" :"#8293a0"};
        text-transform: capitalize;
        &:nth-of-type(1), &:nth-of-type(3){
            font-size: .8rem;
        }
        &:nth-of-type(2){
            font-size: 1rem;
            font-weight: 600;
            background: ${props => props.isToday ? "#65ADC9" : "none"};
            color: ${props => props.isToday ? "#fff" : "#8293a0"};
            border-radius: 20px;
            padding: 1px 5px;
            margin: ${props => props.isToday ? "0 5px" : "0 auto"};
            width: max-content;
        }
    }
`

export const EventContainer = styled("div")`
background: ${props => props.selectedEvent ? "#5899ef" : props.color};
border-radius: 3px;
height:100%;
overflow: hidden;
width: 100%;
padding: 0 5px;
b{
    white-space: nowrap;
}
.event-tooltip{
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow:
    0 0px 1.9px rgba(0, 0, 0, 0.08),
    0 0px 5.3px rgba(0, 0, 0, 0.115),
    0 0px 12.7px rgba(0, 0, 0, 0.15),
    0 0px 42px rgba(0, 0, 0, 0.23);
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease-in-out;
    border-radius: 3px;
    .icons-container{
        display: flex;
        span{
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            padding: 5px 8px;
            color: #435F71;
            cursor: pointer;
            i{
                font-size: 14px;
            }
            &:first-of-type{border-radius: 3px 0 0 3px;}
            &:last-of-type{border-radius: 0 3px 3px 0};
            &:hover{
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
    b{
        color: #435F71;
        padding: 0 5px;
    }
}
&:hover{
    .event-tooltip{
        opacity: 1;
        visibility: visible;
    }
}
` 