import BasicModel from "components/ClassWrapper/BasicModel";

/**
 * Extended enumeration wrapper
 * @property {string} name truncated name
 */
export default class EnumModel extends BasicModel {
    constructor(o: Object) {
        o = o  || {};
        super(o);
        this.name = o[EnumModel.NAME];
    }

    static NAME = "name";
}