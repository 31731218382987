import EnumModel from "components/ClassWrapper/EnumModel";

/**
 * @property {string} name always uppercase, 1 to 50 characters
 * @property {string} description 1 to 500 characters
 */
class Motive extends EnumModel {
    constructor(o: Object) {
        o = o  || {};
        super(o);
        this.description = o["description"];
    }
}

export default Motive;