import EnumModel from "components/ClassWrapper/EnumModel";

/**
 * @property {string} code
 * @property {SeatType} seatType
 */
class MaterialType extends EnumModel {
    constructor(o: Object) {
        o = o  || {};
        super(o);
        /**
         * @type {string}
         */
        this.code = o["code"];
        /**
         * @type {"BED" | "CHAIR"}
         */
        this.seatType = o.seatType
    }
}

export default MaterialType;