import { getFullNameWithPrefix, printIsoDate, pushISODateTimeByMilliseconds, getISODateFromISODateTime, getISOTimeFromISODateTime, pushISODateByDays, Dict } from "Utils";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import moment, { Moment } from "moment";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import { PlanningActionConfig } from 'views/planning/PlanningActionConfig';
import { UnixInterval } from "components/ClassWrapper/TimeClasses";

export class RdvMoveError extends Error {
}

/**
 * Only modify the time of rdv in the same date as the selected rdv by the same amount of shift.
 * The rdvs of other dates will only be moved if the selected rdv is moved to other date (future or past).
 * Careful to only modify the date, not time.
 * 
 * @param selectedRdvId the last selected and moved rdv that triggers this control
 * @param selectedRdvIds all currently selected rdvs
 * @param newStartTime destination after moving last selected rdv
 * @param config global config
 * @param planningActionConfig local config of moving rdv
 * @param publicHolidays global closure
 * @param rdvItemMap data of visual rdv, at least those are to be modified
 * @param completeRdvMap raw data of rdvs, at least those are to be modified
 * @returns shifted rdvs
 * @throws {RdvMoveError} if outside of service hour (depending on {@link PlanningActionConfig#limitRdvInOpeningHour}) or
 *                        if any rdv falls into weekend or holidays (depending on {@link PlanningActionConfig#noRdvOnWeekendsAndHolidays})
 */
export const checkAndShiftDateTimeRdvs = (
    selectedRdvId: number,
    selectedRdvIds: number[],
    newStartTime: number,
    config: OptaConfig,
    planningActionConfig: PlanningActionConfig,
    publicHolidays: UnixInterval[],
    rdvItemMap: Dict<{start_time: number, end_time: number}>,
    completeRdvMap: Dict<CompleteRdv>
): CompleteRdv[] => {
    const shiftedRdvs: CompleteRdv[] = [];
    // Calculate the shift
    const newStartTimeMoment = moment(newStartTime);
    const newStartDate = newStartTimeMoment.clone().startOf("day");
    const delta = newStartTime - rdvItemMap[selectedRdvId].start_time;
    const selectedRdvTimestamp = moment(rdvItemMap[selectedRdvId].start_time);
    const selectedDate = selectedRdvTimestamp.startOf("day");
    const rdvIdsSameSelectedDate = selectedRdvIds.filter(id => selectedDate.isSame(rdvItemMap[id].start_time, "day"));
    const openingHourOnNewStartDate = newStartDate.clone().add(moment.duration(config.realOpeningHour));
    const closingHourOnNewStartDate = newStartDate.clone().add(moment.duration(config.closingHour));
    // Shift all rdv same selected date
    // Cancel if start and end of some rdv same selected date would not stay in same day
    // If rdv is limited to opening hour, we must also check
    rdvIdsSameSelectedDate.forEach(id => {
        const crdv = completeRdvMap[id];
        const rdv = crdv.rdv;
        let rdvItem = rdvItemMap[id],
            nextStartTime = moment(rdvItem.start_time + delta),
            nextEndTime = moment(rdvItem.end_time + delta);
        if (!nextStartTime.isSame(nextEndTime, "day")
            || (
                planningActionConfig.limitRdvInOpeningHour
                && (
                    nextStartTime.isBefore(openingHourOnNewStartDate)
                    || nextEndTime.isAfter(closingHourOnNewStartDate)
                )
            ))
            throw new RdvMoveError(`RDV du patient ${getFullNameWithPrefix(crdv.patient)} sur l'hébergement ${rdv.place.number} le ${printIsoDate(rdv.sessionDay, true)} va être déplacé hors des horaires d'ouverture de service. L'action sera annulée`);
        if (planningActionConfig.noRdvOnWeekendsAndHolidays)
            checkRdvOnWeekendsAndHolidays(crdv, nextStartTime, publicHolidays);
        const _session = {
            start: pushISODateTimeByMilliseconds(rdv.sessionDay, rdv.session.start, delta),
            end: pushISODateTimeByMilliseconds(rdv.sessionDay, rdv.session.end, delta),
        },
            _consultation = rdv.consultationDay && rdv.consultation ? {
                start: pushISODateTimeByMilliseconds(rdv.consultationDay, rdv.consultation.start, delta),
                end: pushISODateTimeByMilliseconds(rdv.consultationDay, rdv.consultation.end, delta),
            } : null,
            _installation = rdv.installation ? {
                start: pushISODateTimeByMilliseconds(rdv.sessionDay, rdv.installation.start, delta),
                end: pushISODateTimeByMilliseconds(rdv.sessionDay, rdv.installation.end, delta),
            } : null,
            _medPrep = rdv.medPrepDay && rdv.medPrep ? {
                start: pushISODateTimeByMilliseconds(rdv.medPrepDay, rdv.medPrep.start, delta),
                end: pushISODateTimeByMilliseconds(rdv.medPrepDay, rdv.medPrep.end, delta),
            } : null,
            _treatment = rdv.treatment ? {
                start: pushISODateTimeByMilliseconds(rdv.sessionDay, rdv.treatment.start, delta),
                end: pushISODateTimeByMilliseconds(rdv.sessionDay, rdv.treatment.end, delta),
            } : null;
        shiftedRdvs.push({
            ...crdv,
            rdv: {
                ...rdv,
                sessionDay: getISODateFromISODateTime(_session.start),
                session: {
                    start: getISOTimeFromISODateTime(_session.start),
                    end: getISOTimeFromISODateTime(_session.end)
                },
                consultationDay: _consultation ? getISODateFromISODateTime(_consultation.start) : null,
                consultation: _consultation ?
                    {
                        start: getISOTimeFromISODateTime(_consultation.start),
                        end: getISOTimeFromISODateTime(_consultation.end)
                    } : null,
                installation: _installation ? {
                    start: getISOTimeFromISODateTime(_installation.start),
                    end: getISOTimeFromISODateTime(_installation.end)
                } : null,
                medPrepDay: _medPrep ? getISODateFromISODateTime(_medPrep.start) : null,
                medPrep: _medPrep ? {
                    start: getISOTimeFromISODateTime(_medPrep.start),
                    end: getISOTimeFromISODateTime(_medPrep.end)
                } : null,
                treatment: _treatment ? {
                    start: getISOTimeFromISODateTime(_treatment.start),
                    end: getISOTimeFromISODateTime(_treatment.end)
                } : null,
            },
        });
    });
    // Shift all rdv of other dates only if selected date is changed, only if user allows moving future rdv same cycle
    if (!!planningActionConfig.moveFutureRdvSameCycle) {
        const deltaDays = newStartDate.diff(selectedDate, "days");
        if (deltaDays !== 0) {
            selectedRdvIds.filter(id => !rdvIdsSameSelectedDate.includes(id))
                .forEach(id => {
                    const crdv = completeRdvMap[id];
                    const rdv = crdv.rdv;
                    const nextSessionDay = pushISODateByDays(rdv.sessionDay, deltaDays);
                    if (planningActionConfig.noRdvOnWeekendsAndHolidays)
                        checkRdvOnWeekendsAndHolidays(crdv, nextSessionDay, publicHolidays);
                    shiftedRdvs.push({
                        ...crdv,
                        rdv: {
                            ...rdv,
                            sessionDay: nextSessionDay,
                            consultationDay: rdv.consultationDay ? pushISODateByDays(rdv.consultationDay, deltaDays) : null,
                            medPrepDay: rdv.medPrepDay ? pushISODateByDays(rdv.medPrepDay, deltaDays) : null,
                        }
                    })
                });
        }
    }
    return shiftedRdvs;
}

/**
 * @param crdv rdv to check
 * @param nextSessionDay calculated date after shift
 * @param publicHolidays list of global closure
 * @throws {RdvMoveError} if nextSessionDay is a weekend or found in publicHolidays
 */
export const checkRdvOnWeekendsAndHolidays = (crdv: CompleteRdv, nextSessionDay: string | Moment, publicHolidays: UnixInterval[]): void => {
    const nextSessionDayMoment = moment(nextSessionDay);
    const nextSessionDayStr = nextSessionDayMoment.format(moment.HTML5_FMT.DATE);
    const nextSessionDayAsDow = nextSessionDayMoment.day();
    if (nextSessionDayAsDow === 0 || nextSessionDayAsDow === 6)
        throw new RdvMoveError(`RDV du patient ${getFullNameWithPrefix(crdv.patient)} sur l'hébergement ${crdv.rdv.place.number} le ${printIsoDate(crdv.rdv.sessionDay, true)} sera déplacé sur le weekend ${printIsoDate(nextSessionDayStr, true)}. L'action va être annulée`);
    if (publicHolidays.length === 0) return;
    const nextSessionStart = moment(nextSessionDayStr + "T" + crdv.rdv.session.start).valueOf();
    const nextSessionEnd = moment(nextSessionDayStr + "T" + crdv.rdv.session.end).valueOf();
    publicHolidays.forEach(d => {
        if (d.start < nextSessionEnd && d.end > nextSessionStart)
            throw new RdvMoveError(`RDV du patient ${getFullNameWithPrefix(crdv.patient)} sur l'hébergement ${crdv.rdv.place.number} le ${printIsoDate(crdv.rdv.sessionDay, true)} sera déplacé sur un jour férié ${printIsoDate(nextSessionDayStr, true)}. L'action va être annulée`);
    });
}