import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/vendor/fontawesome-free-5.9.0-web/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import NewApp from "NewApp";
import { ToastProvider } from 'react-toast-notifications'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { client } from "./config/keycloak";
import AuthAPI from "./api/AuthAPI";
import { HashRouter } from "react-router-dom";

const eventLogger = (event: unknown, error: unknown) => {
}

const manageToken = (tokens: { token: string, refreshToken: string, idToken: string }) => {
  if (tokens.token && tokens.refreshToken && tokens.idToken) {
    AuthAPI.setAxiosToken(tokens.token);
    localStorage.setItem("token", tokens.token);
    localStorage.setItem("refresh_token", tokens.refreshToken);
    localStorage.setItem("id_token", tokens.idToken);
  }
}

// We use HashRouter that bases on hash (#...) to define current location
// We do this to save the original url when we first enter the application
window.sessionStorage.setItem("lastLocation", window.location.hash);

const render = () => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <HashRouter>
        <ReactKeycloakProvider
          authClient={client}
          onEvent={eventLogger}
          onTokens={manageToken}
          autoRefreshToken={true}
          initOptions={{
            onLoad: 'check-sso',
            responseMode: 'query',
            token: localStorage.getItem("token"),
            refreshToken: localStorage.getItem("refresh_token"),
            idToken: localStorage.getItem("id_token"),
          }}>
          <ToastProvider placement={"bottom-left"} autoDismiss={true} newestOnTop={true}>
            <NewApp />
          </ToastProvider>
        </ReactKeycloakProvider>
      </HashRouter>
    </AppContainer>,
    document.getElementById('root')
  );
};

render();

if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}