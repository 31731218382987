import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
    Container,
    Navbar
} from "reactstrap";
import UncontrolledDropdownUserMenu from "components/Navbars/UncontrolledDropdownUserMenu";
import { OptaRoute } from "routes";

type AdminNavbarProps = {
    route: OptaRoute,
    sidebarToggler: () => void
}

class AdminNavbar extends React.Component<AdminNavbarProps> {

    render() {
        return (
            <div className="header">
                <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                    <button className="toggleSidebarBtn" style={{
                        background: 'none',
                        border: 'none',
                        color: '#fff',
                        marginLeft: '5px',
                        fontSize: '20px'
                    }} onClick={this.props.sidebarToggler}><i className="fas fa-bars" /></button>
                    <Container fluid>
                        <Link
                            className="h2 mb-0 d-none d-lg-inline-block"
                            to={this.props.route.getFullPath()}
                        >
                            {this.props.route.name}
                        </Link>
                        <UncontrolledDropdownUserMenu />
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default AdminNavbar;
