import BasicModel from "components/ClassWrapper/BasicModel";
import {defVal} from "Utils";
import MaterialType from "components/ClassWrapper/MaterialType";

/**
 * @property {string} number
 * @property {MaterialType} type
 * @property {ModelId} sectorId
 * @property {ExternalId?} externalId
 */
class Material extends BasicModel {
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         * @type {string}
         */
        this.number = o["number"];
        /**
         * @type {MaterialType}
         */
        this.type = new MaterialType(o["type"]);
        /**
         * @type {import("components/ClassWrapper/BasicModel").ModelId}
         */
        this.typeId = this.type.id;
        /**
         * @type {ModelId}
         */
        this.sectorId = defVal(o["sectorId"], -1);
        /**
         * @type {ExternalId?}
         */
        this.externalId = o.externalId;
    }

    /**
     *
     * @return {string} number plus material type code
     */
    static getCompoundName = (place: Material = null): string =>
        !place ? "" : "" + place.number;
}

export default Material;