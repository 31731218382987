import type { OptaEnumDict } from "Utils";
import {
    includeIgnoreCases,
} from "Utils";
import "assets/css/opta.css";
import { BottomContainer, Container, MainContainer, TopContainer } from "assets/styled-components/CalendarContainer";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import { fetchGlobalDictionary } from "components/Controller/ReusableBulkCall";
import UController from "components/Controller/UController";
import ULiveFeed from "components/Controller/ULiveFeed";
import memoize from "memoize-one";
import React from "react";
import "react-calendar-timeline/lib/Timeline.css";
import { Button, ButtonGroup, Input } from "reactstrap";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import type { SearchCriteria } from "views/jobs/FilterBox";
import FilterBox from "views/jobs/FilterBox";
import JobsTimeline from "views/jobs/JobsTimeline";
import DetailBox from "views/jobs/RdvDetailBox";
import { PopupContainer } from "views/planning/Planning";
import moment from "moment";
import JobsTable from "views/jobs/JobsTable";
import { generatePlanningRdvList } from "tools/pdf_generators";

function rdvSorterByPlace(r1: CompleteRdv, r2: CompleteRdv): Number {
    const p1 = r1.rdv.place, p2 = r2.rdv.place;
    return p1.number.localeCompare(p2.number);
}

function rdvSorterBySessionStart(r1: CompleteRdv, r2: CompleteRdv): Number {
    return r1.rdv.session.start.localeCompare(r2.rdv.session.end);
}

function rdvSorter(r1: CompleteRdv, r2: CompleteRdv): Number {
    return rdvSorterBySessionStart(r1, r2)
        || rdvSorterByPlace(r1, r2);
}

const REGEX_DATE = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/

const getBirthdayFilter = (s?: String) : ?String => {
    if (!s) return undefined;
    const match = s.match(REGEX_DATE);
    if (!match || match.length !== 4) return undefined;
    return `${match[3]}-${match[2]}-${match[1]}`;
}

class SyncJobs extends React.PureComponent {

    props: {
        dict?: OptaEnumDict,
        config?: OptaConfig,
    };

    state = {
        /**
         * @type {DateString} iso date format
         */
        currentDate: new Date().toISOString().substr(0, 10),
        /**
         * @type {boolean}
         */
        onListMode: false,
        /**
         * @type {CompleteRdv | null}
         */
        selectedRdv: null,
        /**
         * @type SearchCriteria | null
         */
        searchCriteria: null,
        /**
         * @type {Array<CompleteRdv>}
         */
        completeRdvs: [],
        /**
         * @type {string}
         */
        message: null,
        /**
         * @type {LiveSubscription}
         */
        rdvLiveFeedSubscription: null,
        /**
         * @type {Boolean}
         */
        isLeftSidebarOpen: false,
    };
    
    filteredCompleteRdvArrayRef = React.createRef();

    /**
     *
     * @param {CompleteRdv | null} rdv
     */
    onRdvSelected = (rdv: CompleteRdv | null) =>
            this.setState({
                selectedRdv: rdv
            });
    
    closeRdvDetailBox = () => this.onRdvSelected(null);

    showTimeline = () =>
            this.setState({
                onListMode: false,
            });

    showTable = () =>
            this.setState({
                onListMode: true,
            });

    /**
     *
     * @param {CompleteRdv} modifiedRdv
     */
    refresh = (modifiedRdv: CompleteRdv): void => {
        if (!modifiedRdv) return;
        this.setState({
            completeRdvs: [...this.state.completeRdvs]
        });
    };

    onSetNewSearchCriteria = (searchCriteria: SearchCriteria) => this.setState({searchCriteria});

    filterRdvs = (rdvArray: Array<CompleteRdv>, searchCriteria: SearchCriteria): Array<CompleteRdv> => {
        if (searchCriteria === null) return rdvArray;
        const birthdayFilter = getBirthdayFilter(searchCriteria.searchText);
        return rdvArray.filter(crdv =>
            (!searchCriteria.searchText
                    || includeIgnoreCases(crdv.patient.externalId?.value, searchCriteria.searchText)
                    || includeIgnoreCases(crdv.patient.lastName, searchCriteria.searchText)
                    || includeIgnoreCases(crdv.patient.firstName, searchCriteria.searchText)
                    || includeIgnoreCases(crdv.patient.lastName, searchCriteria.searchText)
                    || (!!birthdayFilter && includeIgnoreCases(crdv.patient.birthday, birthdayFilter))
            )
            && searchCriteria.selectedSectorIds.includes(crdv.protocol.sectorId)
            && searchCriteria.selectedTakerStatus.includes(crdv.rdv.nurse ? "yes" : "no")
        );
    }

    memo_filterRdvs = memoize(this.filterRdvs);

    componentDidMount() {
        this.setState({
            rdvLiveFeedSubscription: ULiveFeed.todayRdvs.subscribe(
                (latestRdvChange: CompleteRdv) => {
                    let previousRdvState = this.state.completeRdvs.find(v => v.id === latestRdvChange.id);
                    if (!previousRdvState) {
                        if (// If a change happens to an appoint today
                            latestRdvChange.rdv.sessionDay === this.state.currentDate)
                            // New rdv
                            this.setState({
                                completeRdvs: this.state.completeRdvs.concat(latestRdvChange)
                            });
                    } else {
                        const rdvDeletedOrMoved = !latestRdvChange.rdv.enabled ||
                            latestRdvChange.rdv.sessionDay !== this.state.currentDate;
                        this.setState({
                            completeRdvs: this.state.completeRdvs.map(r =>
                                // If not the recently changed rdv
                                r.id !== latestRdvChange.id ||
                                    // If we already have the latest change
                                    r.rdv.lastModifiedDateTime >= latestRdvChange.rdv.lastModifiedDateTime
                                    ? r
                                    : rdvDeletedOrMoved ? null : latestRdvChange
                            ),
                            selectedRdv: !this.state.selectedRdv
                                ? null
                                : this.state.selectedRdv.id !== latestRdvChange.id ||
                                    this.state.selectedRdv.rdv.lastModifiedDateTime >= latestRdvChange.rdv.lastModifiedDateTime
                                    ? this.state.selectedRdv
                                    : rdvDeletedOrMoved ? null : latestRdvChange
                        });
                    }
                }
            )
        }, this.fetchJobs);
    }

    componentWillUnmount() {
        this.state?.rdvLiveFeedSubscription?.unsubscribe();
        this.filteredCompleteRdvArrayRef.current = undefined;
    }

    exportTodayAppoints = () => this.filteredCompleteRdvArrayRef.current && generatePlanningRdvList(
        {
            startDate: this.state.currentDate,
            endDate: this.state.currentDate,
        },
        this.filteredCompleteRdvArrayRef.current
    );

    updateCurrentDate = (date: String) => this.setState({currentDate: date}, this.fetchJobs);

    onSelectDate = (e: Event) => this.updateCurrentDate(e.target.value);

    onClickNextDate = () => this.updateCurrentDate(moment(this.state.currentDate).add(1, 'day').format(moment.HTML5_FMT.DATE));

    onClickPreviousDate = () => this.updateCurrentDate(moment(this.state.currentDate).add(-1, 'day').format(moment.HTML5_FMT.DATE));

    fetchJobs = (): Promise<Void> => UController.appointment.getAllAtDate(this.state.currentDate).then(appointments => {
        appointments.sort(rdvSorter);
        this.setState({completeRdvs: appointments});
    });

    render() {
        const filteredCompleteRdvArray = this.memo_filterRdvs(this.state.completeRdvs, this.state.searchCriteria);
        this.filteredCompleteRdvArrayRef.current = filteredCompleteRdvArray;
        return (
            <div style={{height: "calc(100vh - 110px)"}}>
                <Container fullHeight>
                    <TopContainer>
                        <div></div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button onClick={this.exportTodayAppoints} className="print-btn">
                                <span className="fas fa-print fa-lg"/>
                            </Button>
                            <ButtonGroup>
                                <Button outline color="primary" onClick={this.onClickPreviousDate} style={{border: "none"}}>
                                    <i className="fas fa-chevron-left"/>
                                </Button>
                                <Input value={this.state.currentDate} type={"date"} id={"startDate"} onChange={this.onSelectDate} />
                                <Button outline color="primary" onClick={this.onClickNextDate} style={{border: "none"}}>
                                    <i className="fas fa-chevron-right"/>
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup className="ml-2">
                                <Button onClick={this.showTimeline} color="primary" outline className={`${!this.state.onListMode ? "active" : ""}`}>
                                    <span className="fa fa-calendar-alt fa-lg"/>
                                </Button>
                                <Button onClick={this.showTable} color="primary" outline className={`${this.state.onListMode ? "active" : ""}`}>
                                    <span className="fa fa-list-alt fa-lg"/>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </TopContainer>
                    <BottomContainer fullHeight>
                        <MainContainer>
                            <div style={{padding: 20}}>
                                <FilterBox 
                                    onSetSearchCriteria={this.onSetNewSearchCriteria}
                                    placeholder={"Entrez vos filtres selon les critères ci-dessous"}
                                    dict={this.props.dict}/>
                            </div>
                            {filteredCompleteRdvArray.length === 0 ?
                                <p className="m-3"><span className="font-weigh-bold font-italic text-danger">Aucun rendez-vous trouvé !</span></p>
                                :
                                this.state.onListMode ?
                                    <JobsTable
                                        onRdvSelected={this.onRdvSelected}
                                        dict={this.props.dict}
                                        date={this.state.currentDate}
                                        config={this.props.config}
                                        completeRdvArray={filteredCompleteRdvArray}
                                    />
                                    :
                                    <JobsTimeline
                                        completeRdvArray={filteredCompleteRdvArray}
                                        onRdvClick={this.onRdvSelected}
                                        dict={this.props.dict}
                                        date={this.state.currentDate}
                                        config={this.props.config}
                                    />
                            }
                        </MainContainer>
                    </BottomContainer>
                    {!!this.state.selectedRdv && 
                        <PopupContainer view>
                            <DetailBox 
                                selectedCompleteRdv={this.state.selectedRdv} 
                                close={this.closeRdvDetailBox}/>
                        </PopupContainer>
                    }
                    {/* <Row className="m-3">
                        <Col xs={12}>
                            <Card outline={true} color="primary">
                                <CardHeader className="d-flex align-items-baseline">
                                </CardHeader>
                                <CardBody>
                                    {
                                        <FilterBox onSetSearchCriteria={this.onSetNewSearchCriteria}
                                                   placeholder={"Entrez vos filtres selon les critères ci-dessous"}
                                                   dict={this.props.dict}/>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>*/}
                </Container>
            </div>
        );
    }
}

export default class Jobs extends React.PureComponent {
    render() {
        return (
                <AsyncLoaderWrapper loader={() => Promise.all([
                    fetchGlobalDictionary(),
                    UController.planning.getConfig(),
                ])
                        .then(([
                                   dict,
                                   config,
                               ]) => ({
                            dict,
                            config,
                        }))
                }>
                    <SyncJobs/>
                </AsyncLoaderWrapper>
        );
    }
};