import { CustomHeaderContainer } from "assets/styled-components/CalendarContainer"
import moment from "moment"

export function timelineToFcEvents(viewMode, eventsType, events){
    return events?.map(event => ({
        color: event.capacity === 1 ? "#3ab350" : "#e94c4c",
        title: event.comment ?? "",
        id: event.id,
        display: viewMode === "exceptional" && eventsType === "regular" ? "background" : null,
        resourceId: `staff_${event.staffId}`,
        daysOfWeek: eventsType === "exceptional" ? null : [event.interval.dayOfWeek],
        startTime: eventsType === "exceptional" ? null : event.interval.start,
        endTime: eventsType === "exceptional" ? null : event.interval.end,
        start: eventsType === "regular" ? null : event.interval.start,
        end: eventsType === "regular" ? null : event.interval.end,
        extendedProps: {
            eventType: eventsType,
            capacity: event.capacity
        }
    })) ?? []
}

export const HeaderContent = (d, type) => {
    return(
        <CustomHeaderContainer isToday={moment(d.date).isSame(moment(), "day")} level={d.level} type={type}>
            <>
                {d.level === 0 ? 
                    <>
                        <span>{moment(d.date).format("ddd")}</span>
                        {type === "exceptional" &&
                        <>
                            <span>{moment(d.date).format("DD")}</span>
                            <span>{moment(d.date).format("MMM")}</span>
                        </>}
                    </>
                :   
                    <>
                        <span>{moment(d.date).format("HH")}h</span>
                    </>
                }
            </>
        </CustomHeaderContainer>
    )
}