import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";

import { OptaRoute, DEFAULT_HOMEPAGE } from "routes";
import routes from "routes";
import { LAYOUT } from "Constants";
import { AccountController } from "components/Controller/AuthProvider";
import optaLogo from "assets/img/brand/logo_planning.svg";
import { useState } from "react";

const PrivateRoutes = (props) => {

    const [sidebarActive, setSidebarActive] = useState(true)
    const currentAccount = AccountController.getCurrentAccount();
    const sidebarToggler = () => setSidebarActive(!sidebarActive);
    return (
        <>
            <Sidebar
                routes={routes}
                logo={{
                    innerLink: "/",
                    imgSrc: optaLogo,
                    imgAlt: "OptaLP logo"
                }}
                sidebarActive={sidebarActive} />
            <div
                className="main-content"
                style={sidebarActive ? { marginLeft: '80px' } : { marginLeft: '250px' }}>
                <Switch>
                    {routes
                        .filter(r => r.layout === LAYOUT.MAIN)
                        .filter(r => r.rolesAllowed.includes(currentAccount.role))
                        .map((r: OptaRoute, key: number) => {
                            const RouteComponent = r.component;
                            return (
                                <Route
                                    path={r.getFullPath()}
                                    key={r.path}
                                >
                                    <AdminNavbar
                                        route={r}
                                        sidebarToggler={sidebarToggler}
                                    />
                                    <RouteComponent />
                                </Route>
                            )
                        })}
                    <Redirect to={DEFAULT_HOMEPAGE[currentAccount.role].getFullPath()}/>
                </Switch>
            </div>
        </>
    )
}

export default PrivateRoutes