import {Client, StompConfig} from "@stomp/stompjs";
import optaConfig from "config";
import * as SockJs from "sockjs-client";
import {DOMAIN_HOST, SERVER_HOST, WITH_SSL} from "Constants";

export const getStompClient = (customConfig: StompConfig): Client => {
    let protocol = WITH_SSL ? "wss" : "ws";
    const client = new Client({
        brokerURL: `${protocol}://${DOMAIN_HOST}/${optaConfig.websocket.endpoint}`,
        ...customConfig
    });
    // Fallback code
    if (typeof WebSocket !== 'function') {
        // For SockJS you need to set a factory that creates a new SockJS instance
        // to be used for each (re)connect
        // Note that the URL is different from the WebSocket URL
        client.webSocketFactory = () => new SockJs(`${SERVER_HOST}/${optaConfig.websocket.endpoint}`);
    }
    return client;
}