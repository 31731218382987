import { useKeycloak } from "@react-keycloak/web";
import { ROLE_SET } from "Constants";
import { _require, logErrorGroup } from "Utils";
import StaffsAPI from "api/StaffsAPI";
import { AccountController } from "components/Controller/AuthProvider";
import { useCallback, useEffect, useState } from "react";
import AccountInitiator from "views/AccountInitiator";
import NewLogin from "views/NewLogin";
import PrivateRoutes from './layouts/PrivateRoutes';
import { useToasts } from 'react-toast-notifications';

/**
 * Returned by Keycloak.
 */
export type KeycloakUserInfo = {
    role: string,
    sub: string,
    username?: string,
    preferred_username?: string,
    email: string,
    email_verified: boolean
}

export const INTERNAL_PROVIDER_ID = "INTERNAL";

export type FormattedStaff = {
    lastName: string,
    firstName: string,
    sex: "M" | "F",
    sectorId: number,
    professionId: number,
    id: string | number
}

function loadStaff(userInfo: KeycloakUserInfo): Promise<FormattedStaff> {
    return StaffsAPI.getStaffsByRSQL({
        type: 'account',
        size: 1,
        search: `externalAuthId==${userInfo.sub}`
    })
        .then(accounts => {
            let account = accounts.content && accounts.content.length > 0 ? accounts.content[0] : null;
            if (!!account) {
                if (account.enabled)
                    return {
                        lastName: account.staff.lastName,
                        firstName: account.staff.firstName,
                        sex: account.staff.sex,
                        sectorId: account.staff.sectorId,
                        professionId: account.staff.professionId,
                        id: account.staff.id
                    }
                else
                    return null
            } else {  // Staff not found or not exist, and not internal sub
                return null;
            }
        });
}
const handleLogout = (keycloak?: Keycloak): Promise<void> => {
    sessionStorage.removeItem('lastLocation');
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("id_token");
    return !!keycloak ? keycloak.logout() : Promise.reject();
}
export const NewApp = () => {

    const { initialized, keycloak } = useKeycloak();
    const [viewInitAccountForm, setViewInitAccountForm] = useState(false)
    const [keycloakUserInfo, setKeycloakUserInfo] = useState(null)
    const { addToast } = useToasts()


    const getUserInfos = useCallback((userInfo: KeycloakUserInfo, identityProvider?: string): Promise<void> => loadStaff(userInfo)
    .then(formattedStaff => {
        if (!!formattedStaff) {
            let userRole: string = userInfo.role;
            if (userRole.startsWith("ROLE_")) userRole = userRole.slice(5);
            if (userRole === "SUPER_ADMIN") userRole = "ADMIN";
            AccountController._storeCurrentAccount({
                staff: formattedStaff,
                username: userInfo.preferred_username ?? userInfo.username,
                role: _require.enum(userRole, ROLE_SET),
                enabled: true
            });
            setViewInitAccountForm(false)
        } else {
            setViewInitAccountForm(true)
        }
        setKeycloakUserInfo(userInfo);
    }), [setViewInitAccountForm, setKeycloakUserInfo]);

    useEffect(() => {
        if (!keycloak) return;
        keycloak.onAuthLogout = () => handleLogout(keycloak);
        keycloak.onTokenExpired = () => {
            keycloak.updateToken(1)
                .catch(error => logErrorGroup(error, "authentication.refresh_token"));
        }
        return () => {
            keycloak.onTokenExpired = null;
            keycloak.onAuthLogout = undefined;
        };
    }, [keycloak]);

    useEffect(() => {
        if (!keycloak) return;
        keycloak.onAuthSuccess = () => {
            if (keycloak.token) {
                localStorage.setItem("token", keycloak.token);
                localStorage.setItem("refresh_token", keycloak.refreshToken);
                localStorage.setItem("id_token", keycloak.idToken);
                keycloak.loadUserInfo()
                    .catch(() => keycloak.updateToken(1))
                    .then((userInfo: KeycloakUserInfo) => getUserInfos(userInfo, keycloak.tokenParsed["identity_provider"]))
                    .catch(e => {
                        console.error(e);
                        !!addToast &&
                            addToast("Un erreur est survenue lors du chargement de votre profile. Vous serez redirigé vers la page de connexion",
                                {
                                    appearance: "error",
                                    onDismiss: () => handleLogout(keycloak),
                                    autoDismiss: true,
                                    autoDismissTimeout: 5000
                                });
                    })
            }
        };
        return () => {
            keycloak.onAuthSuccess = null;
        };
    }, [keycloak, addToast, getUserInfos]);

    if (!initialized) {
        return <div>En attente de Keycloak...</div>
    }

    return (
        <>
            {
                !keycloakUserInfo ? <NewLogin />
                    : viewInitAccountForm ?
                        <AccountInitiator
                            keycloakUserInfo={keycloakUserInfo}
                            identityProvider={keycloak.tokenParsed["identity_provider"] ?? INTERNAL_PROVIDER_ID}
                            handleSuccess={() => getUserInfos(keycloakUserInfo, keycloak.tokenParsed["identity_provider"])}
                        />
                        : <PrivateRoutes />
            }
        </>
    );
};

export default NewApp