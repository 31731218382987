import Cycle from "components/ClassWrapper/Cycle";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import moment from "moment";
import { getFullNameWithPrefix, getFullNameWithPrefixDoctor, getLocalDateStrFromISO, printIsoDate, printIsoInterval, printIsoTime, reduceDict } from "Utils";
import image from "../assets/img/brand/optacare_noir.png"
import calendarIcon from "../assets/img/icons/calendar-alt-solid.png"
import idCardIcon from "../assets/img/icons/id-card-regular.png"
import phoneIcon from "../assets/img/icons/phone-alt-solid.png"
import careLogo from "../assets/img/brand/optacare-favicon-square.png"
import Rendezvous from "components/ClassWrapper/Rendezvous";
import Patient from "components/ClassWrapper/Patient";
import type { Styles } from "jspdf-autotable";

const DOC_BASE_HEIGHT = 35


const addHeader = doc => {

    doc.setFontSize(12)
    doc.text(`Imprimé le ${moment().format("DD/MM/YYYY HH:mm")}`, 280, 18, null, null, "right");

    doc.addImage(image, 'PNG', 15, 12, 50, 12.5, undefined, false); 
    doc.setFontSize(13)
    doc.setFont("helvetica", "bold")
    doc.text("Hôtel de Bureaux 2 | Bureau A2", doc.internal.pageSize.width / 2, 18, null, null, "center");
    doc.setFont("helvetica", "normal")
    doc.text("OPTACARE", doc.internal.pageSize.width / 2, 24, null, null, "center");
}


const addFooters = doc => {
    const pageCount = doc.internal.getNumberOfPages()
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text(`Page ${String(i)} sur ${String(pageCount)}`, 280, doc.internal.pageSize.height - 10, null, null, "right");
        doc.setFont('helvetica', 'bold')
        doc.text('2 Rue GUSTAVE EIFFEL, 10430 ROSIERES-PRES-TROYES', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 16, { align: 'center' })
        doc.setFont('helvetica', 'normal')
        doc.text('03 25 72 68 07', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 13, { align: 'center' })
        doc.setFont('helvetica', 'bold')
        doc.text('www.optacare.fr', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 7, {   align: 'center' })
    }
}

export function generatePDFConvocationRdvList (cycle: Cycle): void{

    const doc = new jsPDF("portrait");

    cycle.rendezvousList.forEach(rdv => {
        doc.addPage();
        generateConvocationContent(doc, rdv, cycle.patient);
    })

    doc.deletePage(1)
    doc.output('pdfobjectnewwindow', `liste_rendez-vous_${cycle.patient.lastName.toUpperCase()}-${cycle.patient.firstName}__debut-${cycle.interval.start}.pdf`);
}

export const generateConsultationsTasksPDF = (dateRange: {startDate: string, endDate: string}, consultations: CompleteRdv[], sectorsDictionary: any) => {

    var headers = [
        {dataKey: "date", header: "Date "},
        {dataKey: "patient", header: "Patient"},
        {dataKey: "patientComment", header: "Note"},
        {dataKey: "protocol", header: "Protocole"},
        {dataKey: "speciality", header: "Spécialité"},
        {dataKey: "motive", header: "Motif"},
        {dataKey: "consultation_in_place", header: "Consult. sur place"},
        {dataKey: "doctor", header: "Médecin en charge"},
        {dataKey: "theory_hour", header: "Heure prévu"},
        {dataKey: "theory_hour_rdv", header: "RDV prévu"},
        {dataKey: "real_hour", header: "Heure réel"},
    ]

    let _sectors = reduceDict(sectorsDictionary, "name")

    var data = consultations.map(consult => ({
        date: printIsoDate(consult.rdv.sessionDay),
        patient: getFullNameWithPrefix(consult.patient),
        patientComment: consult.patient.comment ?? "-",
        protocol: consult.protocol.name,
        speciality: _sectors?.[consult.protocol?.sectorId] ?? "-",
        motive: consult.motive?.name ?? "-",
        consultation_in_place: consult.rdv.consultationInPlace ? "Oui" : "Non",
        doctor: getFullNameWithPrefixDoctor(consult.rdv?.doctor, null, "-"),
        theory_hour: printIsoInterval(consult.rdv.consultation),
        theory_hour_rdv: printIsoInterval(consult.rdv.session),
        real_hour: printIsoInterval(consult.rdv.realConsultation)
    }))

    const doc = new jsPDF("landscape");

    addHeader(doc)

    //Start protocol review
    doc.setFontSize(12)
    doc.setFont("helvetica", "bold")
    let _theoricalRange = `Consultations du : ${moment(dateRange.startDate).format("DD/MM")} au ${moment(dateRange.endDate).format("DD/MM")}`, 
        _textWidth = doc.getTextWidth(_theoricalRange),
        _height = DOC_BASE_HEIGHT+5
    
    doc.text(_theoricalRange, 15, _height, {}, null, "left");
    doc.line(15,_height+1, 15 + _textWidth, _height+1)

    autoTable(doc, {
        tableWidth: doc.internal.pageSize.getWidth() - 40,
        margin: { left: 15, right: 15, bottom: 20},
        startY: _height+5,
        body: data,
        columns: headers,
        theme: "grid",
        headStyles: {fillColor: "#d9d9d9", textColor: "#000"},
        columnStyles: {
            startHour: {halign: "center"}, endHour: {halign: "center"}, duration: {halign: "center"}
        }
    });

    addFooters(doc)

    doc.output('pdfobjectnewwindow', `listes_consultations__${moment(dateRange.startDate).format("DD/MM")}__debut-${moment(dateRange.endDate).format("DD/MM")}.pdf`);

}

export function generatePDFConvocation(rdv: CompleteRdv){

    const doc = new jsPDF("portrait");
    generateConvocationContent(doc, rdv.rdv, rdv.patient)
    doc.output('pdfobjectnewwindow', `rendez_vous_${rdv.patient.lastName}_${moment(rdv.rdv.sessionDay).format("DD/MM/YYYY")}.pdf`);
}

function generateConvocationContent(doc: any, rdv: Rendezvous, patient: Patient){

    doc.setFontSize(12)

    let HEADER_HEIGHT = 65,
        START_BOX_HEIGHT = HEADER_HEIGHT + 40,
        BOX_HEIGHT = 90


    doc.addImage(image, 'PNG', 15, 12, 60, 20, undefined, false); 
    doc.addImage(careLogo, 'PNG', 175, 12, 20, 20, undefined, false); 
    doc.setFontSize(20)
    doc.setFont("helvetica", "bold")
    doc.text("Hôtel de Bureaux 2 | Bureau A2", 15, 50, null, null);
    doc.setFontSize(18)
    doc.setFont("helvetica", "normal")
    doc.text("OPTACARE", 15, 58, null, null);
    
    doc.setFontSize(14)
    doc.text(`Prénom, nom : ${getFullNameWithPrefix(patient)}`, 15, HEADER_HEIGHT + 10, null, null);
    // doc.text(`Sexe : ${rdv.patient.sex === "M" ? "Homme" : "Femme"}`, 15, HEADER_HEIGHT + 16, null, null);
    doc.text(`Date de naissance : ${!!patient.birthday ? getLocalDateStrFromISO(patient.birthday) : ""}`, 15, HEADER_HEIGHT + 17, null, null);
    doc.text(`IPP : ${patient.externalId?.value ?? "N/A"}`, 15, HEADER_HEIGHT + 24, null, null);

    //Top Box
    doc.line(15,START_BOX_HEIGHT,195,START_BOX_HEIGHT)
    doc.line(15,START_BOX_HEIGHT,15,START_BOX_HEIGHT+BOX_HEIGHT)
    doc.line(75,START_BOX_HEIGHT,75,START_BOX_HEIGHT+BOX_HEIGHT)
    doc.line(135,START_BOX_HEIGHT,135,START_BOX_HEIGHT+BOX_HEIGHT)
    doc.line(195,START_BOX_HEIGHT,195,START_BOX_HEIGHT+BOX_HEIGHT)
    doc.line(15,START_BOX_HEIGHT+BOX_HEIGHT,195,START_BOX_HEIGHT+BOX_HEIGHT)
    //---------

    let START_BOX_TEXT = START_BOX_HEIGHT+10

    //Left box section
    doc.addImage(calendarIcon, 'PNG', 40, START_BOX_HEIGHT+3, 10, 10, undefined, false); 
    doc.setFontSize(12)
    doc.setFont("helvetica", "bold")

    let _nextAppoint = "PROCHAIN RENDEZ-VOUS",
        _nextAppointWidth = doc.getTextWidth(_nextAppoint)
    
    doc.text(_nextAppoint, 18, START_BOX_TEXT + 10, {}, null);
    doc.line(18,START_BOX_TEXT+11, 18 + _nextAppointWidth, START_BOX_TEXT+11)

    doc.setFontSize(12)
    // doc.setFont("helvetica", "normal")
    doc.text(`Le : ${printIsoDate(rdv.sessionDay)}`, 17, START_BOX_TEXT + 20, {}, null);
    doc.text(`à : Basse-Normandie`, 17, START_BOX_TEXT + 28, {}, null);
    !!rdv.doctor && doc.text(`Avec le docteur :`, 17, START_BOX_TEXT + 36, {}, null);
    !!rdv.doctor && doc.text(getFullNameWithPrefixDoctor(rdv.doctor), 17, START_BOX_TEXT + 42, {maxWidth: 52}, null);

    doc.text(`Prise de sang : ${moment(rdv.sessionDay).subtract(2, "day").format("DD/MM/YYYY")}`, 17, !!rdv.doctor ? START_BOX_TEXT + 56 : START_BOX_TEXT + 36, {}, null);
    doc.text(`ECBU : ____/____/______`, 17, !!rdv.doctor ? START_BOX_TEXT + 64 : START_BOX_TEXT + 44, {}, null);

    doc.text(`Résultats à faxer au :`, 17, START_BOX_TEXT + 70, {}, null);
    doc.text(`03 25 72 68 07`, 17, START_BOX_TEXT + 76, {}, null);

    //---------

    //Center box section
    doc.addImage(idCardIcon, 'PNG', 100, START_BOX_HEIGHT+3, 10, 10, undefined, false); 
    doc.setFontSize(12)
    doc.setFont("helvetica", "bold")
    doc.text(`MUNISSEZ-VOUS`, 87, START_BOX_TEXT + 10, {}, null);

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text('\u2022 d\'une pièce d\'identité (CNI, carte de séjour, passeport),', 77, START_BOX_TEXT + 20, {maxWidth: 60}, null);
    doc.text('\u2022 de votre carte vitale,', 77, START_BOX_TEXT + 32, {maxWidth: 60}, null);
    doc.text('\u2022 de votre carte de mutuelle', 77, START_BOX_TEXT + 38, {maxWidth: 60}, null);
    //---------


    //Right box section
    doc.addImage(phoneIcon, 'PNG', 158, START_BOX_HEIGHT+3, 10, 10, undefined, false); 
    doc.setFontSize(12)
    doc.setFont("helvetica", "bold")
    doc.text(`CONTACTS UTILES`, 144, START_BOX_TEXT + 10, {}, null);

    doc.setFontSize(11)
    doc.setFont("helvetica", "normal")
    doc.text(`Secrétariat : 03 25 72 68 07`, 137, START_BOX_TEXT + 20, {}, null);
    doc.text(`OPTACARE, coordinateur :`, 137, START_BOX_TEXT + 28, {}, null);
    doc.text(`03 25 72 68 07`, 137, START_BOX_TEXT + 33, {}, null);
    doc.text(`OPTA LP S.A.S, cadre :`, 137, START_BOX_TEXT + 41, {}, null);
    doc.text(`03 25 72 68 07`, 137, START_BOX_TEXT + 46, {}, null);
    
    let _supportPhone = "Soins de supports :",
    _supportPhoneWidth = doc.getTextWidth(_supportPhone)
    
    doc.text(_supportPhone, 137, START_BOX_TEXT + 60, {}, null, "left");
    doc.line(137,START_BOX_TEXT+61, 137 + _supportPhoneWidth, START_BOX_TEXT+61)

    doc.setFontSize(10)
    doc.text(`O. NIYO : 03 25 72 68 07`, 137, START_BOX_TEXT + 66, {}, null);
    doc.text(`O. NATAN : 03 25 72 68 07`, 137, START_BOX_TEXT + 71, {}, null);
    doc.text(`O. LEAX : 03 25 72 68 07`, 137, START_BOX_TEXT + 76, {}, null);
    //---------
   
    addFooters(doc)
}

export type PlanningTableHeader = {
    dataKey: String,
    header: String,
    dataAccessor: (crdv: CompleteRdv) => any,
    style?: {
        [key: string]: Partial<Styles>
    }
}

const planningTableHeaders: PlanningTableHeader[] = [
    {
        dataKey: "place",
        header: "Hbgt",
        dataAccessor: crdv => crdv.rdv.place.number,
    },
    {
        dataKey: "date",
        header: "Date",
        dataAccessor: crdv => printIsoDate(crdv.rdv.sessionDay)
    },
    {
        dataKey: "start",
        header: "Début",
        dataAccessor: crdv => printIsoTime(crdv.rdv.session.start),
        style: {
            halign: "center"
        },
    },
    {
        dataKey: "patient",
        header: "Patient",
        dataAccessor: crdv => getFullNameWithPrefix(crdv.patient)
    },
    {
        dataKey: "ipp",
        header: "IPP",
        dataAccessor: crdv => crdv.patient.externalId?.value
    },
    {
        dataKey: "birthday",
        header: "Date de naissance",
        dataAccessor: crdv => crdv.patient.birthday ? getLocalDateStrFromISO(crdv.patient.birthday) : ""
    },
    {
        dataKey: "protocol",
        header: "Protocole",
        dataAccessor: crdv => crdv.protocol.name
    },
    {
        dataKey: "doctor",
        header: "Médecin",
        dataAccessor: crdv => getFullNameWithPrefixDoctor(crdv.rdv.doctor, null, "-")
    },
    {
        dataKey: "end",
        header: "Fin",
        dataAccessor: crdv => printIsoTime(crdv.rdv.session.end),
        style: {
            halign: "center"
        },
    },
    {
        dataKey: "patientComment",
        header: "Note",
        dataAccessor: crdv => crdv.patient.comment,
        style: {
            cellWidth: 20
        },
    },
];

/**
 * 
 * @param {Object} dateRange 
 * @param {string} dateRange.startDate ISO-8601 Date
 * @param {string} dateRange.endDate ISO-8601 Date
 * @param {CompleteRdv[]} completeRdvs 
 * @param {PlanningTableHeader[]} [columns=planningTableHeaders] override default columns
 * @param {jsPDF | undefined} existingDoc use an existing page instead of creating new one
 */
export function generatePlanningRdvList(dateRange: {startDate: string, endDate: string}, completeRdvs: CompleteRdv[], columns: PlanningTableHeader[] = planningTableHeaders, existingDoc?: jsPDF){
    const startDate = getLocalDateStrFromISO(dateRange.startDate);
    const endDate = getLocalDateStrFromISO(dateRange.endDate);
    const singleDate = startDate === endDate;

    const data = completeRdvs.map(crdv => {
        const d = {};
        columns.forEach(c => d[c.dataKey] = c.dataAccessor(crdv));
        return d;
    })

    const doc = !existingDoc ? new jsPDF("landscape") : existingDoc;

    addHeader(doc)

    //Start protocol review
    doc.setFontSize(12)
    doc.setFont("helvetica", "bold")
    let _theoricalRange = singleDate ? `Les RDV du ${startDate}` : `Les RDV du ${startDate} au ${endDate}`, 
        _textWidth = doc.getTextWidth(_theoricalRange),
        _height = DOC_BASE_HEIGHT+5
    
    doc.text(_theoricalRange, 15, _height, {}, null, "left");
    doc.line(15,_height+1, 15 + _textWidth, _height+1)

    autoTable(doc, {
        tableWidth: doc.internal.pageSize.getWidth() - 20,
        margin: { left: 10, right: 10, bottom: 5},
        startY: _height+5,
        body: data,
        columns: columns,
        theme: "grid",
        headStyles: {fillColor: "#d9d9d9", textColor: "#000"},
        columnStyles: columns.reduce((currentStyles, column) => ({...currentStyles, [column.dataKey]: column.style}), {})
    });

    if (!existingDoc)
        doc.output('pdfobjectnewwindow', singleDate ? `Liste_RDV__${startDate}` : `list_RDV__${startDate}__à__${endDate}.pdf`);

}

export function generatePlanningRdvLists(dateRange: {startDate: string, endDate: string}, completeRdvLists: CompleteRdv[][], columns: PlanningTableHeader[] = planningTableHeaders) {
    const doc = new jsPDF("landscape");
    completeRdvLists.forEach(list => {
        doc.addPage();
        generatePlanningRdvList(dateRange, list, columns, doc);
    });
    doc.deletePage(1);
    doc.output('pdfobjectnewwindow', dateRange.startDate === dateRange.endDate ? `Liste_RDV__${dateRange.startDate}` : `list_RDV__${dateRange.startDate}__à__${dateRange.endDate}.pdf`);
}