import {authCaller as axiosInstance} from "../components/Controller/AuthProvider";

function getSectors(){
    return axiosInstance.get(`/sectors`)
        .then(response => response.data.content)
}

function getProfessions(){
    return axiosInstance.get(`/professions`)
        .then(response => response.data.content)    
}

function getResourceByRsql(type, params){
    return axiosInstance.get(`/rsql`, {
        params: {
            type: type,
            ...params
        }
    })
    .then(response => response.data.content)    

}

const ResourcesAPI = {
    getSectors,
    getProfessions,
    getResourceByRsql
}

export default ResourcesAPI;