import React from "react";
import * as PropTypes from "prop-types";
import MaterialTable, { Localization } from "material-table";
import UController from "components/Controller/UController";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import Staff from "components/ClassWrapper/Staff";

const MATERIAL_TABLE_LOCALIZATION_FR: Localization = {
    body: {
        emptyDataSourceMessage: "Aucun profil défini",
        editRow: {
            deleteText: "Vous êtes sûr de désactiver ce profile ?",
            cancelTooltip: "Annuler",
            saveTooltip: "Sauvegarder",
        },
        addTooltip: "Ajouter un profile",
        deleteTooltip: "Désactiver le profile",
    },
    header: {
        actions: "Actions",
    },
}

class SyncStaffList extends React.PureComponent {
    state = {
        tableRef: React.createRef(),
    };

    static propTypes = {
        onStaffSelect: PropTypes.func,
        /**
         * @type {Dict<string>}
         */
        sectorDict: PropTypes.objectOf(PropTypes.string),
        /**
         * @type {Dict<string>}
         */
        professionDict: PropTypes.objectOf(PropTypes.string),
    };

    onRowAdd = newData => UController.staff.post(newData);

    onRowUpdate = (newData) => UController.staff.patch(newData);

    onDataQuery = query => {
        /**
         *
         * @type {Filter[]}
         */
        let filters = query.filters;
        // Build search query
        let searchQuery = [];
        if (filters.length > 0)
            filters.forEach(filter => {
                switch (filter.column.field) {
                    case "firstName":
                    case "lastName":
                        // Find all protocol including given input
                        searchQuery.push(filter.column.field + "=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    case "sectorId":
                        if (filter.value.length > 0)
                            searchQuery.push("sector.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "professionId":
                        if (filter.value.length > 0)
                            searchQuery.push("profession.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "externalId.value":
                        searchQuery.push("externalId.value=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    case "enabled":
                        searchQuery.push("enabled==" + (filter.value === "checked" ? "true" : "false"));
                        break;
                    default:
                        break;
                }
            });
        return UController.staff.iQuery(query, searchQuery);
    };

    toggleStaffStatus = (staff: Staff): Promise<Void> => {
        if (staff.enabled) {
            if (window.confirm("Voulez-vous désactiver ce profil ?"))
                UController.staff.delete(staff)
                    .then(() => this.state.tableRef.current && this.state.tableRef.current.onQueryChange())
                    .catch(error => { console.error(error); alert("Un erreur est survenue. Veuillez ressayer ultérieurement") })
        } else {
            UController.staff.patch({
                id: staff.id,
                enabled: true
            })
                .then(() => this.state.tableRef.current && this.state.tableRef.current.onQueryChange())
                .catch(error => { console.error(error); alert("Un erreur est survenue. Veuillez ressayer ultérieurement") })
        }
    }

    title = "Personnels";

    render() {
        // noinspection JSUnresolvedFunction
        return <MaterialTable
            tableRef={this.state.tableRef}
            columns={[
                {
                    field: "enabled",
                    title: "Actif",
                    type: "boolean",
                },
                {
                    field: "firstName",
                    title: "Prénom",
                },
                {
                    field: "lastName",
                    title: "Nom",
                },
                {
                    field: "sectorId",
                    title: "Spécialité",
                    sorting: false,
                    lookup: this.props.sectorDict
                },
                {
                    field: "rgbColor",
                    title: "Couleur",
                    filtering: false,
                    editComponent: props => (
                        <input
                            type="color"
                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                        />
                    ),
                    render: rowData => <div
                        style={{
                            width: "100px",
                            height: "20px",
                            background: rowData.rgbColor,
                        }}
                    />
                },
                {
                    field: "professionId",
                    title: "Profession",
                    sorting: false,
                    lookup: this.props.professionDict,
                },
                {
                    field: "sex",
                    title: "Sexe",
                    lookup: {
                        "M": "M",
                        "F": "F"
                    }
                },
                {
                    field: "externalId.value",
                    title: "Transcodage",
                },
            ]}
            data={this.onDataQuery}
            title={this.title}
            editable={{
                onRowAdd: this.onRowAdd,
                onRowUpdate: this.onRowUpdate
            }}
            options={{
                exportButton: true, exportDelimiter: ';',
                exportFileName: this.title,
                filtering: true,
                grouping: false,
                search: false,
                debounceInterval: 1000,
                addRowPosition: "first",
            }}
            actions={[
                (rowData) => {
                    return {
                        icon: rowData.enabled ? "delete" : "rotate_left",
                        tooltip: rowData.enabled ? "Désactiver" : "Activer",
                        onClick: (event, rowData) => this.toggleStaffStatus(rowData)
                    };
                },
            ]}
            localization={MATERIAL_TABLE_LOCALIZATION_FR}
        />;
    }
}

class StaffList extends React.PureComponent {
    render() {
        return (
            <AsyncLoaderWrapper loader={() => Promise.all([
                UController.sector.getNameDict(),
                UController.profession.getNameDict(),
            ])
                .then(([sectorDict, professionDict]) =>
                ({
                    sectorDict,
                    professionDict,
                }))}
                onLoadingMessage={"En cours de chargement de personnels"}
            >
                <SyncStaffList {...this.props} />
            </AsyncLoaderWrapper>
        );
    }
}

export default StaffList;