import React, {ReactElement} from "react";
import * as PropTypes from "prop-types";
import EntityPicker from "components/picker/EntityPicker";
import Motive from "components/ClassWrapper/Motive";
import UController from "components/Controller/UController";

export default class MotivePicker extends React.PureComponent {

    static propTypes = {
        onChooseMotive: PropTypes.func.isRequired,
    };

    render() {
        return <EntityPicker
            async
            placeholder={"Motif (optionnel)"}
            onChooseValue={this.props.onChooseMotive}
            getFilteredSuggestions={this.getFilteredMotives}
            getSuggestionValue={this.getMotiveName}
            fillInputValue={this.getMotiveName}
            renderSuggestion={this.renderMotive}/>;
    }

    getFilteredMotives = (hint: string): Promise<Motive[]> => {
        hint = hint.trim();
        if (hint.length === 0)
            return Promise.resolve([]);
        else
            return UController.motive.searchSuggest(hint);
    };

    getMotiveName = (motive: Motive): string => motive.name;

    renderMotive = (motive: Motive): string | ReactElement => motive.name;
}