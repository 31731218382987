import React from "react";
import * as PropTypes from "prop-types";
import UncontrolledAlert from "reactstrap/es/UncontrolledAlert";
import moment from "moment";

class DateRangePicker extends React.Component {

    static propTypes = {
        /**
         * Callback on user done choosing interval
         * @function
         * @param {string | null} startDate
         * @param {string | null} endDate
         */
        onRangeChosen: PropTypes.func.isRequired,
        className: PropTypes.string,
        startTextPlaceholder: PropTypes.string,
        endTextPlaceholder: PropTypes.string,
        startDefaultValue: PropTypes.string,
        endDefaultValue: PropTypes.string,
        /**
         * Ignore end, or not. Default to <b>false</b>
         */
        openRight: PropTypes.bool,
        min: PropTypes.string,
        max: PropTypes.string,
    };

    static defaultProps = {
        className: "",
        startTextPlaceholder: "Date de début",
        endTextPlaceholder: "Date de fin",
        startDefaultValue: "",
        endDefaultValue: "",
        openRight: false,
    };

    state = {
        /**
         * @type {string}
         */
        startDate: this.props.startDefaultValue,
        /**
         * @type {string | null}
         */
        endDate: this.props.endDefaultValue,
        /**
         * @type {string}
         */
        message: null,
    };

    /**
     *
     * @param {string} selectedDate
     */
    onStartDateChosen = (selectedDate) =>
        !!this.state.endDate ?
            this.setState({
                message: this.state.endDate < selectedDate ? "La fin doit être ultérieure ou égale au début." : null,
                startDate: this.state.endDate < selectedDate ? this.props.startDefaultValue : selectedDate,
            }, () => this.props.onRangeChosen(this.state.startDate, this.state.endDate))
            : this.setState({startDate: selectedDate},
            () => this.props.onRangeChosen(this.state.startDate, this.state.endDate));

    /**
     *
     * @param {string} selectedDate
     */
    onEndDateChosen = (selectedDate) =>
        !!this.state.startDate ?
            this.setState({
                message: this.state.startDate > selectedDate ? "La fin doit être ultérieure ou égale au début." : null,
                endDate: this.state.startDate > selectedDate ? this.props.endDefaultValue : selectedDate,
            }, () => this.props.onRangeChosen(this.state.startDate, this.state.endDate))
            : this.setState({endDate: selectedDate},
            () => this.props.onRangeChosen(this.state.startDate, this.state.endDate));

    componentDidMount = () =>
        !!this.state.startDate && (this.props.openRight || !!this.state.endDate) &&
        this.props.onRangeChosen(this.state.startDate, this.state.endDate);

    updateInterval = ({start, end}) => {
        this.setState({
            startDate: moment(start).toISOString(true).substring(0,10),
            endDate: moment(end).subtract(1, "day").toISOString(true).substring(0,10)
        })
    };

    render() {
        return (
            <>
                <div className={this.props.className} style={{display: "flex"}}>
                    <div className="d-flex flex-row align-items-center mb-2 inputs-range-container">
                        <input className="form-control date-range-input"
                            type={"date"}
                            value={this.state.startDate}
                            onChange={event => this.onStartDateChosen(event.target.value)}
                            id={"startDate"}
                            min={this.props.min}
                            max={this.props.max}
                        />
                        {/* <UncontrolledTooltip target={"startDate"}>
                            {this.props.startTextPlaceholder}
                        </UncontrolledTooltip> */}
                        {
                            !this.props.openRight &&
                                <>
                                    <i className="fas fa-arrow-right mx-2"/>
                                    <input className="form-control date-range-input"
                                        type={"date"}
                                        value={this.state.endDate}
                                        onChange={event => this.onEndDateChosen(event.target.value)}
                                        id={"endDate"}
                                        min={this.props.min}
                                        max={this.props.max}
                                    />
                                </>
                        }
                        {/* <UncontrolledTooltip target={"endDate"}>
                            {this.props.endTextPlaceholder}
                        </UncontrolledTooltip> */}
                    </div>
                    {
                        this.state.message ?
                            <UncontrolledAlert color="danger">
                                {this.state.message}
                            </UncontrolledAlert>
                            : null
                    }
                </div>
            </>
        );
    }

}

export default DateRangePicker;