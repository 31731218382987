/**
 * Date, time or date time. Should follow ISO format
 */
export type TimeString = string;
export type GenericTimeInterval = {
    start: TimeString,
    end: TimeString
}
export type CompressedTimeInterval = {
    /**
     * number of same time intervals
     */
    effective: number,
    start: TimeString,
    end: TimeString,
}
/**
 * ISO 8601 date
 */
export type DateString = TimeString;
/**
 * ISO 8601 time
 */
export type LocalTimeString = TimeString;
export type DateTimeString = TimeString;
/**
 * Combined ISO 8601 with start and end as date/time string
 */
export type Interval<E> = {
    /**
     * ISO 8601
     */
    start: E,
    /**
     * ISO 8601
     */
    end: E
}
/**
 * ISO 8601 of start and end at local time
 */
export type LocalTimeInterval = Interval<LocalTimeString>;
/**
 * ISO 8601 of start and end date
 */
export type LocalDateInterval = Interval<DateString>;
export type UnixInterval = Interval<number>;

export const hasStarted = (i: ?Interval): boolean => !!i?.start;
export const hasEnded = (i: ?Interval): boolean => hasStarted(i) && !!i?.end;
export const hasStartedNotEnded = (i: ?Interval): boolean => hasStarted(i) && !i?.end;
export const reallyExists = (i: ?Interval): boolean => !!i && !!i.start && !!i.end && i.start < i.end;