import React, {PureComponent} from "react";
import UController from "components/Controller/UController";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import globalOptaConfig from "config";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import "react-datetime/css/react-datetime.css";
import * as Datetime from "react-datetime";
import moment from "moment";
import {extractHourAndMinFromISOTime} from "Utils";
import type {TimeString} from "components/ClassWrapper/TimeClasses";
import PlanningConfigurationManager from "./PlanningConfigurationManager";

const extractHour = ({openingHour, realOpeningHour, closingHour}): {
    openingHour: TimeString,
    realOpeningHour: TimeString,
    closingHour: TimeString
} =>
        ({
            openingHour: extractHourAndMinFromISOTime(openingHour),
            realOpeningHour: extractHourAndMinFromISOTime(realOpeningHour),
            closingHour: extractHourAndMinFromISOTime(closingHour),
        });

/**
 * Planning configuration
 */
class SyncEnvConfigList extends PureComponent {

    props: {
        EnvConfig?: OptaConfig
    };

    state: { config: OptaConfig, running: boolean } = {
        config: {
            ...this.props.EnvConfig,
            ...extractHour(this.props.EnvConfig)
        },
        running: false,
        message: "",
    };

    resetValues = () => this.setState({config: {...this.props.EnvConfig, ...extractHour(this.props.EnvConfig)}});

    saveValues = () => Promise.resolve()
            .then(() => this.setState({running: true}))
            .then(() => UController.planning.saveConfig(this.state.config))
            .then((modifiedConfig: OptaConfig) => this.setState({
                config: {...modifiedConfig, ...extractHour(modifiedConfig)},
                running: false
            }))
            .catch((error) => {
                console.error(error);
                this.setState({
                    running: false,
                    message: "Une erreur est survenu. Veuillez vérifier vos entrées ou la connexion."
                });
            });

    render() {
        // noinspection JSUnresolvedFunction
        return (
            <Row className="my-4">
                <Col xs={12} lg={6} className="mb-4">
                    <Card>
                        <CardHeader>
                            <h2 className="text-white mr-auto">
                                Paramètres de planification
                            </h2>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row form>
                                    <Col xs={12}>
                                        <h3 className="font-weight-600">Ouverture du service</h3>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="openingHour">Ouverture</Label>
                                            <div id="openingHour">
                                                <Datetime dateFormat={false}
                                                        timeFormat={globalOptaConfig.timeFormat}
                                                        value={this.state.config.openingHour}
                                                        onChange={(m: moment | string) => m instanceof moment && this.setState({
                                                            config: {
                                                                ...this.state.config,
                                                                openingHour: m.format(globalOptaConfig.timeFormat),
                                                            }
                                                        })}
                                                />
                                            </div>
                                            <FormText color="muted">
                                                Heure d'ouverture du service
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="realOpeningHour">Début</Label>
                                            <div id="realOpeningHour">
                                                <Datetime dateFormat={false}
                                                        timeFormat={globalOptaConfig.timeFormat}
                                                        value={this.state.config.realOpeningHour}
                                                        onChange={(m: moment | string) => m instanceof moment && this.setState({
                                                            config: {
                                                                ...this.state.config,
                                                                realOpeningHour: m.format(globalOptaConfig.timeFormat)
                                                            }
                                                        })}
                                                />
                                            </div>
                                            <FormText color="muted">
                                                Heure d'accueil des patients
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="closingHour">Fermeture</Label>
                                            <div id="closingHour">
                                                <Datetime dateFormat={false}
                                                        timeFormat={globalOptaConfig.timeFormat}
                                                        value={this.state.config.closingHour}
                                                        onChange={(m: moment | string) => m instanceof moment && this.setState({
                                                            config: {
                                                                ...this.state.config,
                                                                closingHour: m.format(globalOptaConfig.timeFormat)
                                                            }
                                                        })}
                                                />
                                            </div>
                                            <FormText color="muted">
                                                Heure de fermeture du service
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col xs={12}>
                                        <h3>Unité temporelle</h3>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="timeSlotLength">Précision du créneau</Label>
                                            <Input id="timeSlotLength"
                                                type="number"
                                                min={1}
                                                value={this.state.config.timeSlotLength}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                    config: {
                                                        ...this.state.config,
                                                        timeSlotLength: e.target.value
                                                    }
                                                })}
                                            />
                                            <FormText color="muted">
                                                Découpage horaire par créneau
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="consultationLength">Consultation</Label>
                                            <Input id="consultationLength"
                                                type="number"
                                                min={1}
                                                value={this.state.config.consultationLength}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                    config: {
                                                        ...this.state.config,
                                                        consultationLength: e.target.value
                                                    }
                                                })}
                                            />
                                            <FormText color="muted">
                                                Durée de la consultation en minutes
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="installationLength">Installation</Label>
                                            <Input id="installationLength"
                                                type="number"
                                                min={1}
                                                value={this.state.config.installationLength}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                    config: {
                                                        ...this.state.config,
                                                        installationLength: e.target.value
                                                    }
                                                })}
                                            />
                                            <FormText color="muted">
                                                Durée de l'installation en minutes
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col xs={12}>
                                        <h3>Divers</h3>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="numMultiTask">Multi-tâche</Label>
                                            <Input id="numMultiTask"
                                                type="number"
                                                min={1}
                                                value={this.state.config.numMultiTask}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                    config: {
                                                        ...this.state.config,
                                                        numMultiTask: e.target.value
                                                    }
                                                })}
                                            />
                                            <FormText color="muted">
                                                Nombre de patients qu'une infirmière peut observer simultanément
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="daysDelayLimit">Flexibilité de RDV</Label>
                                            <Input id="daysDelayLimit"
                                                type="number"
                                                min={0}
                                                value={this.state.config.daysDelayLimit}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                    config: {
                                                        ...this.state.config,
                                                        daysDelayLimit: e.target.value
                                                    }
                                                })}
                                            />
                                            <FormText color="muted">
                                                Décalage toléré pour un rdv (en nombre de jours)
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form className="d-flex flex-row-reverse">
                                    <ButtonGroup>
                                        <Button className="bg-danger text-white" onClick={this.resetValues}>
                                            Annuler
                                        </Button>
                                        <Button className="bg-success text-white" onClick={this.saveValues}>
                                            Sauvegarder
                                        </Button>
                                    </ButtonGroup>
                                    {this.state.running && <span className="mx-2"><i className="fa fa-spinner"/></span>}
                                </Row>
                                {this.state.message &&
                                <Row>
                                    <span className="text-danger">{this.state.message}</span>
                                </Row>}
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className="mb-4">
                    <Card>
                        <CardHeader>
                            <h2 className="text-white mr-auto">
                                Paramètres des disponibilités
                            </h2>  
                        </CardHeader>
                        <CardBody>
                            <PlanningConfigurationManager/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

class EnvConfigList extends PureComponent {
    render() {
        return (
                <AsyncLoaderWrapper loader={() => UController.planning.getConfig()
                        .then((EnvConfig) => ({EnvConfig}))}
                                    onLoadingMessage={"En cours de chargement de la liste de matériel"}>
                    <SyncEnvConfigList/>
                </AsyncLoaderWrapper>
        );
    }
}

export default EnvConfigList;