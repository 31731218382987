import React from "react";
import {Modal, ModalBody, ModalHeader, Form, FormGroup, ButtonGroup, Button} from "reactstrap";

const ConfirmBox = ({onCancel, onConfirm, message}: { onCancel: () => void, onConfirm: () => void, message: React.ReactNode }) => {
    const onConfirmWrap = (e) => {e.preventDefault(); onConfirm();}
    const onCancelWrap = (e) => {e.preventDefault(); onCancel();}
    return (
            <Modal isOpen={true}>
                <ModalHeader className="bg-primary" toggle={onCancel}>
                    <span className="text-white display-4">Confirmation</span>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={onConfirmWrap} className="d-flex flex-column">
                        <FormGroup inline={true} className="d-flex justify-content-between">
                            {message}
                        </FormGroup>
                        <ButtonGroup className="mx-auto">
                            <Button className="bg-success text-white" type={"submit"}>OK</Button>
                            <Button className="bg-danger text-white" type={"button"} onClick={onCancelWrap}>Annuler</Button>
                        </ButtonGroup>
                    </Form>
                </ModalBody>
            </Modal>
    )
};

export default ConfirmBox;