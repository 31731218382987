import React, {useState, useRef, useEffect, useImperativeHandle, forwardRef} from 'react'
// import { DateRange } from 'react-date-range';
import { DateRangePicker, DefinedRange } from 'react-date-range';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as rdrLocales from 'react-date-range/dist/locale';
import { isMobile } from 'react-device-detect';

type Props = {
    reloadAuto: boolean,
    horizon: {from: Moment, to: Moment},
    handleSubmit(e:{from:Moment, to:Moment}): void,
    ref: any,
    color?:string,
    popupPosition: "left" | "right",
    definedPeriodsProposals: "short" | "long",
    intervalFormat?: "numeric" | "literal"
}

const Container = styled("div")`
    position:relative;
    z-index: 999;
    .date_range{
        padding: 5px 15px;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 3px;
        border: ${props => (props.isOpen && !isMobile) ? "1px solid #007bff" : "" };
        color:${props => props.color};
        display: flex;
        align-items: center;
        &:hover{
            border: ${props => (props.isOpen && !isMobile) ? "1px solid #007bff" : "" };
        }
    }
    .popup{
        background: #fff;
        position: absolute;
        left: ${props => props.popupPosition === "left" ? 0 : "inherit"};
        right: ${props => props.popupPosition === "right" ? 0 : "inherit"};
        top: 40px;
        z-index: 10;
        box-shadow:
            0 0px 1.7px rgba(0, 0, 0, 0.01),
            0 0px 4.6px rgba(0, 0, 0, 0.015),
            0 0px 11.2px rgba(0, 0, 0, 0.02),
            0 0px 37px rgba(0, 0, 0, 0.03);
        .rdrDefinedRangesWrapper{
            background: #fff;
            .rdrStaticRange{
                background: #fff;
            }
        }
        .rdrCalendarWrapper{
            .rdrDateDisplayWrapper{background-color: #fff}
            .rdrMonthAndYearWrapper{background-color: #fff}
            .rdrWeekDays{background-color: #fff}
            .rdrInfiniteMonths {background-color: #fff}
        }
    }
    @media (max-width: 540px){
        .popup{
            box-shadow:
                0 2.8px 2.2px rgba(0, 0, 0, 0.02),
                0 6.7px 5.3px rgba(0, 0, 0, 0.028),
                0 12.5px 10px rgba(0, 0, 0, 0.035),
                0 22.3px 17.9px rgba(0, 0, 0, 0.042),
                0 41.8px 33.4px rgba(0, 0, 0, 0.05),
                0 100px 80px rgba(0, 0, 0, 0.07);
        }
    }
`

const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        moment(range.startDate).isSame(definedRange.statDate,'day') && moment(range.endDate).isSame(definedRange.endDate,'day')
      );
    },
  };
  
function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}


const DateRangeSelector: React.FC<Props> = forwardRef((props, ref) => {

    const {reloadAuto, horizon, handleSubmit, color, popupPosition, definedPeriodsProposals, intervalFormat} = props

    const [isOpen, setIsOpen] = useState(false)
    const [needToReload, setNeedToReload] = useState(false)
    const node = useRef()

    const [selectedRange, setSelectedRange] = useState([{
        startDate: moment(horizon.from).toDate(),
        endDate: moment(horizon.to).toDate(),
        key: 'selection'
    }])

    const staticRanges = definedPeriodsProposals === "long" ? 
        createStaticRanges([
            {
                label: 'Semaine en cours',
                range: () => ({
                    startDate: moment().startOf("week").toDate(),
                    endDate: moment().endOf("week").toDate(),
                }),
            },
            {
                label: 'Semaine glissante',
                range: () => ({
                    startDate: moment().toDate(),
                    endDate: moment().add(1, "week").toDate(),
                }),
            },
            {
                label: '2 semaines',
                range: () => ({
                    startDate: moment().toDate(),
                    endDate: moment().add(2, "week").toDate(),
                }),
            },
            {
                label: 'Mois en cours',
                range: () => ({
                    startDate: moment().startOf("month").toDate(),
                    endDate: moment().endOf("month").toDate(),
                }),
            },
            {
                label: 'Mois glissant',
                range: () => ({
                    startDate: moment().toDate(),
                    endDate: moment().add(1, "month").toDate(),
                }),
            },
        ]) : 
        createStaticRanges([
            {
                label: "Aujourd'hui",
                range: () => ({
                    startDate: moment().startOf("day").toDate(),
                    endDate: moment().endOf("day").toDate(),
                }),
            },
            {
                label: "Demain",
                range: () => ({
                    startDate: moment().add(1, "day").startOf("day").toDate(),
                    endDate: moment().add(1, "day").endOf("day").toDate(),
                }),
            },
            {
                label: "2 jours",
                range: () => ({
                    startDate: moment().startOf("day").toDate(),
                    endDate: moment().add(1, "day").endOf("day").toDate(),
                }),
            },
            {
                label: 'Semaine en cours',
                range: () => ({
                    startDate: moment().startOf("week").toDate(),
                    endDate: moment().endOf("week").toDate(),
                }),
            },
        ])
    

    const reload = (startDate?:Date, endDate?: Date) => {
        handleSubmit({from: moment(startDate ?? selectedRange[0].startDate), to: moment(endDate ?? selectedRange[0].endDate)})
        setNeedToReload(false)
    }

    useEffect(() => {
        //Eviter de charger au premier coup
        if(!!selectedRange){
            if(reloadAuto){
                handleSubmit({
                    from: moment(selectedRange[0].startDate),
                    to: moment(selectedRange[0].endDate)
                });
                setNeedToReload(false);
            }
        }
    }, [selectedRange, reloadAuto, handleSubmit, setNeedToReload])

    const handleClick = e => {
        //@ts-ignore
        if (node.current && node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false)
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    }, []);

    useImperativeHandle(ref, () => ({
        initializeDate(type: "week" | "year" | "6months") {
            setSelectedRange([{
                startDate: moment().subtract(["week", "year"].includes(type) ? 1 : 6, type === "year" ? 'y' : type === "6months" ? "M" : 'w').toDate(),
                endDate: moment().toDate(),
                key: 'selection'
            }])
        },
        setRange(from: moment, to: moment){
            setSelectedRange([{
                startDate: from.toDate(),
                endDate: to.toDate(),
                key: 'selection'
            }])
        }
    }));

    
    const getIntervalFormat = () => intervalFormat === "literal" ? "DD MMM" : "DD/MM/YYYY"
    
    const getInterval = () => `${moment(selectedRange[0].startDate).format(getIntervalFormat())} - ${moment(selectedRange[0].endDate).format(getIntervalFormat())}`
      
    return(
        <Container isOpen={isOpen} color={color} popupPosition={popupPosition}>
            <div>
                <span className="date_range" onClick={() => setIsOpen(!isOpen)}>{getInterval()}</span>
                {needToReload && <i className="ri-refresh-line pointer" color={"#2f3542"} onClick={reload}></i> }
            </div>
            {isOpen && 
                <div className="popup" ref={node}>
                    {!isMobile ? 
                        <DateRangePicker
                            editableDateInputs={true}
                            onChange={item => {
                                setSelectedRange([item.selection]);
                                if(!!reloadAuto)
                                    setNeedToReload(true)
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={selectedRange}
                            direction="vertical"
                            months={1}
                            scroll={{ enabled: true }}
                            locale={rdrLocales.fr}
                            minDate={moment().subtract(3, 'M').toDate()}
                            maxDate={moment().add(1, 'Y').toDate()}
                            color={color ?? "#007bff"}
                            rangeColors={[color ?? "#007bff"]}
                            staticRanges={staticRanges}
                            inputRanges={[]}
                            />
                            :
                            <DefinedRange
                                onChange={item => {setSelectedRange([item.selection]); setNeedToReload(true)}}
                                ranges={selectedRange}
                                color={color}
                                rangeColors={[color]}
                                staticRanges={staticRanges}
                                inputRanges={[]}/>
                    }
                </div>

            }
        </Container>
    )
})

export default DateRangeSelector