import * as React from "react";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import OptaLogo from '../assets/img/brand/optacare_lagoon.svg';
import {env} from "Constants";

const NewLogin = () => {

  const { keycloak } = useKeycloak();

  const [useExternalProvider, setExternalProvider] = useState(null);

  useEffect(() => {
    if (!!keycloak && useExternalProvider !== null) {
      if (useExternalProvider === true) {
        const url = keycloak.createLoginUrl({
          idpHint: env.AUTH_EXTERNAL_PROVIDER
        });
        document.location.assign(url);
      } else {
        const url = keycloak.createLoginUrl();
        document.location.assign(url);
      }
    }
  }, [useExternalProvider, keycloak]);

  if (keycloak?.authenticated) {
    // Redirect to the original url stored in session by index.js
    // HashRouter uses hash (#...) to store current location
    // Our application has "/app" as prefix
    // We only need the fragments after the prefix
    return <Redirect to={sessionStorage?.getItem("lastLocation")?.replace(/^.*#\/app\//g, "") || "/"} />
  }

  return (
        <div className="navigation">
            <div className="form-login">
                <h1 className="gray-dark">Me connecter</h1>
                    <form>
                        {useExternalProvider === null ? (
                            <div className="login-btns-container">
                                <span className="login-btns animated-button thar-three" onClick={() => setExternalProvider(false)}>
                                  Connexion
                                </span>
                            </div>) 
                            : (<p>En cours de redirection...</p>)}
                    </form>
            </div>
            <div className="navigation-left">
                <div className="optaLabel">
                    <h2 style={{color: "#fff"}}>Bienvenue sur HDJ Planner</h2>
                    <div className="divider"></div>
                    <h3 style={{color: "#fff"}}>OptaLP 2024</h3>
                </div>
            </div>
            <div className="navigation-right">
                <img
                    style={{maxWidth: "50%"}}
                    alt="OPTA_LOGO"
                    className="opta_logo"
                    src={OptaLogo}  />
            </div>
        </div>
    );
};

export default NewLogin;