import Protocol from "components/ClassWrapper/Protocol";
import Motive from "components/ClassWrapper/Motive";
import Rendezvous from "components/ClassWrapper/Rendezvous";
import BasicModel from "components/ClassWrapper/BasicModel";
import Patient from "components/ClassWrapper/Patient";

/**
 *
 * @type {{TO_MODIFY: string, CONFIRMED: string, TO_DELETE: string, TO_CREATE: string}}
 */
export const CYCLE_STATUS = {
    CONFIRMED: "confirmed",
    TO_CREATE: "to-create",
    TO_DELETE: "to-delete",
    TO_MODIFY: "to-modify",
};

/**
 *
 * @type {Object<string,string>}
 */
export const CYCLE_STATUS_FR = {
    [CYCLE_STATUS.CONFIRMED]: "Enregistré",
    [CYCLE_STATUS.TO_CREATE]: "À enregistrer",
    [CYCLE_STATUS.TO_DELETE]: "À supprimer",
    [CYCLE_STATUS.TO_MODIFY]: "À modifier",
};

class Cycle extends BasicModel {
    /**
     *
     * @param o raw data from JSON
     */
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         *
         * @type {Patient}
         */
        this.patient = new Patient(o["patient"]);
        /**
         * Simplified version without predefined prescriptions
         * @type {Protocol}
         */
        this.protocol = new Protocol(o["protocol"]);
        /**
         *
         * @type {?Motive}
         */
        this.motive = !!o["motive"] ? new Motive(o["motive"]) : null;
        /**
         * @type {?string}
         */
        this.comment = o["comment"];
        /**
         * @type {Rendezvous[]}
         */
        this.rendezvousList = o["rendezvousList"].map(value => new Rendezvous(value))
            .sort((r1: Rendezvous, r2: Rendezvous): number => r1.sessionDay < r2.sessionDay ? -1 : 1);
        /**
         *
         * @type {string} registerStatus CONFIRMED, TO_CREATE, TO_REMOVE or TO_MODIFY
         */
        this.registerStatus = o.registerStatus;
        /**
         * @type {import("./TimeClasses").LocalDateInterval}
         */
        this.interval = o.interval;
    }
}

export default Cycle;