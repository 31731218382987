import React from "react";
import RdvStatus from "components/ClassWrapper/RdvStatus";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import ButtonGroup from "reactstrap/es/ButtonGroup";
import Button from "reactstrap/es/Button";
import AvCheckboxGroup from "availity-reactstrap-validation/lib/AvCheckboxGroup";
import AvCheckbox from "availity-reactstrap-validation/lib/AvCheckbox";
import type {Dict} from "Utils";
import Sector from "components/ClassWrapper/Sector";
import memoize from "memoize-one";
import SectorBadge from "views/planning/SectorBadge";

export type SearchCriteria = {
    /**
     * Maybe empty, meaning all possibilities
     */
    searchText: string,
    selectedRdvStatusCodes: Array<string>,
    selectedSectorIds: Array<number>,
    /**
     * "yes" and / or "no"
     */
    selectedTakerStatus: Array<string>
};

class FilterBox extends React.PureComponent {
    props: {
        onSetSearchCriteria: (newSearchCriteria: SearchCriteria) => void,
        placeholder: string,
        dict: { sector: Dict<Sector> }
    };

    onSubmit = (event, errors, values: SearchCriteria) => !!values && this.props.onSetSearchCriteria(values);

    getInitialSearchOptions = (sectorDict: Dict<Sector>): SearchCriteria | null =>
        sectorDict === null ? null :
            ({
                searchText: "",
                selectedRdvStatusCodes: Object.keys(RdvStatus), // Codes of all rdv status
                selectedSectorIds: Object.values(sectorDict).map(sector => sector.id),
                selectedTakerStatus: ["yes", "no"],
            });

    memo_getInitialSearchOptions = memoize(this.getInitialSearchOptions);

    onShowAll = () => this.props.onSetSearchCriteria(null);

    render() {
        const defaultSearchOptions = this.memo_getInitialSearchOptions(this.props.dict.sector);
        return (
            defaultSearchOptions !== null &&
            <AvForm onSubmit={this.onSubmit}
                    model={defaultSearchOptions}>
                <Row style={{marginBottom: '20px'}}>
                    <Col xl={8} xs={12}>
                        <AvInput name="searchText"
                                 placeholder={this.props.placeholder}/>
                        <label className="labelFilterJobs" style={{marginBottom: '0px !important', fontSize: '12px'}}>Nom(s) / prénom / date de naissance / IPP</label>

                    </Col>
                    <Col xl={4} xs={12}>
                        <ButtonGroup className="d-flex">
                            <Button color="primary" type="submit" className="m-0" block>
                                <i className="fas fa-search"/> Rechercher
                            </Button>
                            <Button color="success" onClick={this.onShowAll} className="m-0" block>
                                <i className="fas fa-list-alt"/> Afficher tous
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    {/* <Col md={4}>
                        <AvCheckboxGroup name="selectedRdvStatusCodes" label="État du rdv">
                            {Object.keys(RdvStatus).map((value: string) =>
                                <Col xl={8}
                                     key={value}>
                                <AvCheckbox
                                    customInput
                                    label={RdvStatus[value].name}
                                    value={value}/>
                                </Col>
                            )}
                        </AvCheckboxGroup>
                    </Col> */}
                    <Col md={6}>
                        <AvCheckboxGroup inline name="selectedSectorIds" label="Spécialité">
                            {Object.values(this.props.dict.sector).filter((sector: Sector) => sector.enabled).map((sector: Sector) =>
                                <AvCheckbox customInput  label={<SectorBadge sector={sector}/>} value={sector.id} key={sector.id}/>
                            )}
                        </AvCheckboxGroup>
                    </Col>
                    <Col md={6}>
                        <AvCheckboxGroup inline name="selectedTakerStatus" label="Infirmière affectée">
                            <AvCheckbox customInput label="Oui" value={"yes"} key={1}/>
                            <AvCheckbox customInput label="Non" value={"no"} key={2}/>
                        </AvCheckboxGroup>
                    </Col>
                </Row>
            </AvForm>
        );
    }
}

export default FilterBox;