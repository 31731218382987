import React from "react";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import StaffList from "components/InteractiveList/StaffList";
import StaffAvailabilityManager from "views/hrmanage/StaffAvailabilityManager";
import {
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import SessionAvailabilityManager from "views/hrmanage/SessionAvailabilityManager";
import UncontrolledAlert from "reactstrap/es/UncontrolledAlert";

class HRManage extends React.PureComponent {

    state = {
        activeTab: "staffs"
    }

    render() {
        return <>
            <Nav tabs className="nav-tabs">
                <NavItem>
                    <NavLink active={this.state.activeTab === "staffs"} onClick={() => this.setState({activeTab: "staffs"})}>
                        Liste du personnel
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={this.state.activeTab === "StaffAvailability"} onClick={() => this.setState({activeTab: "StaffAvailability"})}>
                        Calendrier de travail nominatif
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={this.state.activeTab === "SessionAvailability"} onClick={() => this.setState({activeTab: "SessionAvailability"})}>
                        Calendrier de travail non-nominatif
                    </NavLink>
                </NavItem>
            </Nav>
            <Row className="m-3">
                <Col style={{height: 'calc(100vh - 203px)'}}>
                    {this.state.activeTab === "staffs" ? <StaffList/>
                        : this.state.activeTab === "StaffAvailability" ? <StaffAvailabilityManager />
                        : this.state.activeTab === "SessionAvailability" ? <SessionAvailabilityManager />
                        : <UncontrolledAlert color="danger">Inconnu onglet</UncontrolledAlert>
                    }
                </Col>
            </Row>
        </>;
    }
}

export default HRManage;