import React from "react";
import * as PropTypes from "prop-types";
import Patient from "components/ClassWrapper/Patient";
import {getFullNameWithPrefix, getLocalDateStrFromISO} from "Utils";
import EntityPicker from "components/picker/EntityPicker";
import styled from "styled-components";
import { REGEX_DATE_INPUT_SEARCH } from "Constants";
import ResourcesAPI from "api/ResourcesAPI";

const SuggestionItem = styled('div')`
    span{
        display: block;
        &:nth-of-type(1){
            font-weight: 700;
        }
        &:nth-of-type(2){
            font-style: italic;
        }
    }
`

export default class PatientPicker extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string,
        onPatientChosen: PropTypes.func.isRequired,
    };

    parseDate = (date: string): string => {     
        let _day = date.slice(0,2), _month = date.slice(3,5), _year = date.slice(6,10),
        _parsedDate = `${_year}-${_month}-${_day}`
        return _parsedDate
    }
    

    getFilteredPatients = (inputValue: string): Promise<Patient[]> => {
        inputValue = inputValue.trim();
        if (inputValue.length === 0)
            return Promise.resolve([]);
        else{
            let _searchValue = inputValue.split(" ")
                                            .map(t => {
                                                if(REGEX_DATE_INPUT_SEARCH.test(t))
                                                    return `birthday==${this.parseDate(t)}`
                                                return `(firstName=eqnc="*${t}*",lastName=eqnc="*${t}*",partnerName=eqnc="*${t}*",externalId.value=eqnc="*${t}*")`
                                            })
                                            .join(';')

    
            return ResourcesAPI.getResourceByRsql("patient", {search: _searchValue});
        }
    };

    getPatientName = (suggestion: Patient): string => getFullNameWithPrefix(suggestion);

    renderPatient = (suggestion: Patient): string => (
        <SuggestionItem>
            <span>{getFullNameWithPrefix(suggestion)}</span>
            {!!suggestion.birthday && <span>({getLocalDateStrFromISO(suggestion.birthday)})</span>}
            {!!suggestion?.externalId?.value && <span>IPP: {suggestion?.externalId?.value}</span>}
        </SuggestionItem>
    );

    render() {
        return <EntityPicker
            async
            className={this.props.className}
            placeholder={"Recherche de patient"}
            onChooseValue={this.props.onPatientChosen}
            clearOnSelect={true}
            getFilteredSuggestions={this.getFilteredPatients}
            getSuggestionValue={this.getPatientName}
            renderSuggestion={this.renderPatient}/>;
    }
}