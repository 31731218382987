import React from "react";
import { Alert } from "reactstrap";
import {AccountController} from "components/Controller/AuthProvider";
import {Redirect} from "react-router-dom";
import {_require} from "Utils";
import {HOME_PAGE} from "Constants";
import OptaLogo from '../assets/img/brand/optacare_lagoon.svg';

class Login extends React.Component {

    state = {
        /**
         * @type {string}
         */
        username: null,
        /**
         * @type {string}
         */
        password: null,
        /**
         * @type {boolean}
         */
        redirectToReferrer: false,
        errorMessage: null,
    };

    authenticate = (e) => {
        e.preventDefault();
        try {
            _require.nonEmptyString(this.state.username);
            _require.nonEmptyString(this.state.password);
        } catch (e) {
            this.setState({errorMessage: "Veuillez remplir l'identifiant et le mot de passe correctement"});
            return;
        }
        AccountController.login({username: this.state.username, password: this.state.password})
                .then(() => this.setState({redirectToReferrer: true}))
                .catch(err => {
                    console.error(err);
                    this.setState({errorMessage: "Erreur d'authentification. Veuillez vérifier votre identifiant et mot de passe, ou retenter après quelques instants"});
                });
    };

    render() {
        const {redirectToReferrer} = this.state;
        const {from} = this.props.location.state || {};
        if (redirectToReferrer) return <Redirect to={from ? from : HOME_PAGE}/>;
        else
            return (
                <div className="navigation">
                    <div className="form-login">
                        <h1 className="gray-dark">Connexion</h1>
                        <form onSubmit={this.authenticate}>
                            <div className="inputContainer">
                                <i className="icon fa fa-envelope"/>
                                <input 
                                    type="text" 
                                    className="ipt_login ipt_mail"
                                    placeholder="Identifiant"
                                    onChange={event => this.setState({username: event.target.value})}/>
                            </div>
                            <div className="inputContainer">
                                <i className="icon fa fa-lock" />
                                <input 
                                    type="password" 
                                    className="ipt_login ipt_pass" 
                                    placeholder="Mot de passe"
                                    onChange={event => this.setState({password: event.target.value})}/>
                            </div>
                            <button type="submit" className="btn btn-lg">Me connecter</button>
                            <Alert isOpen={!!this.state.errorMessage} color={"danger"}>
                                {this.state.errorMessage}
                            </Alert>
                        </form>
                    </div>
                    <div className="navigation-left">
                        <div className="optaLabel">
                            <h2 style={{color: "#fff"}}>Bienvenue sur HDJ Planning</h2>
                            <div className="divider"></div>
                            <h3 style={{color: "#fff"}}>OptaLP 2020</h3>
                        </div>
                    </div>
                    <div className="navigation-right">
                        <img
                            alt="OPTA_LOGO"
                            className="opta_logo"
                            src={OptaLogo}
                        />
                    </div>
                </div>
            );
    }
}

export default Login;
