import Jobs from "views/jobs/Jobs";
import Protocols from "views/Protocols";
import Configurations from "views/Configurations/Configurations";
import Patients from "views/Patients";
import Planning from "views/planning/Planning";
import HRManage from "views/hrmanage/HRManage";
import Login from "views/Login";
import Accounts from "views/Accounts";
import MedPrepTasks from "views/MedPrepTasks";
import {LAYOUT, ROLE} from "Constants";
import {_require} from "Utils";
import ConsultationTasks from "views/ConsultationTasks";
import JobLister from "views/JobLister";
import LoggerView from "views/LoggerView";

/**
 * A page
 */
export class OptaRoute {
    constructor(o: Object) {
        /**
         * url relative name
         * @type {string}
         */
        this.path = _require.nonEmptyString(o["path"]);
        /**
         * Title
         * @type {string}
         */
        this.name = _require.nonEmptyString(o["name"]);
        /**
         * Fontawesome's icon name
         * @type {string}
         */
        this.icon = _require.nonEmptyString(o["icon"]);
        /**
         * what to render
         * @type {React.ReactElement}
         */
        this.component = o["component"];
        /**
         * From LAYOUT constants
         * @type {string}
         */
        this.layout = _require.nonEmptyString(o["layout"]);
        /**
         *
         * @type {string[]}
         */
        this.rolesAllowed = !o["rolesAllowed"] ? [] : o["rolesAllowed"];
    }

    /**
     *
     * @returns {string}
     */
    getFullPath = (): string => `/app/${this.path}`;
    // getFullPath = (): string => `${this.layout}/${this.path}`;
}

/**
 *
 * all available routes in app
 */
export const routeDict: Object<String, OptaRoute> = {
    // STATS: new OptaRoute({
    //     path: "stats",
    //     name: "Statistiques",
    //     icon: "fas fa-chart-line",
    //     component: Statistics,
    //     layout: LAYOUT.MAIN,
    //     rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
    // }),
    JOBS: new OptaRoute({
        path: "jobs",
        name: "Charge journalière",
        icon: "fas fa-medkit",
        component: Jobs,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.NURSE, ROLE.DOCTOR, ROLE.CARE_GIVER, ROLE.SHARED_ACCESS, ROLE.SECRETARIAT],
    }),
    JOB_LISTER: new OptaRoute({
        path: "jobs-listing",
        name: "Convocations",
        icon: "fas fa-table",
        component: JobLister,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.NURSE, ROLE.DOCTOR, ROLE.CARE_GIVER, ROLE.SHARED_ACCESS, ROLE.SECRETARIAT],
    }),
    MED_PREP_TASKS: new OptaRoute({
        path: "medicine-preparations",
        name: "Préparations de médicaments",
        icon: "fas fa-vials",
        component: MedPrepTasks,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.PHARMACIST],
    }),
    CONSULTATION_TASKS: new OptaRoute({
        path: "consultations",
        name: "Consultations",
        icon: "fas fa-stethoscope",
        component: ConsultationTasks,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.DOCTOR, ROLE.NURSE],
    }),
    PLANNING: new OptaRoute({
        path: "planning",
        name: "Planifications",
        icon: "far fa-calendar-alt",
        component: Planning,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.NURSE, ROLE.SECRETARIAT],
    }),
    PATIENTS: new OptaRoute({
        path: "patients",
        name: "Patients",
        icon: "fa fa-file-medical-alt",
        component: Patients,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.NURSE, ROLE.DOCTOR, ROLE.SECRETARIAT],
    }),
    PROTOCOLS: new OptaRoute({
        path: "protocoles",
        name: "Protocoles",
        icon: "fa fa-fill",
        component: Protocols,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.PHARMACIST],
    }),
    HUMAN_RESOURCES: new OptaRoute({
        path: "human-resources",
        name: "Personnels",
        icon: "fa fa-users",
        component: HRManage,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
    }),
    ACCOUNTS: new OptaRoute({
        path: "accounts",
        name: "Comptes",
        icon: "fas fa-address-book",
        component: Accounts,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
    }),
    ENVIRONMENT: new OptaRoute({
        path: "environnement",
        name: "Environnement",
        icon: "fa fa-cogs",
        component: Configurations,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
    }),
    LOGGER: new OptaRoute({
        path: "logger",
        name: "Journalisation",
        icon: "fa fa-history",
        component: LoggerView,
        layout: LAYOUT.MAIN,
        rolesAllowed: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
    }),
    LOGIN: new OptaRoute({
        path: "login",
        name: "Login",
        icon: "fa fa-sign-in-alt",
        component: Login,
        layout: LAYOUT.AUTH,
    }),
};

const routes: Array<OptaRoute> = Object.values(routeDict);
export default routes;

/**
 * Default homepage to render for each logged-in role
 */
export const DEFAULT_HOMEPAGE: { [string]: OptaRoute } = {
    [ROLE.CARE_GIVER]: routeDict.JOBS,
    [ROLE.DOCTOR]: routeDict.CONSULTATION_TASKS,
    [ROLE.PHARMACIST]: routeDict.MED_PREP_TASKS,
    [ROLE.NURSE]: routeDict.PLANNING,
    [ROLE.ADMIN]: routeDict.ENVIRONMENT,
    [ROLE.SUPER_ADMIN]: routeDict.ENVIRONMENT,
    [ROLE.SHARED_ACCESS]: routeDict.JOBS,
    [ROLE.SECRETARIAT]: routeDict.JOBS,
};