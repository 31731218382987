import BasicModel from "components/ClassWrapper/BasicModel";
import Staff from "components/ClassWrapper/Staff";
import Material from "components/ClassWrapper/Material";
import type {LocalTimeInterval, DateString, DateTimeString} from "components/ClassWrapper/TimeClasses";
import RdvStatus, {Status} from "components/ClassWrapper/RdvStatus";
import {hasEnded, hasStarted, hasStartedNotEnded, reallyExists} from "components/ClassWrapper/TimeClasses";

class Rendezvous extends BasicModel {
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         * @type {?string}
         */
        this.comment = (o["comment"]: ?string);
        // /**
        //  * @type {string} a value recognizable by {@link RdvStatus}
        //  */
        // this.status = (o["status"]: string);
        /**
         * @type {?Staff} Assigned doctor. Must be present before executing the consultation
         */
        this.doctor = ((!!o["doctor"] ? new Staff(o["doctor"]) : null): ?Staff);
        /**
         * @type {?Staff} Assigned nurse. The principal one taking care of the patient
         */
        this.nurse = ((!!o["nurse"] ? new Staff(o["nurse"]) : null): ?Staff);
        /**
         * @type {Material} Required for the appointment
         */
        this.place = (new Material(o["place"]): Material);
        /**
         * @type {boolean}
         */
        this.consultationInPlace = (!!o["consultationInPlace"]: boolean);
        /**
         * @type {boolean}
         */
        this.inNeedConsultation = (!!o["inNeedConsultation"]: boolean);
        /**
         * @type {boolean}
         */
        this.canPrePrepMed = (!!o["canPrePrepMed"]: boolean);
        /**
         * In minutes >= 0
         * @type {number}
         */
        this.treatmentDuration = (o["treatmentDuration"]: number);
        /**
         * In minutes >= 0
         * @type {number}
         */
        this.medPrepDuration = (o["medPrepDuration"]: number);
        /**
         * Continue or cancel due to physical condition. <tt>undefined</tt> if no decision taken
         * @type {?boolean}
         */
        this.go = (o["go"]: ?boolean);
        /**
         * @type {DateString}
         */
        this.sessionDay = (o["sessionDay"]: DateString);
        /**
         * @type {LocalTimeInterval}
         */
        this.session = (o["session"]: LocalTimeInterval);
        /**
         * @type {?DateString}
         */
        this.consultationDay = (o["consultationDay"]: ?DateString);
        /**
         * @type {?LocalTimeInterval}
         */
        this.consultation = (o["consultation"]: ?LocalTimeInterval);
        /**
         * @type {?LocalTimeInterval}
         */
        this.installation = (o["installation"]: ?LocalTimeInterval);
        /**
         * @type {?DateString}
         */
        this.medPrepDay = (o["medPrepDay"]: ?DateString);
        /**
         * @type {?LocalTimeInterval}
         */
        this.medPrep = (o["medPrep"]: ?LocalTimeInterval);
        /**
         * @type {?LocalTimeInterval}
         */
        this.treatment = (o["treatment"]: ?LocalTimeInterval);
        /**
         * @type {?DateTimeString}
         */
        this.cancel = (o["cancel"]: ?DateTimeString);
        /**
         * @type {?LocalTimeInterval}
         */
        this.realSession = (o["realSession"]: ?LocalTimeInterval);
        /**
         * @type {?DateString}
         */
        this.realSessionDay = (o["realSessionDay"]: ?DateString);
        /**
         * @type {?DateString}
         */
        this.realConsultationDay = (o["realConsultationDay"]: ?DateString);
        /**
         * @type {?LocalTimeInterval}
         */
        this.realConsultation = (o["realConsultation"]: ?LocalTimeInterval);
        /**
         * @type {?DateTimeString}
         */
        this.goMoment = (o["goMoment"]: ?DateTimeString);
        /**
         * @type {?LocalTimeInterval}
         */
        this.realInstallation = (o["realInstallation"]: ?LocalTimeInterval);
        /**
         * @type {?DateString}
         */
        this.realMedPrepDay = (o["realMedPrepDay"]: ?DateString);
        /**
         * @type {?LocalTimeInterval}
         */
        this.realMedPrep = (o["realMedPrep"]: ?LocalTimeInterval);
        /**
         * @type {?DateTimeString}
         */
        this.realMedReception = (o["realMedReception"]: ?DateTimeString);
        /**
         * @type {?LocalTimeInterval}
         */
        this.realTreatment = (o["realTreatment"]: ?LocalTimeInterval);
        /**
         * @type {?DateTimeString}
         */
        this.realCancel = (o["realCancel"]: ?DateTimeString);
        /**
         * Values from {@link CYCLE_STATUS}
         * @type {?string}
         */
        this.registerStatus = o.registerStatus;
    }

    static hasPlannedConsultation(rdv: Rendezvous): boolean {
        return !!rdv.inNeedConsultation && rdv.consultationInPlace;
    }
}

export default Rendezvous;

/**
 * Get all the status of the appointment
 * @param {?Rendezvous} rdv
 * @return {Status[]} ids recognizable by {@link RdvStatus}
 */
export const getStatus = (rdv: ?Rendezvous): Status[] => {
    if (!rdv) return [RdvStatus.UNKNOWN];
    return StatusCheckers.filter(ch => ch.checker(rdv))
        .map(ch => ch.status)
        ;
}

export const getLastStatus = (rdv: ?Rendezvous): Status => {
    let status = getStatus(rdv);
    return status[status.length - 1];
}

type StatusChecker = {
    status: Status,
    checker: Rendezvous => boolean
}

export const isInSession = (rdv: Rendezvous): boolean => !!rdv.realSessionDay && hasStartedNotEnded(rdv.realSession);

const StatusCheckers: StatusChecker[] = [
    {
        status: RdvStatus.NOT_ARRIVED,
        checker: (rdv: Rendezvous): boolean => !rdv.realSessionDay || !hasStarted(rdv.realSession)
    },
    {
        status: RdvStatus.CANCELED,
        checker: (rdv: Rendezvous): boolean => !!rdv.realCancel
    },
    {
        status: RdvStatus.ON_WAIT,
        checker: (rdv: Rendezvous): boolean => isInSession(rdv)
            && !hasStarted(rdv.realInstallation) // Installation not started
            && (!rdv.consultationInPlace || (!rdv.realConsultationDay && !hasStarted(rdv.realConsultation))) // Consultation not started
    },
    {
        status: RdvStatus.ON_CONSULT,
        checker: (rdv: Rendezvous): boolean => !!rdv.realConsultationDay && hasStartedNotEnded(rdv.realConsultation)
    },
    {
        status: RdvStatus.DONE_CONSULT,
        checker: (rdv: Rendezvous): boolean => !!rdv.realConsultationDay && hasEnded(rdv.realConsultation)
            && !hasEnded(rdv.realSession) && (!reallyExists(rdv.treatment) || !hasStarted(rdv.realTreatment))
    },
    {
        status: RdvStatus.ON_INSTALL,
        checker: (rdv: Rendezvous): boolean => isInSession(rdv)
            && hasStartedNotEnded(rdv.realInstallation)
    },
    {
        status: RdvStatus.DONE_INSTALL,
        checker: (rdv: Rendezvous): boolean => isInSession(rdv) && hasEnded(rdv.realInstallation)
    },
    {
        status: RdvStatus.ON_MED_PREP,
        checker: (rdv: Rendezvous): boolean => !!rdv.realMedPrepDay && hasStartedNotEnded(rdv.realMedPrep)
    },
    {
        status: RdvStatus.DONE_MED_PREP,
        checker: (rdv: Rendezvous): boolean => !!rdv.realMedPrepDay && hasEnded(rdv.realMedPrep) && !rdv.realMedReception
    },
    {
        status: RdvStatus.DONE_MED_RECEPTION,
        checker: (rdv: Rendezvous): boolean => !!rdv.realMedPrepDay && hasEnded(rdv.realMedPrep) && !!rdv.realMedReception
            && (!reallyExists(rdv.treatment) || !hasStarted(rdv.realTreatment)) && !hasEnded(rdv.realSession)
    },
    {
        status: RdvStatus.ON_TREATMENT,
        checker: (rdv: Rendezvous): boolean => isInSession(rdv) && hasStartedNotEnded(rdv.realTreatment)
    },
    {
        status: RdvStatus.DONE_TREATMENT,
        checker: (rdv: Rendezvous): boolean => isInSession(rdv) && hasEnded(rdv.realTreatment)
    },
    {
        status: RdvStatus.FINISHED,
        checker: (rdv: Rendezvous): boolean => !!rdv.realSessionDay && hasEnded(rdv.realSession)
    },
];
