import React from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import AccountsList from "components/InteractiveList/AccountsList";

/**
 * Page of organization management
 */
export default class Accounts extends React.PureComponent {
    render() {
        return (
                <div className="m-3">
                    <Row className="my-4">
                        <Col xl={12} className="mb-4">
                            <AccountsList/>
                        </Col>
                    </Row>
                </div>
        );
    }
}