import { FormGroup, Input, MenuItem, Select } from "@material-ui/core"
import ProtocolsAPI from "api/ProtocolsAPI"
import React, { useState, useEffect, useCallback } from "react"
import { Col, Label, UncontrolledTooltip } from "reactstrap"
import { Button, ModalFooter } from "reactstrap";
import type Protocol, { SingleRdvPrescription } from "./ClassWrapper/Protocol"
import type { ExternalId } from "./ClassWrapper/ExternalId"
import { BED } from "./ClassWrapper/SeatType"
import OptaConfig from "./ClassWrapper/OptaConfig"
import { Dict } from "Utils"

function hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
}

type CustomSingleRdvPrescription = SingleRdvPrescription & {
    bedRequirement?: boolean
}

type CustomProtocol = {
    id: Number,
    enabled: Boolean,
    name: String,
    externalId?: ExternalId,
    sectorId: Number,
    prescriptions: CustomSingleRdvPrescription[]
}

const adaptToProcotol = (customProtocol: CustomProtocol): Protocol => ({
    ...customProtocol,
    sector: `sectors/${customProtocol.sectorId}`,
    prescriptions: customProtocol.prescriptions.map(pre => ({
        ...pre,
        seatTypeRequirement: pre.bedRequirement ? BED : null,
        consultation: pre.consultationDuration > 0,
    }))
})

type ProtocolFormProps = {
    protocol: Protocol | undefined,
    handleClose: () => void,
    config: OptaConfig,
    sectorDict: Dict<String>,
}

const handleNullableIntField = (fieldName: String, setProtocol: (p: Protocol) => void, e, rdvIdx: Number) => {
    let valStr: String = e.currentTarget.value.trim();
    let val = valStr.length === 0 ? null : parseInt(valStr);
    setProtocol(protocol => ({
        ...protocol,
        prescriptions: protocol.prescriptions.map((p, idx) => idx !== rdvIdx ? p : ({
            ...p,
            [fieldName]: val
        }))
    }));
}

const ProtocolForm = (props: ProtocolFormProps) => {

    /**
     * @type {[CustomProtocol, (p: CustomProtocol) => void]}
     */
    const [protocol, setProtocol] = useState({
        name: "",
        prescriptions: [],
        sectorId: null,
        externalId: null
    })

    const [error, setError] = useState("")

    useEffect(() => {
        if (!!props.protocol) {
            setProtocol({
                name: props.protocol.name,
                sectorId: props.protocol.sectorId,
                prescriptions: props.protocol.prescriptions.map(pre => ({
                    ...pre,
                    bedRequirement: pre.seatTypeRequirement === BED,
                })),
                externalId: props.protocol.externalId
            })
        } else {
            setProtocol({
                name: "",
                prescriptions: [],
                sectorId: null,
                externalId: null
            })
        }
    }, [props])

    const addPrescription = useCallback(() => setProtocol((protocol: CustomProtocol) => ({
        ...protocol,
        prescriptions: [
            ...protocol.prescriptions,
            {
                dayIndex: protocol.prescriptions.length === 0 ? 1 : Math.max(...protocol.prescriptions.map(p => p.dayIndex)) + 1,
                daysDelayLimit: null,
                treatmentDuration: 0,
                medPrepDuration: 0,
                medPrepAfterInstallation: true,
                consultationDuration: null,
                medicinePreMixAuthorized: false,
                bedRequirement: false
            }
        ]
    })), [setProtocol]);

    const deletePrescription = (index) => {
        let _arr = [...protocol.prescriptions];
        _arr.splice(index, 1);
        setProtocol({
            ...protocol,
            prescriptions: _arr
        })
    }

    const handleInputChange = (e, name, index) => {
        let _arr = [...protocol.prescriptions];
        _arr[index][name] = e.currentTarget.value;
        setProtocol({
            ...protocol,
            prescriptions: _arr
        })
    }

    const handleCheckboxChange = (e, name, index) => {
        let _arr = [...protocol.prescriptions],
            value = _arr[index][name]
        _arr[index][name] = !value;
        setProtocol({
            ...protocol,
            prescriptions: _arr
        })
    }

    const handleSubmit = () => {
        setError("")
        if (protocol.prescriptions.length === 0) {
            setError("Veuillez renseigner au moins une prescription.")
            return
        }
        if (hasDuplicates(protocol.prescriptions.map(p => parseInt(p.dayIndex)))) { //pas d'index identique
            setError("Impossible d'avoir 2 jours avec le même index.")
            return
        }
        if (!props.protocol) { //Create
            ProtocolsAPI.createProtocol(adaptToProcotol(protocol))
                .then(() => props.handleClose())
                .catch(e => {
                    console.error(e);
                    setError("Une erreur est survenue lors de la création. Veuillez resssayer ultérieurement");
                })
        } else {
            ProtocolsAPI.patchProtocol(props.protocol.id, adaptToProcotol(protocol))
                .then(() => props.handleClose())
                .catch(e => {
                    console.error(e);
                    setError("Une erreur est survenue lors de la modification. Veuillez resssayer ultérieurement");
                })
            //Edit
        }
    }

    return (
        <>
            {!!error && <p style={{ background: "#f5365c", padding: 5, color: "#fff", textAlign: 'center', fontWeight: 400 }}>{error}</p>}
            <Col md={12}>
                <FormGroup style={{ marginBottom: 20 }}>
                    <Label>Nom du protocole</Label>
                    <Input
                        value={protocol.name}
                        onChange={e => setProtocol({ ...protocol, name: e.currentTarget.value })} />
                </FormGroup>
                <FormGroup style={{ marginBottom: 20 }}>
                    <Label>Spécialité</Label>
                    <Select
                        value={protocol.sectorId}
                        onChange={e => setProtocol({ ...protocol, sectorId: e.target.value })}>
                        {Object.keys(props.sectorDict).map(sectorId => (
                            <MenuItem key={sectorId} value={sectorId}>{props.sectorDict[sectorId]}</MenuItem>
                        ))}
                    </Select>
                </FormGroup>
                <FormGroup>
                    <Label>Transcodage</Label>
                    <Input
                        value={protocol.externalId ? protocol.externalId.value : ""}
                        onChange={e => setProtocol({ ...protocol, externalId: { value: e.currentTarget.value } })} />
                </FormGroup>

                <Label style={{ marginTop: 20 }}>Prescriptions ({protocol.prescriptions.length})</Label>
                <table className="prescription-table">
                    <thead>
                        <tr>
                            <th>Jour</th>
                            <th>
                                Décalage
                                <i id="protocol-form-header-daysDelayLimit" class="fas fa-info-circle d-block"></i>
                                <UncontrolledTooltip target="protocol-form-header-daysDelayLimit">
                                    Décalage toléré pour un rdv (en nombre de jours).
                                    Laisser vide pour prendre la valeur globale ({props.config.daysDelayLimit}J)
                                </UncontrolledTooltip>
                            </th>
                            <th>
                                Consultation
                                <i id="protocol-form-header-consultationDuration" class="fas fa-info-circle d-block"></i>
                                <UncontrolledTooltip target="protocol-form-header-consultationDuration">
                                    Durée de consultation (en minutes).
                                    Laisser vide pour prendre la valeur globale ({props.config.consultationLength}m)
                                </UncontrolledTooltip>
                            </th>
                            <th>
                                Installation
                                <i id="protocol-form-header-installationDuration" class="fas fa-info-circle  d-block"></i>
                                <UncontrolledTooltip target="protocol-form-header-installationDuration">
                                    Durée d'installation (en minutes).
                                    Laisser vide pour prendre la valeur globale ({props.config.installationLength}m)
                                </UncontrolledTooltip>
                            </th>
                            <th>Prép. après installation</th>
                            <th>
                                Prép. anticipée
                                <i id="protocol-form-header-medicinePreMixAuthorized" class="fas fa-info-circle  d-block"></i>
                                <UncontrolledTooltip target="protocol-form-header-medicinePreMixAuthorized">
                                    Autoriser de préparation anticipée de médicament
                                </UncontrolledTooltip>
                            </th>
                            <th>Prép. (min)</th>
                            <th>Traitement (min)</th>
                            <th>Lit requis</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {protocol.prescriptions.map((pre, i) => (
                            <tr key={i} className="prescription-item">
                                <td>
                                    <input
                                        min={1}
                                        defaultValue={pre.dayIndex}
                                        onChange={e => handleInputChange(e, "dayIndex", i)}
                                        type="number" />
                                </td>
                                <td>
                                    <input
                                        maxLength={1}
                                        pattern="\s*|\d"
                                        defaultValue={pre.daysDelayLimit}
                                        size={3}
                                        onChange={e => handleNullableIntField("daysDelayLimit", setProtocol, e, i)}
                                        type="text" />
                                </td>
                                <td>
                                    <input
                                        maxLength={3}
                                        pattern="\s*|\d"
                                        defaultValue={pre.consultationDuration}
                                        size={3}
                                        onChange={e => handleNullableIntField("consultationDuration", setProtocol, e, i)}
                                        type="text" />
                                </td>
                                <td>
                                    <input
                                        maxLength={3}
                                        pattern="\s*|\d"
                                        defaultValue={pre.installationDuration}
                                        size={3}
                                        onChange={e => handleNullableIntField("installationDuration", setProtocol, e, i)}
                                        type="text" />
                                </td>
                                <td>
                                    <input
                                        defaultChecked={!!pre.medPrepAfterInstallation}
                                        onChange={e => handleCheckboxChange(e, "medPrepAfterInstallation", i)}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <input
                                        defaultChecked={!!pre.medicinePreMixAuthorized}
                                        onChange={e => handleCheckboxChange(e, "medicinePreMixAuthorized", i)}
                                        type="checkbox" />
                                </td>
                                <td>
                                    <input
                                        min={0}
                                        onChange={e => handleInputChange(e, "medPrepDuration", i)}
                                        defaultValue={pre.medPrepDuration}
                                        type="number" />
                                </td>
                                <td>
                                    <input
                                        min={0}
                                        onChange={e => handleInputChange(e, "treatmentDuration", i)}
                                        defaultValue={pre.treatmentDuration}
                                        type="number" />
                                </td>
                                <td>
                                    <input
                                        defaultChecked={!!pre.bedRequirement}
                                        onChange={e => handleCheckboxChange(e, "bedRequirement", i)}
                                        type="checkbox" />
                                </td>
                                <td><i className="fa fa-trash" style={{ cursor: "pointer" }} onClick={() => deletePrescription(i)} /></td>
                            </tr>
                        ))}
                        <tr className="prescription-item item-add">
                            <td colSpan={9} style={{ cursor: "pointer" }} onClick={addPrescription}>
                                <i className="fa fa-plus" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ModalFooter>
                    <Button color="secondary" onClick={props.handleClose}>Annuler</Button>
                    <Button color="primary" onClick={handleSubmit}>{props.protocol ? "Modifier" : "Ajouter"}</Button>{' '}
                </ModalFooter>
            </Col>
        </>
    )
}

export default ProtocolForm