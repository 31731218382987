/**
 * @property {number} id taken from material
 * @property {string} title
 * @property {boolean} stackItem
 * @property {string} backgroundColor
 * @property {Material} rawData
 */
export default class Place {
    constructor(o) {
        ["id", "title", "stackItem", "backgroundColor", "rawData"].forEach(att => this[att] = o[att]);
    }
}