import React, { useEffect, useMemo, useState } from "react";
import Sector from "components/ClassWrapper/Sector";
import { transparentize } from "polished";

export const SectorFilter = ({ sectors, onChange, key }: {
    sectors: Sector[],
    onChange: (selectedSectorIds: Number[]) => void
}) => {
    const sectorIds = useMemo(() => sectors.map(sector => sector.id), [sectors]);
    /**
     * @type {[Number[]]}
     */
    const [selectedSectorIds, setSelectedSectorIds] = useState(sectorIds);

    const toggleSelectedSectorIds = (sectorId: number) => setSelectedSectorIds(selectedSectorIds.includes(sectorId) ? selectedSectorIds.filter(id => id !== sectorId) : selectedSectorIds.concat(sectorId));

    useEffect(() => {
        onChange(selectedSectorIds);
    }, [selectedSectorIds, onChange]);

    return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            <span className="font-weight-bold mr-2">Spécialités :</span>
            {
                sectors.filter(sector => sector.enabled)
                    .map(sector =>
                        <span className="label"
                            style={{
                                background: !selectedSectorIds.includes(sector.id) ? "rgba(47, 53, 66,.05)" : transparentize(.8, sector.rgbColor),
                                color: !selectedSectorIds.includes(sector.id) ? "rgba(47, 53, 66, 0.7)" : sector.rgbColor,
                            }}
                            onClick={() => toggleSelectedSectorIds(sector.id)}
                            key={sector.id}
                        >
                            {sector.name}
                        </span>
                    )
            }
        </div>
    )
}