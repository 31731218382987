import React from "react";
import {Link, NavLink as NavLinkRRD} from "react-router-dom";
import * as PropTypes from "prop-types";
import Col from "reactstrap/es/Col";
import Collapse from "reactstrap/es/Collapse";
import Container from "reactstrap/es/Container";
import Form from "reactstrap/es/Form";
import Input from "reactstrap/es/Input";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import Nav from "reactstrap/es/Nav";
import Navbar from "reactstrap/es/Navbar";
import NavbarBrand from "reactstrap/es/NavbarBrand";
import NavItem from "reactstrap/es/NavItem";
import NavLink from "reactstrap/es/NavLink";
import Row from "reactstrap/es/Row";
import OptaNavbarBrand from "components/Sidebar/OptaNavbarBrand";
import {OptaRoute} from "routes";
import {LAYOUT} from "Constants";
import {AccountController} from "components/Controller/AuthProvider";
import UncontrolledDropdownUserMenu from "components/Navbars/UncontrolledDropdownUserMenu";

export default class Sidebar extends React.PureComponent {
    state = {
        collapseOpen: false
    };

    constructor(props) {
        super(props);
        this.activeRoute.bind(this);
    }

    account = AccountController.getCurrentAccount();

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    // toggles collapse between opened and closed (true/false)
    toggleCollapse = () => {
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };
    // closes the collapse
    closeCollapse = () => {
        this.setState({
            collapseOpen: false
        });
    };
    // creates the links that appear in the left menu / Sidebar
    createLinks = (routes: Array<OptaRoute>) => {
        return routes
            .filter(route => route.layout === LAYOUT.MAIN
                    && route.rolesAllowed.includes(this.account.role))
            .map((route, key) =>
                    (
                        <NavItem key={key}>
                            <NavLink
                                to={route.getFullPath()}
                                tag={NavLinkRRD}
                                onClick={this.closeCollapse}
                                activeClassName="active"
                                data-title={route.name}
                            >
                                <i className={route.icon}/>
                                <span>{route.name}</span>
                            </NavLink>
                        </NavItem>
                    ));
    };


    render() {
        const {routes, logo, sidebarActive} = this.props;
        let navbarBrandProps;
        if (logo && logo.innerLink) {
            navbarBrandProps = {
                to: logo.innerLink,
                tag: Link
            };
        } else if (logo && logo.outterLink) {
            navbarBrandProps = {
                href: logo.outterLink,
                target: "_blank"
            };
        }
        return (
                <Navbar
                        className={`navbar-vertical fixed-left ${sidebarActive ? "navbar-small" : ""}`}
                        expand="md"
                        id="sidenav-main"
                        style={sidebarActive ? {maxWidth: '80px'} : {maxWidth: '250px'}}
                >
                    <Container fluid>
                        {/* Toggler */}
                        <button
                                className="navbar-toggler"
                                type="button"
                                onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-icon"/>
                        </button>
                        {/* Brand */}
                        {logo ? (
                                <NavbarBrand className="pt-0 d-flex flex-column" {...navbarBrandProps}>
                                    <OptaNavbarBrand logo={logo}/>
                                </NavbarBrand>
                        ) : null}
                        {/* User */}
                        <UncontrolledDropdownUserMenu className="align-items-center d-md-none"/>
                        {/* Collapse */}
                        <Collapse navbar isOpen={this.state.collapseOpen}>
                            {/* Collapse header */}
                            <div className="navbar-collapse-header d-md-none">
                                <Row>
                                    {logo ? (
                                            <Col className="collapse-brand" xs="6">
                                                {logo.innerLink ? (
                                                        <Link to={logo.innerLink}>
                                                            <OptaNavbarBrand logo={logo}/>
                                                        </Link>
                                                ) : (
                                                        <a href={logo.outterLink}>
                                                            <OptaNavbarBrand logo={logo}/>
                                                        </a>
                                                )}
                                            </Col>
                                    ) : null}
                                    <Col className="collapse-close" xs="6">
                                        <button
                                                className="navbar-toggler"
                                                type="button"
                                                onClick={this.toggleCollapse}
                                        >
                                            <span/>
                                            <span/>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            {/* Form */}
                            <Form className="mt-4 mb-3 d-md-none">
                                <InputGroup className="input-group-rounded input-group-merge">
                                    <Input
                                            aria-label="Search"
                                            className="form-control-rounded form-control-prepended"
                                            placeholder="Search"
                                            type="search"
                                    />
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <span className="fa fa-search"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Form>
                            {/* Navigation */}
                            <Nav navbar>{this.createLinks(routes)}</Nav>
                        </Collapse>
                    </Container>
                </Navbar>
        );
    }
}

Sidebar.defaultProps = {
    routes: [{}]
};

Sidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired
    })
};