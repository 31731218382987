import React from 'react';
import MaterialTable from "material-table";
import UController from "components/Controller/UController";

/**
 * Interactive list of sectors management
 */
class SectorsList extends React.PureComponent {

    static propTypes = {};

    static defaultProps = {};

    state = {
        tableRef: React.createRef(),
    };

    onRowUpdate = newData => UController.sector.patch(newData);

    onDataQuery = query => {
        /**
         *
         * @type {Filter[]}
         */
        let filters = query.filters;
        // Build search query
        let searchQuery = [];
        if (filters.length > 0)
            filters.forEach(filter => {
                switch (filter.column.field) {
                    case "name":
                        if (filter.value.length > 0)
                            searchQuery.push("name=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    case "enabled":
                        searchQuery.push("enabled==" + (filter.value === "checked" ? "true" : "false"));
                        break;
                    case "externalId.value":
                        searchQuery.push("externalId.value=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    default:
                        break;
                }
            });
        return UController.sector.iQuery(query, searchQuery);
    };


    onRowAdd = (newData: Object) => UController.sector.post(newData);

    static title = "Spécialités";

    render() {
        // noinspection JSUnresolvedFunction
        return (
            <MaterialTable
                title={SectorsList.title}
                tableRef={this.state.tableRef}
                columns={[
                    {
                        field: "id",
                        title: "Id",
                        editable: "never",
                        hidden: true,
                    },
                    {
                        field: "enabled",
                        title: "Actif",
                        initialEditValue: true,
                        type: "boolean",
                    },
                    {
                        field: "name",
                        title: "Nom",
                        filtering: true
                    },
                    {
                        field: "rgbColor",
                        title: "Couleur",
                        filtering: false,
                        editComponent: props => (
                            <input
                                type="color"
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                            />
                        ),
                        render: rowData => <div
                            style={{
                                width: "100px",
                                height: "20px",
                                backgroundColor: rowData["rgbColor"]
                            }}/>
                    },
                    {
                        field: "externalId.value",
                        title: "Transcodage",
                        type: "string"
                    }
                ]}
                data={this.onDataQuery}
                editable={{
                    onRowAdd: this.onRowAdd,
                    onRowUpdate: this.onRowUpdate,
                }}
                options={{
                    exportButton: true, 
                    exportDelimiter: ';',
                    exportFileName: SectorsList.title,
                    filtering: true,
                    grouping: false,
                    search: false,
                    debounceInterval: 1000,
                    draggable: false,
                    paging: true,
                    addRowPosition: "first",
                    pageSize: 10
                }}
                actions={[
                    {
                        icon: "refresh",
                        tooltip: "Rafraîchir",
                        isFreeAction: true,
                        onClick: () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange()
                    }
                ]}
            />
        );
    }
}

export default SectorsList;