import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {
    Button,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import moment from 'moment';
import AvailabilitiesAPI from 'api/AvailabilitiesAPI';

const FormContainer = styled('div')`
    padding: 10px;
    overflow-x: hidden;
    .form-group{
        padding: 10px;
        margin-bottom: 0 !important;
    }
    .radios-container{
        display: flex;
        margin-top: 10px;
        .radio-group{
            margin-right: 20px;
            label{
                font-size: 16px;
                padding-left: 5px;
            }
        }
    }
`

const StaffAvailabilitiesForm = (props) => {

    const {close, selectedSlot, closeAndReload} = props
    const [error, setError] = useState("")

    const [formState, setFormState] = useState({
        capacity: 1,
        startDate: "",
        startHour: "",
        endDate: "",
        endHour: "",
        comment: "" 
    })

    useEffect(() => {
        if(!!selectedSlot){
            setFormState(formState => ({
                ...formState,
                startDate: moment(selectedSlot.interval.start).format("YYYY-MM-DD"),
                startHour: moment(selectedSlot.interval.start).format("HH:mm"),
                endDate: moment(selectedSlot.interval.end).format("YYYY-MM-DD"),
                endHour: moment(selectedSlot.interval.end).format("HH:mm"),
                capacity: selectedSlot.capacity ?? 1,
                comment: selectedSlot.comment 
            }))
        }
    }, [selectedSlot])

    const handleSubmit = () => {

        setError(undefined)

        if(!formState.startDate || !formState.startHour || !formState.endDate || !formState.endHour){
            setError("Veuillez remplir l'interval entièrement.")
            return
        }

        if(!selectedSlot.id){ //Create
            AvailabilitiesAPI.createAvailability("exceptional", {
                staffId: selectedSlot.resource?.id?.split("_")?.[1],
                sectorId: selectedSlot.resource?.extendedProps?.sectorId ?? undefined,
                capacity: formState.capacity,
                comment: formState.comment,
                interval: {
                    start: `${formState.startDate}T${formState.startHour}`,
                    end: `${formState.endDate}T${formState.endHour}`,
                }
            })
            .then(closeAndReload)
            .catch(e => null)
        } else {
            AvailabilitiesAPI.patchAvailability("exceptional", selectedSlot.id, {
                id: selectedSlot.id,
                staffId: selectedSlot.resource?.id?.[0]?.split("_")?.[1],
                capacity: formState.capacity,
                comment: formState.comment,
                interval: {
                    start: `${formState.startDate}T${formState.startHour}`,
                    end: `${formState.endDate}T${formState.endHour}`,
                }
            })
            .then(closeAndReload)
            .catch(e => null)
        }
    }

    return (
        <FormContainer>
            {!!error && <p style={{background: "#f5365c", padding: 5, color: "#fff", textAlign: 'center', fontWeight: 400}}>{error}</p>}
            <form>
                <div className='form-group'>
                    <div className="radios-container">
                        <div className="radio-group">
                            <input type="radio" name="radio-availability" id="radio-availability-1" checked={formState.capacity === 1} onChange={() => setFormState({...formState, capacity: 1})}/>
                            <label htmlFor="radio-availability-1"  onClick={() => setFormState({...formState, capacity: 1})}>Disponible</label>
                        </div>
                        <div className="radio-group">
                            <input type="radio" name="radio-availability" id="radio-availability-0" checked={formState.capacity === 0} onChange={() => setFormState({...formState, capacity: 0})}/>
                            <label htmlFor="radio-availability-0"  onClick={() => setFormState({...formState, capacity: 0})}>Indisponible</label>
                        </div>

                    </div>
                </div>
                <Row style={{paddingLeft: 15}}>
                    <div style={{display: 'flex'}}>
                        <FormGroup>
                            <Label for="start">Début</Label>
                            <Input  
                                id="startDate"
                                type="date"
                                value={formState.startDate}
                                onChange={e => setFormState({...formState, startDate: e.currentTarget.value})}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="start">Heure de début</Label>
                            <Input  
                                id="startHour"
                                type="time"
                                value={formState.startHour}
                                onChange={e => setFormState({...formState, startHour: e.currentTarget.value})}/>
                        </FormGroup>
                        
                    </div>
                </Row>
                <Row style={{paddingLeft: 15}}>
                    <div style={{display: 'flex'}}>
                        <FormGroup>
                            <Label for="end">Fin</Label>
                            <Input  
                                id="endDate"
                                type="date"
                                value={formState.endDate}
                                onChange={e => setFormState({...formState, endDate: e.currentTarget.value})}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="end">Heure de fin</Label>
                            <Input  
                                id="endHour"
                                type="time"
                                value={formState.endHour}
                                onChange={e => setFormState({...formState, endHour: e.currentTarget.value})}/>
                        </FormGroup>
                    </div>
                </Row>
                <p style={{fontStyle: 'italic', fontSize: 14, marginLeft: 10}}>Exemple pour une journée entière : 05/05/2023 00:00 - 05/05/2023 23:59</p>

                <FormGroup>
                    <Label for="comment">Commentaire</Label>
                    <Input  
                        id="comment"
                        type="text"
                        value={formState.comment}
                        onChange={e => setFormState({...formState, comment: e.currentTarget.value})}/>
                </FormGroup>


                <br/>
                <div>
                    <Button color="secondary" onClick={close}>Annuler</Button>
                    <Button color="primary" onClick={handleSubmit}>Ajouter</Button>{' '}
                </div>
            </form>
        </FormContainer>
    )

}

export default StaffAvailabilitiesForm