import React, {useEffect, useState} from "react";
import FormGroup from "reactstrap/es/FormGroup";
import ButtonGroup from "reactstrap/es/ButtonGroup";
import Button from "reactstrap/es/Button";
import {RRule} from "rrule";
import Label from "reactstrap/es/Label";

const dayChoices = [
    {
        name: "Lundi",
        val: 0,
    },
    {
        name: "Mardi",
        val: 1,
    },
    {
        name: "Mercredi",
        val: 2,
    },
    {
        name: "Jeudi",
        val: 3,
    },
    {
        name: "Vendredi",
        val: 4,
    },
    {
        name: "Samedi",
        val: 5,
    },
    {
        name: "Dimanche",
        val: 6,
    },
    {
        name: "Jour",
        val: [0, 1, 2, 3, 4, 5, 6],
    },
    {
        name: "Jour du semaine",
        val: [0, 1, 2, 3, 4],
    },
    {
        name: "Weekend",
        val: [5, 6],
    },
];
const dayConstants = {
    0: "MO",
    1: "TU",
    2: "WE",
    3: "TH",
    4: "FR",
    5: "SA",
    6: "SU",
};
const dows = dayChoices.slice(0, 7);
const monthMap = {
    1: "Janvier",
    2: "Février",
    3: "Mars",
    4: "Avril",
    5: "Mai",
    6: "Juin",
    7: "Juillet",
    8: "Août",
    9: "Septembre",
    10: "Octobre",
    11: "Novembre",
    12: "Décembre",
};
/**
 * At precise date or at ordinal date
 * @param {Object} props
 * @param {function(string)} props.onFreqChange take a string argument to represent rrule
 * @param {{freq: number, interval: number, bymonth: number, bymonthday: number}} props.initialFreq
 * @return {React.Component}
 * @constructor
 */
const AnnualRecur = React.memo(({onFreqChange, initialFreq}) => {
    const [yearInterval, setYearInterval] = useState(1);
    const [day, setDay] = useState(1);
    const [month, setMonthPos] = useState(1);
    useEffect(() => {
        onFreqChange(
            "RRULE:FREQ=YEARLY"
            + ";INTERVAL=" + yearInterval
            + ";BYMONTH=" + monthMap[month]
            + ";BYMONTHDAY=" + day
        );
    }, [onFreqChange, yearInterval, month, day]);
    useEffect(() => {
        initialFreq.interval ? setYearInterval(initialFreq.interval) : setYearInterval(1);
        initialFreq.bymonth ? setMonthPos(initialFreq.bymonth) : setMonthPos(1);
        initialFreq.bymonthday ? setDay(initialFreq.bymonthday) : setDay(1);
    }, [initialFreq]);
    return <FormGroup className="mb-0 d-flex pt-2 align-items-baseline">
        <p className="flex-shrink-1 pl-2">Tous les </p>
        <input className="flex-shrink-1 mx-2 form-control" type="number"
               style={{
                   maxWidth: "80px"
               }}
               value={yearInterval} min={1}
               onChange={event => setYearInterval(event.target.value)}/>
        <p className="flex-shrink-1 px-2">ans. Au</p>
        <input type="number" min={1} max={31} value={day}
               className="w-auto form-control pl-2"
               style={{
                   maxWidth: "80px"
               }}
               onChange={event => setDay(event.target.value)}/>
        <select value={month}
                className="w-auto form-control pl-2"
                onChange={event => setMonthPos(event.target.value)}>
            {
                Object.keys(monthMap).map(m => <option key={m} value={m}>{monthMap[m]}</option>)
            }
        </select>
    </FormGroup>;
});
/**
 * At precise date or at ordinal date
 * @param {Object} props
 * @param {function(string)} props.onFreqChange take a string argument to represent rrule
 * @param {{freq: number, interval: number, bymonthday: number}} props.initialFreq
 * @return {React.Component}
 * @constructor
 */
const MonthlyRecur = React.memo(({onFreqChange, initialFreq}) => {
    const [monthInterval, setMonthInterval] = useState(1);
    const [day, setDay] = useState(1);
    useEffect(() => {
        onFreqChange(
            "RRULE:FREQ=MONTHLY"
            + ";INTERVAL=" + monthInterval
            + ";BYMONTHDAY=" + day
        );
    }, [onFreqChange, monthInterval, day]);
    useEffect(() => {
        initialFreq.interval ? setMonthInterval(initialFreq.interval) : setMonthInterval(1);
        initialFreq.bymonthday ? setDay(initialFreq.bymonthday) : setDay(1);
    }, [initialFreq]);
    return <FormGroup className="mb-0 d-flex flex-wrap pt-2 align-items-baseline">
        <p className="flex-shrink-1 pl-2">Tous les </p>
        <input className="flex-shrink-1 mx-2 form-control w-auto" type="number"
               style={{
                   maxWidth: "80px"
               }}
               min={1} value={monthInterval} onChange={event => setMonthInterval(event.target.value)}/>
        <p className="px-2">mois. Au jour</p>
        <input type="number" min={1} max={31}
               style={{
                   maxWidth: "80px"
               }}
               className="form-control mx-2 w-auto flex-shrink-1"
               value={day} onChange={event => setDay(event.target.value)}/>
    </FormGroup>;
});
/**
 * Days in week
 * @param {Object} props
 * @param {function(string)} props.onFreqChange take a string argument representing the new rrule
 * @param {{freq: number, interval: number, byweekday: {weekday: number}[]}} props.initialFreq take one arg representing the new frequency
 * @return {React.Component}
 * @constructor
 */
const WeeklyRecur = React.memo(({onFreqChange, initialFreq}) => {
    const [weekInterval, setWeekInterval] = useState(1);
    const [days, setDays] = useState([]);
    const toggleDay = dayPos => () => {
        if (days.includes(dayPos))
            setDays(days.filter(d => d !== dayPos));
        else
            setDays(days.concat(dayPos));
    };
    useEffect(() => {
        onFreqChange(
            "RRULE:FREQ=WEEKLY"
            + ";INTERVAL=" + weekInterval
            + (days.length > 0 ? ";BYDAY=" + (days.map(d => dayConstants[d]).join(",")) : "")
        )
    }, [onFreqChange, weekInterval, days]);
    useEffect(() => {
        initialFreq.interval ? setWeekInterval(initialFreq.interval) : setWeekInterval(1);
        initialFreq.byweekday ? setDays(initialFreq.byweekday.map(bwd => bwd.weekday)) : setDays([]);
    }, [initialFreq]);
    return <FormGroup className="mb-0 d-flex flex-wrap align-items-baseline">
        <div className="d-flex pt-2 align-items-baseline">
            <p className="flex-shrink-1 pl-2">Toutes les </p>
            <input className="flex-shrink-1 mx-2 form-control"
                   type="number"
                   min={1}
                   style={{
                       maxWidth: "80px"
                   }}
                   value={weekInterval}
                   onChange={event => setWeekInterval(event.target.value)}/>
            <p className="flex-shrink-1 px-2">semaines.</p>
        </div>
        <ButtonGroup className="d-flex flex-wrap">
            {
                dows.map((d, i) =>
                    <Button size="sm" color="info" key={i} active={days.includes(i)} onClick={toggleDay(i)}>
                        {d.name.substring(0, 3)}
                    </Button>
                )
            }
        </ButtonGroup>
    </FormGroup>;
});
/**
 * After certain interval of days
 * @param {Object} props
 * @param {function(string)} props.onFreqChange take a string argument representing the new rrule
 * @param {{freq: number, interval: number}} props.initialFreq
 * @return {React.Component}
 * @constructor
 */
const DailyRecur = React.memo(({onFreqChange, initialFreq}) => {
    const [dayInterval, setDayInterval] = useState(1);
    useEffect(() => {
        onFreqChange("RRULE:FREQ=DAILY;INTERVAL=" + dayInterval);
    }, [onFreqChange, dayInterval]);
    useEffect(() => {
        initialFreq.interval ? setDayInterval(initialFreq.interval) : setDayInterval(1);
    }, [initialFreq]);
    return <FormGroup className="mb-0">
        <div className="d-flex pt-2 align-items-baseline">
            <p className="flex-shrink-1 pl-2">Tous les </p>
            <input className="flex-shrink-1 mx-2 form-control" type="number"
                   min={1}
                   style={{
                       maxWidth: "80px"
                   }}
                   value={dayInterval}
                   onChange={event => setDayInterval(event.target.value)}/>
            <p className="flex-shrink-1 pl-2">jours.</p>
        </div>
    </FormGroup>;
});
const FreqRecurType = {
    WEEKLY: {
        name: "Hebdomadaire",
        component: WeeklyRecur,
    },
    DAILY: {
        name: "Quotidienne",
        component: DailyRecur,
    },
    MONTHLY: {
        name: "Mensuelle",
        component: MonthlyRecur,
    },
    YEARLY: {
        name: "Annuelle",
        component: AnnualRecur,
    }
};
/**
 * Defines the rhythm of occurrences
 * @param {Object} props
 * @param {function(Object)} props.onFreqChange take one arg representing the new frequency
 * @param {{freq: *}} props.initialFreq
 * @param {number} props._key to reset
 * @return {React.Component}
 * @constructor
 */
const FreqRecur = ({onFreqChange, initialFreq, _key}) => {
    const [freqType, setFreqType] = useState("WEEKLY");
    const FreqComponent = FreqRecurType[freqType].component;
    useEffect(() => {
        if (initialFreq.freq) {
            if (initialFreq.freq === RRule.YEARLY)
                setFreqType("YEARLY");
            else if (initialFreq.freq === RRule.MONTHLY)
                setFreqType("MONTHLY");
            else if (initialFreq.freq === RRule.DAILY)
                setFreqType("DAILY");
            else
                setFreqType("WEEKLY");
        } else
            setFreqType("WEEKLY"); // Default
    }, [initialFreq, _key]);
    return <FormGroup>
        <Label>
            Rythme de récurrence:
        </Label>
        <select
            className="d-inline-block w-auto form-control"
            value={freqType}
            onChange={event => setFreqType(event.target.value)}>
            {Object.keys(FreqRecurType).map(f =>
                <option value={f} key={f}>{FreqRecurType[f].name}</option>)}
        </select>
        <FreqComponent onFreqChange={onFreqChange} initialFreq={initialFreq}/>
    </FormGroup>;
};

export default FreqRecur;