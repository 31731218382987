import React from "react";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import type {Dict} from "Utils";
import {getFullNameWithPrefix, parseISODateTimeToMilliseconds} from "Utils";
import ReactCalendarTimeline, {
    DateHeader,
    SidebarHeader,
    TimelineHeaders,
    TimelineMarkers,
    TodayMarker
} from "react-calendar-timeline";
import config from "config";
import Sector from "components/ClassWrapper/Sector";
import MaterialType from "components/ClassWrapper/MaterialType";
import RdvItem from "views/planning/RdvItem";
import memoize from "memoize-one";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import type {PatientItem} from "views/jobs/PatientItem";
import {getLastStatus} from "components/ClassWrapper/Rendezvous";

export default class JobsTimeline extends React.PureComponent {

    props: {
        completeRdvArray: Array<CompleteRdv>,
        /**
         *
         * @param {CompleteRdv | null} rdv the selected rdv
         */
        onRdvClick: (rdv: CompleteRdv | null) => void,
        dict: {
            sector: Dict<Sector>,
            materialType: Dict<MaterialType>
        },
        /**
         * iso date
         */
        date: string,
        config: OptaConfig,
    };

    /**
     *
     * @param {number} itemId
     */
    onRdvClick = (itemId) =>
        this.props.onRdvClick(
            this.props.completeRdvArray.find(v => v.id === itemId)
        );

    /**
     *
     * @type {function(CompleteRdv): RdvItem}
     */
    memo_parseCompleteRdvToRdvItem = memoize(
        (crdv: CompleteRdv): RdvItem =>
            ({
                id: crdv.rdv.id,
                group: this.getGroupId(crdv),
                title: getFullNameWithPrefix(crdv.patient),
                start_time: parseISODateTimeToMilliseconds(crdv.rdv.sessionDay, crdv.rdv.session.start),
                end_time: parseISODateTimeToMilliseconds(crdv.rdv.sessionDay, crdv.rdv.session.end),
                backgroundColor: this.props.dict.sector[crdv.protocol.sectorId]?.rgbColor ?? "#ffffff",
                rawData: crdv,
            })
    );

    memo_parseCompleteRdvArrayToRdvItems = memoize(
        (crdvs: Array<CompleteRdv>): Array<RdvItem> =>
            crdvs.map(this.memo_parseCompleteRdvToRdvItem)
    );

    getGroupId = (crdv: CompleteRdv): String => crdv.patient.id + "_" + crdv.rdv.id;

    /**
     *
     * @param {CompleteRdv} crdv
     * @return {PatientItem}
     */
    parseCompleteRdvToPatientItem = (crdv: CompleteRdv): PatientItem => ({
        rawData: crdv.patient,
        id: this.getGroupId(crdv),
        title: <p><span className="font-weight-bold">{getFullNameWithPrefix(crdv.patient)}</span><br/><span className="font-italic">{crdv.patient.externalId?.value ?? "N/A"}</span></p>,
        rightTitle: crdv.rdv.nurse ? getFullNameWithPrefix(crdv.rdv.nurse) : "",
    });

    memo_parseCompleteRdvToPatientItem = memoize(
        this.parseCompleteRdvToPatientItem
    );

    memo_parseCompleteArrayToPatientItems = memoize(
        (crdvs: Array<CompleteRdv>): Array<PatientItem> =>
            crdvs.map(this.memo_parseCompleteRdvToPatientItem)
    );

    /**
     *
     * @param {RdvItem} item
     * @param itemContext
     * @param getItemProps
     * @return {React.ReactElement}
     */
    rdvItemRenderer = ({item, itemContext, getItemProps}) =>
        (
            <div
                {...getItemProps({
                    style: {
                        background: item.backgroundColor,
                        border: "2px solid black",
                    }
                })}>
                <div style={{
                    height: itemContext.dimensions.height,
                }}
                     className={"rendezvous"}>
                    <span className="p-1 bg-dark text-white rendezvous-label">
                        {
                            getLastStatus(item.rawData.rdv).icon
                                .map((icon, i) =>
                                <i key={i} className={icon + " fa-2x"}/>)
                        }
                    </span>
                </div>
            </div>
        );

    render() {
        const rdvItems = this.memo_parseCompleteRdvArrayToRdvItems(this.props.completeRdvArray);
        const patientItems = this.memo_parseCompleteArrayToPatientItems(this.props.completeRdvArray);

        return (
            rdvItems.length > 0 && patientItems.length > 0 &&
            <ReactCalendarTimeline
                items={rdvItems}
                groups={patientItems}
                visibleTimeStart={parseISODateTimeToMilliseconds(this.props.date, this.props.config.openingHour)}
                visibleTimeEnd={parseISODateTimeToMilliseconds(this.props.date, this.props.config.closingHour)}
                canMove={false}
                canChangeGroup={false}
                canResize={false}
                canSelect={true}
                onItemClick={this.onRdvClick}
                onItemSelect={this.onRdvClick}
                itemRenderer={this.rdvItemRenderer}
                sidebarWidth={150}
                stickyHeader={true}
                lineHeight={60}
                itemHeightRatio={0.7}
                itemTouchSendsClick={true}
                rightSidebarWidth={150}
                style={{
                    maxHeight: "100%",
                    overflowY: "auto",
                }}
            >
                <TimelineHeaders className="sticky-top bg-primary">
                    <SidebarHeader>
                        {({getRootProps}) =>
                            <div {...getRootProps()}>
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <span className="text-white mx-1">Patient</span>
                                </div>
                            </div>}
                    </SidebarHeader>
                    <SidebarHeader variant={"right"}>
                        {({getRootProps}) =>
                            <div {...getRootProps()}>
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <span className="text-white mx-1 text-right">Infirmière</span>
                                </div>
                            </div>}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader"/>
                    <DateHeader
                        unit="hour"
                        labelFormat={config.timeFormat}
                    />
                </TimelineHeaders>
                <TimelineMarkers>
                    <TodayMarker>
                        {({styles}) =>
                            <div style={{
                                ...styles,
                                zIndex: 99,
                            }}/>
                        }
                    </TodayMarker>
                </TimelineMarkers>
            </ReactCalendarTimeline>
        );
    }
}