/**
 *
 * @typedef {Object<string, number>} PagingInfo
 * @property {number} pageIndex index of current page
 * @property {number} totalPages number of pages
 * @property {number} totalElements number of elements in database
 * @property {number} size number of elements requested in this page (not the length of array)
 */


class PageableArray<T> {
    constructor(array: Array<T>, {size, totalElements, totalPages, pageIndex}) {
        /**
         *
         * @type {Array<T>}
         */
        this.array = array;
        /**
         *
         * @type {PagingInfo}
         */
        this.paging = {size, totalElements, totalPages, pageIndex};
    }
}

export default PageableArray;