import React, { useEffect, useState } from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import MaterialTable from "material-table";
import Input from "reactstrap/es/Input";
import { MATERIAL_TABLE_LOCALIZATION_FR } from "Utils";
import { LocalTimeString } from "components/ClassWrapper/TimeClasses";

/**
 * 
 * @param {LocalTimeString} timeStr supposed in format "mm:HH:SS"
 */
const trimToMinutes = (timeStr?: LocalTimeString): LocalTimeString | null => timeStr?.substring(0, 5);

/**
 * Defines the overlapping intervals
 * @param {Object} props
 * @param {function(CompressedTimeInterval[])} props.onTimeIntervalsChange take an array the new intervals
 * @param {CompressedTimeInterval[]} props.initialTimeIntervals
 * @param {number} props._key to reset
 * @param {LocalTimeString} defaultIntervalStart
 * @param {LocalTimeString} defaultIntervalEnd
 * @return {React.Component}
 * @constructor
 */
const WorkHour = ({ onTimeIntervalsChange, initialTimeIntervals, _key, defaultIntervalStart, defaultIntervalEnd }) => {
    const [timeIntervals, setTimeIntervals] = useState(initialTimeIntervals);
    useEffect(() => {
        onTimeIntervalsChange(timeIntervals);
    }, [onTimeIntervalsChange, timeIntervals]);
    useEffect(() => {
        setTimeIntervals(initialTimeIntervals);
    }, [initialTimeIntervals, _key]);
    return <FormGroup>
        <Label>
            Les créneaux horaires d'une journée:
        </Label>
        <MaterialTable
            style={{
                width: "100%",
            }}
            columns={[
                {
                    field: "start",
                    title: "Début",
                    type: "time",
                    initialEditValue: trimToMinutes(defaultIntervalStart),
                    editComponent: props =>
                        <Input
                            type="time"
                            value={props.value}
                            onChange={e => props.onChange(trimToMinutes(e.target.value))}
                        />,
                },
                {
                    field: "end",
                    title: "Fin",
                    type: "time",
                    initialEditValue: trimToMinutes(defaultIntervalEnd),
                    editComponent: props =>
                        <Input
                            type="time"
                            value={props.value}
                            onChange={e => props.onChange(trimToMinutes(e.target.value))}
                        />,
                },
                {
                    field: "effective",
                    title: "Effectif",
                    type: "numeric",
                    initialEditValue: 1,
                    editComponent: props =>
                        <Input
                            type="number"
                            min={1}
                            value={props.value}
                            onChange={e => props.onChange(parseInt(e.target.value))}
                        />,
                }
            ]}
            data={timeIntervals}
            options={{
                filtering: false,
                draggable: false,
                grouping: false,
                paging: false,
                search: false,
                padding: "dense",
                showTitle: false,
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        if (newData["start"] >= newData["end"]) {
                            alert("Le début de créneau doit être avant la fin de créneau")
                            reject();
                        } else {
                            setTimeIntervals(timeIntervals.concat(newData));
                            resolve();
                        }
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        if (newData["start"] >= newData["end"]){
                            alert("Le début de créneau doit être avant la fin de créneau")
                            reject();
                        } else {
                            let index = timeIntervals.indexOf(oldData);
                            timeIntervals[index] = newData;
                            setTimeIntervals([...timeIntervals]);
                            resolve();
                        }
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        let index = timeIntervals.indexOf(oldData);
                        timeIntervals.splice(index, 1);
                        setTimeIntervals([...timeIntervals]);
                        resolve();
                    }),
            }}
            localization={MATERIAL_TABLE_LOCALIZATION_FR}
        />
    </FormGroup>;
};

export default WorkHour;