import { darken } from '@material-ui/core';
import { DeleteOutline, Edit, KeyboardArrowDown, KeyboardArrowUp, Print, VisibilityOff } from '@material-ui/icons';
import { getFullNameWithPrefix, getLocalDateStrFromISO, printIsoTime } from 'Utils';
import Cycle from 'components/ClassWrapper/Cycle';
import Rendezvous from "components/ClassWrapper/Rendezvous";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import { useCallback, useState } from 'react';
import { styled } from 'styled-components';
import type { ModelId } from 'components/ClassWrapper/BasicModel';
import { generatePDFConvocation, generatePDFConvocationRdvList } from 'tools/pdf_generators';
import type { DateString } from 'components/ClassWrapper/TimeClasses';

const Container = styled('div')`
    width: 300px;
    position: relative;
    z-index: 999;
    margin-left: 5px;
    span.opener{
        height: 40px;
        line-height: 40px;
        padding: 0px 5px;
        cursor: pointer;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        transition: .2s;
        background: ${props => props.isOpen ? "rgba(0,0,0,.1)" : "inherit"};
        &:hover{
            background: rgba(0,0,0,.1);
        }
    }
    ul.suggestion-cycles-list{
        position: absolute;
        z-index: 1;
        bottom: 25px;
        left: 0;
        list-style: none;
        padding-left: 0px;
        width: 100%;
        background: #fff;
        border-radius: 3px;
        padding: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        li.suggestion-cycles-item{
            font-size: 13px;
            display: flex;
            flex-direction: column;
            padding: 5px 0px;
            .main-items-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .rdvs-items-list{
                span{
                    font-style: italic;
                }
            }
            .rdvs-items-list-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        li.all-actions{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #525f7f;
            padding-top: 5px;
            margin-top: 5px;
        }
        .icons-container{
            svg{
                cursor: pointer;
                margin-right: 2px;
            }
        }
    }
`

const IconActions = styled('span')`
    cursor: pointer;
    svg{
        color: #fff;
        padding: 2px 4px; 
        border-radius: 2px; 
        background: ${props => props.background};
        &:hover{
            background: ${props => darken(props.background, .2)};
        }
        margin: 0.5px
    }
`

type Props = {
    label: String,
    cycles: Cycle[],
    onRdvsDeleteAll: () => void,
    onRdvsDeselectAll: () => void,
    onCycleDeselect: (cycleId: number) => void,
    onCycleDelete: (cycleIds: number[]) => void,
    onRdvDelete: (rdv: Rendezvous) => void,
    onRdvEdit: (rdvId: Number) => void,
    getPlaceLabel: (placeId: ModelId) => String,
    currentDate: DateString,
}

const CycleSelectorViewer = (props: Props) => {

    const { label, cycles, onRdvsDeleteAll, onRdvsDeselectAll, onCycleDeselect, onCycleDelete, onRdvDelete, onRdvEdit, getPlaceLabel, currentDate } = props

    const [open, setOpen] = useState(true)

    const toggleOpen = useCallback(() => setOpen(o => !o), [setOpen])
    return (
        <Container isOpen={open}>
            <span className="opener font-weight-bold mr-2" onClick={toggleOpen}>
                {label}
                {!open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </span>
            {
                open && cycles.length > 0 &&
                <ul className='suggestion-cycles-list'>
                    {cycles.map(cycle => (
                        <li key={cycle.id} className={"suggestion-cycles-item"}>
                            <div className="main-items-container">
                                <span>{getFullNameWithPrefix(cycle?.patient)}</span>
                                <div className="icons-container">
                                    <IconActions key={"print"} background={"#2dce89"}><Print onClick={() => generatePDFConvocationRdvList(cycle)} /></IconActions>
                                    <IconActions key={"select"} background={"#ffc107"}><VisibilityOff onClick={() => onCycleDeselect(cycle.id)} /></IconActions>
                                    <IconActions key={"delete"} background={"#dc3545"}><DeleteOutline onClick={() => onCycleDelete([cycle.id])} /></IconActions>
                                </div>
                            </div>
                            <div className="rdvs-items-list">
                                {cycle.rendezvousList.map((rdv, k) => {
                                    const modifiable = rdv.sessionDay >= currentDate;
                                    return (
                                        <div key={k} className="rdvs-items-list-item">
                                            <div className="rdv-description-short">
                                                <span>{k + 1}. </span>
                                                {getPlaceLabel(rdv.place.id)}
                                                <span>{getLocalDateStrFromISO(rdv.sessionDay, true)} </span>
                                                <span>{printIsoTime(rdv.session.start)} - {printIsoTime(rdv.session.end)}</span>
                                            </div>
                                            <div className="icons-container">
                                                <IconActions background={"#2dce89"} key={"print"}><Print onClick={() => generatePDFConvocation(new CompleteRdv(cycle, rdv))} /></IconActions>
                                                <IconActions background={modifiable ? "#2f80ed" : "#dbdbdb"} key={"edit"}><Edit onClick={() => modifiable && onRdvEdit(rdv.id)} /></IconActions>
                                                <IconActions background={modifiable ? "#dc3545" : "#dbdbdb"} key={"delete"}><DeleteOutline onClick={() => modifiable && onRdvDelete(rdv)} /></IconActions>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </li>
                    ))}
                    <li className='all-actions'>
                        <span className='font-weight-bold'>Tous les RDVs :</span>
                        <div className="icons-container">
                            <IconActions background={"#ffc107"} onClick={onRdvsDeselectAll}><VisibilityOff /></IconActions>
                            <IconActions background={"#dc3545"} onClick={onRdvsDeleteAll}><DeleteOutline /></IconActions>
                        </div>
                    </li>
                </ul>
            }
        </Container>
    )

}

export default CycleSelectorViewer