import {defVal} from "Utils";

export type AvailabilitySystem = "SESSION" | "AVAILABILITY_STAFF"

export default class OptaConfig {
    static type = "CHTConfig";

    constructor(o) {
        o = o || {};
        /**
         *
         * @type {number} number of parallel administrations allowed for a nurse
         */
        this.numMultiTask = defVal(o["numMultiTask"], 1);
        /**
         * @type {number} in minutes >= 1
         */
        this.timeSlotLength = defVal(o["timeSlotLength"], 1);
        /**
         * @type {number} in minutes >= 1
         */
        this.consultationLength = defVal(o["consultationLength"], 1);
        /**
         * @type {number} in minutes >= 1
         */
        this.installationLength = defVal(o["installationLength"], 1);
        /**
         * @type {string} in ISO format
         */
        this.openingHour = defVal(o["openingHour"], "00:00:00");
        /**
         * @type {string} in ISO format
         */
        this.realOpeningHour = defVal(o["realOpeningHour"], "00:00:00");
        /**
         * @type {string} in ISO format
         */
        this.closingHour = defVal(o["closingHour"], "00:00:00");
        /**
         * @type {AvailabilitySystem} 
         */
        this.professionAvailabilitySystemDefault = defVal(o["professionAvailabilitySystemDefault"], "SESSION");
        /**
         * @type {Object<string, AvailabilitySystem>} 
         */
        this.professionAvailabilitySystem = defVal(o["professionAvailabilitySystem"], null);
        /**
         * @type {number} how many days a RDV can be delayed. Must >= 0
         */
        this.daysDelayLimit = o.daysDelayLimit;
    }
}