import React from "react";
import {UncontrolledTooltip} from "reactstrap";
import {getFullNameWithPrefix, getFullNameWithPrefixDoctor, getISODateFromISODateTime} from "Utils";
import {CYCLE_STATUS, CYCLE_STATUS_FR} from "components/ClassWrapper/Cycle";
import {getStatus} from "components/ClassWrapper/Rendezvous";
import { PictureAsPdf, EditOutlined } from '@material-ui/icons';
import { generatePDFConvocation } from "tools/pdf_generators";

export default class VisualRdv extends React.PureComponent {
    render() {
        let {item, itemContext, getItemProps, minLabelVisibleWidth, onEdit} = this.props;
        minLabelVisibleWidth = minLabelVisibleWidth || 50;
        /**
         *
         * @type {CompleteRdv}
         */
        let rawData = item.rawData;
        let _background = itemContext.selected ? "orange" : item.backgroundColor;
        if (rawData.registerStatus === CYCLE_STATUS.TO_DELETE) { 
            let stripeColor = itemContext.selected ? "orange" : item.backgroundColor;
            _background = "repeating-linear-gradient(45deg, black, black 4px, " + stripeColor + " 4px, " + stripeColor + " 12px)";
        }
        return (
            <div
                {...getItemProps({
                    style: {
                        background: _background,
                        color: "whitesmoke",
                        border: null, // Remove default style
                        borderColor: itemContext.selected ? item.backgroundColor : "black",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 2,
                    },
                    className: "rendezvous " + rawData.registerStatus,
                })}
                id={"rendezvous-" + item.id}
            >
                {
                    itemContext.dimensions.width >= minLabelVisibleWidth &&
                        <div style={{height: itemContext.dimensions.height}}>
                            <span className={"font-weight-bold p-1 rounded bg-dark text-white rendezvous-label"}>
                                {getFullNameWithPrefix(rawData.patient)}
                            </span>
                        </div>
                }
                <UncontrolledTooltip placement="left" target={"rendezvous-" + item.id} trigger="hover" autohide={false}
                                     style={{maxWidth: "250px", textAlign: "left"}}>
                    <h2 className="text-white text-uppercase border-bottom border-white">{getFullNameWithPrefix(rawData.patient)}</h2>
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            rawData.rdv.sessionDay >= getISODateFromISODateTime(new Date().toISOString()) &&
                            <span className="text-left" style={{cursor: "pointer"}} onClick={() => onEdit(rawData.id)} ><EditOutlined/>Modifier</span>
                        }
                        {
                            rawData.registerStatus === CYCLE_STATUS.CONFIRMED &&
                            <span className="text-right" style={{cursor: "pointer"}} onClick={() => generatePDFConvocation(rawData)}>Imprimer <PictureAsPdf /></span>
                        }
                    </div>
                    <h3 className="text-white text-capitalize font-italic">{getStatus(rawData.rdv).map(s => s.name).join(" | ")}</h3>
                    <div className="border-bottom border-white">
                        <p className="m-0 p-0 font-weight-bold">{rawData.protocol.name}</p>
                        <p className="m-0 p-0">{rawData.rdv.session.start.substr(0, 5)} - {rawData.rdv.session.end.substr(0, 5)}</p>
                        {
                            !!rawData.rdv.consultation
                            && <p className="m-0 p-0">Médecin : {getFullNameWithPrefixDoctor(rawData.rdv.doctor)}</p>
                        }
                        <p className="m-0 p-0">Infirmière : {getFullNameWithPrefix(rawData.rdv.nurse)}</p>
                        <p className="m-0 p-0">Note : {rawData.patient.comment || ""}</p>
                        <p className="m-0 p-0">Commentaire : {rawData.rdv.comment || ""}</p>
                    </div>
                    <p className="m-0 p-0 font-italic">{CYCLE_STATUS_FR[rawData.registerStatus]}</p>
                </UncontrolledTooltip>
            </div>
        );
    }
}