import React, { useState } from 'react';
import styled from 'styled-components';
import AvailabilityRegularDayItem from './AvailabilityRegularDayItem';
import { useEffect } from 'react';
import { useKeyPress } from 'components/useKeyPress';
import { Row } from 'reactstrap';
import { Button } from '@material-ui/core';
import { createContext } from 'react';
import AvailabilitiesAPI from 'api/AvailabilitiesAPI';

const Container = styled('div')`
    padding: 10px;

    .title{
        margin-bottom: 10px;
        display: block;
        i{
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .shift-text-explain{
        display: block;
        font-style: italic;
        text-align: center;
        font-size: 12px;
    }
`

function convertNumberToLitteralHour(nombre){
    const heures = Math.floor(nombre);
    const minutes = (nombre - heures) * 60;
    const heureFormattee = heures.toString().padStart(2, '0');
    const minuteFormattee = minutes.toString().padStart(2, '0');
    return `${heureFormattee}:${minuteFormattee}:00`;
}

function convertLitteralHourToNumber(time){
    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':').map(Number);

    // Calculate the float representation of the time
    const floatTime = hours + minutes / 60;

    return floatTime;
}

function groupByInterval(arr: number[]): {start: string, end: string}[] {

    arr.sort((a,b) => a-b)

    if (arr.length === 0) {
      return [];
    }
  
    const result = [];
    let currentInterval = { start: arr[0], end: arr[0] };
  
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] - currentInterval.end === 0.5) {
        currentInterval.end = arr[i];
      } else {
        result.push({ start: convertNumberToLitteralHour(currentInterval.start), end: convertNumberToLitteralHour(currentInterval.end+0.5) });
        currentInterval = { start: arr[i], end: arr[i] };
      }
    }
  
    // Push the last interval
    result.push({ start: convertNumberToLitteralHour(currentInterval.start), end: convertNumberToLitteralHour(currentInterval.end+0.5) });
  
    return result;
}

function ungroupIntervalInNumbers(intervals: {start: string, end: string}[]){

    if(intervals.length === 0){
        return []
    }

    intervals = intervals.map((a: {start: string, end: string}) => ({
        start: convertLitteralHourToNumber(a.start),
        end: a.end === "23:59" ? convertLitteralHourToNumber("24:00") : convertLitteralHourToNumber(a.end)
    }))

    const result = [];

    intervals.forEach((interval) => {
        const { start, end } = interval;

        if (end-0.5 >= start + 0.5) {
        // Ajouter le début de l'intervalle
        result.push(start);

        // Décomposer l'intervalle par incrément de 0.5
        let current = start + 0.5;
        while (current <= end-0.5) {
            result.push(current);
            current += 0.5;
        }
        } else {
        // Si l'intervalle ne satisfait pas la condition, ajouter simplement le début
        result.push(start);
        }
    });

    return result;
}

export const RegularAvailabilitiesContext = createContext({
    selectedRegularItems: null,
    handleChangeSelectedItems: () => {}
})

const DEFAULT_REGULAR_OBJECT = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
}

const DAYS = ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"]

type Props = {
    staff: {id: string, sectorId: number, fullName: string},
    close(): void,
    closeAndReload(): void
}

const RegularStaffAvailabilitiesViewer: React.FC<Props> = (props) => {

    const {staff, close, closeAndReload} = props
    const isShiftDown = useKeyPress('Shift');

    const [selectedRegularItems, setSelectedRegularItems] = useState(Object.assign({}, DEFAULT_REGULAR_OBJECT))
    const [savedCurrentRegularItems, setSavedCurrentRegularItems] = useState(Object.assign({}, DEFAULT_REGULAR_OBJECT))
    const [savedAvailabilitiesEvents, setSavedAvailabilitiesEvents] = useState([])

    useEffect(() => {
        if(!!staff?.id){
            AvailabilitiesAPI.getAvailabilitiesByQuery("regular", `staffId==${staff.id}`)
            .then(availabilities => {
                let _defaultObject = Object.assign({}, DEFAULT_REGULAR_OBJECT),
                    _parsedObject = Object.assign({}, DEFAULT_REGULAR_OBJECT)

                availabilities.forEach(av => {
                    _defaultObject[av.interval.dayOfWeek] = [
                        ..._defaultObject[av.interval.dayOfWeek],
                        {
                            start: av.interval.start.slice(0, -3),
                            end: av.interval.end.slice(0, -3)
                        }
                    ]
                })
                
                _defaultObject = Object.keys(_defaultObject).forEach(key => {
                    _parsedObject[key] = ungroupIntervalInNumbers(_defaultObject[key])
                })

                setSavedAvailabilitiesEvents(availabilities)
                setSavedCurrentRegularItems(_parsedObject)
                setSelectedRegularItems(_parsedObject)
            })
        }
    }, [staff])
    

    function handleChangeSelectedItems (day: number, events: number[]){
        setSelectedRegularItems({
            ...selectedRegularItems,
            [day]: events
        })
    }

    function createRegularEventsWithIntervals(intervals, dayIndex): Promise<any>{
        
        let _intervals = groupByInterval(intervals)

        if(!_intervals.length)
            return new Promise((res, rej) => res(null))

        return Promise.all(
            _intervals.map(interval => (
                AvailabilitiesAPI.createAvailability("regular", {
                    staffId: parseInt(staff.id),
                    sectorId: null,
                    capacity: 1,
                    comment: "",
                    interval: {
                        dayOfWeek: parseInt(dayIndex),
                        start: interval.start,
                        end: interval.end === "24:00:00" ? "23:59:59" : interval.end,
                    }
                })
            ))
        )
    }

    const handleSubmit = () => {

        Object.keys(selectedRegularItems).forEach((dayIndex, index) => {
            
            let _currentAvailabilitiesForDayIndex = savedAvailabilitiesEvents.filter(av => av.interval.dayOfWeek === parseInt(dayIndex))

            if(!!_currentAvailabilitiesForDayIndex.length){ //If we already have selected items on day index and availabilities already existed in this day 
                
                if(savedCurrentRegularItems[dayIndex].sort((a,b) => a-b) !== selectedRegularItems[dayIndex].sort((a,b) => a-b)){ //Check if ungroup existed availabilities and selectedItems are different

                    Promise.all(
                        _currentAvailabilitiesForDayIndex
                            .map(currentAv => AvailabilitiesAPI.deleteAvailability("regular", currentAv.id))
                    )
                    .then(() => 
                        createRegularEventsWithIntervals(selectedRegularItems[dayIndex], dayIndex)
                        .then(closeAndReload)
                        .catch(close)
                    )
                } else { //Nothing change
                }
            } else if(!!selectedRegularItems[dayIndex].length && !_currentAvailabilitiesForDayIndex.length){ //Only create
                createRegularEventsWithIntervals(selectedRegularItems[dayIndex], dayIndex)
                .then(closeAndReload)
                .catch(close)
            }
        })
    }

    return (
        <RegularAvailabilitiesContext.Provider value={{
            selectedRegularItems: selectedRegularItems,
            handleChangeSelectedItems
        }}>
            <Container>
                {/* <p>The Shift key is {!isShiftDown ? "not" : ""} pressed</p> */}
                <span className='title'>
                    <i className='fas fa-arrow-left' onClick={close}/>
                    Disponibilité régulières de <b>{staff.fullName}</b>
                </span>

                <span className='shift-text-explain'>Utilisez la touche "SHIFT" pour séléctionner en groupe</span>
                {DAYS.map((day, i) => (
                    <AvailabilityRegularDayItem
                        staff={staff}
                        day={day}
                        isShiftDown={isShiftDown}
                        dayIndex={i+1}
                        dividerItemSize={18}
                        startHour={7}
                        endHour={20}/>
                ))}
                <Row style={{display: 'flex', justifyContent: "end", padding: '10px 20px'}}>
                    <Button onClick={close}>Quitter</Button>
                    <Button color="primary" variant='contained' onClick={handleSubmit}>Valider</Button>{' '}
                </Row>
            </Container>
        </RegularAvailabilitiesContext.Provider>
    );
}

export default RegularStaffAvailabilitiesViewer;