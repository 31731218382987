import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { RegularAvailabilitiesContext } from './RegularStaffAvailabilitiesViewer';

const Container = styled('div')`
    .selectors-header{
        display: flex;
        align-items: end;
        justify-content: space-between;
        border-top: 1px solid #e6e6e6;
        h4{
            font-weight: 400;
            margin-bottom: 0px;
            margin-top: 5px;
        }
        span{
            font-size: 12px;
            cursor: pointer;
        }
    }
    .selectors-container{
        width: 100%;
        height: 50px;
        display: flex;
        .selector-item{
            height: 30px;
            width: calc((100% / ${props => props.dividerItemSize ?? "24"})/2 - 1px);
            background: #ededed;
            border-radius: 2px 0 0 2px;
            position: relative;
            &:after{
                content: attr(data-hour);
                position: absolute;
                bottom: -18px;
                left: 0;
                font-size: 12px;
                color: #A5A5A5;
                font-style: italic;
            }
            &.demihour{
                margin-right: 2px;
                border-radius: 0 2px 2px 0px;
                border-left: 1px solid #c7c7c7;
                &.full-square-selected{
                    border-left: 1px solid #007bff;
                }
            }
            &.selected{
                background: #ACCCF8;
            }
            &.last-selected{
                box-shadow:inset 0px 0px 0px 1px #a3ccf7;
            }
        }
    }
`

type Props = {
    staff: {id: string, sectorId: number, fullName: string},
    day: string,
    dayIndex: number,
    isShiftDown: boolean,
    startHour?: number,
    endHour?: numbr,
    dividerItemSize?:number
}

const AvailabilityRegularDayItem: React.FC<Props> = (props) => {

    const {day, dayIndex, isShiftDown, startHour, endHour, dividerItemSize} = props
    const {selectedRegularItems, handleChangeSelectedItems} = useContext(RegularAvailabilitiesContext)

    const items = Array(48).fill().map((d,i) => ({label: i/2, id: i/2}));
    const [lastSelectedItem, setLastSelectedItem] = useState(null)
    // const [selectedItems, setSelectedItems] = useState([])

    const listEl = useRef(null);
    
    useEffect(() => {
        function handleSelectStart(e) {
            if (isShiftDown) {
                e.preventDefault();
            }
        }
        const listElCurrent = listEl?.current;
        if (!!listElCurrent) {
            listElCurrent.addEventListener("selectstart", handleSelectStart, false);
            return () => {
                listElCurrent.removeEventListener("selectstart", handleSelectStart);
            };
        }
    }, [isShiftDown]);

    function handleSelectItem(value){

        const nextValue = getNextValue(value);
    
        handleChangeSelectedItems(dayIndex, nextValue)
        // setSelectedItems(nextValue)
        setLastSelectedItem(value)
    }

    function getNextValue(value){

        const hasBeenSelected = !selectedRegularItems[dayIndex].includes(value);
    
        if (isShiftDown) {
            const newSelectedItems = getNewSelectedItems(value);
            // de-dupe the array using a Set
            const selections = [...new Set([...selectedRegularItems[dayIndex], ...newSelectedItems])];
        
            if (!hasBeenSelected) {
                return selections.filter(item => !newSelectedItems.includes(item));
            }
        
            return selections;
        }
    
        // if it's already in there, remove it, otherwise append it
        return selectedRegularItems[dayIndex].includes(value)
          ? selectedRegularItems[dayIndex].filter(item => item !== value)
          : [...selectedRegularItems[dayIndex], value];
    }

    function getNewSelectedItems(value) {

        const currentSelectedIndex = items.findIndex(item => item.id === value);
        const lastSelectedIndex = items.findIndex(
          item => item.id === lastSelectedItem
        );
    
        return items
          .slice(
            Math.min(lastSelectedIndex, currentSelectedIndex),
            Math.max(lastSelectedIndex, currentSelectedIndex) + 1
          )
          .map(item => item.id);
      }

    return (
        <Container dividerItemSize={dividerItemSize}>
            <div className="selectors-header">
                <h4>{day}</h4>
                <span onClick={() => {
                    handleChangeSelectedItems(dayIndex, [])
                    // setSelectedItems([])
                    setLastSelectedItem(null)
                }}>Effacer</span>
            </div>
            <div className="selectors-container" ref={listEl}>
                {items
                    .filter(hour => ((!!startHour && hour.id >= startHour) || !startHour) && ((!!endHour && hour.id < endHour) || !endHour))
                    .map((item, k) => (
                        <div 
                            key={item.id} 
                            onClick={() => handleSelectItem(item.id)}
                            className={`selector-item 
                                ${!Number.isInteger(item.id) ? "demihour" : ""}
                                ${selectedRegularItems[dayIndex].includes(parseInt(item.id)) ? "full-square-selected" : ""}
                                ${selectedRegularItems[dayIndex].includes(item.id) ? "selected" : ""} 
                                ${lastSelectedItem === item.id ? "last-selected" : ""}`}
                            data-hour={Number.isInteger(item.id) ? item.id : null}
                            id={item.id}>

                        </div>
                    ))}
            </div>
        </Container>
    );
}

export default AvailabilityRegularDayItem;