import Place from "views/planning/Place";
import type {Dict, OptaEnumDict} from "Utils";
import Sector from "components/ClassWrapper/Sector";
import MaterialType from "components/ClassWrapper/MaterialType";
import Material from "components/ClassWrapper/Material";
import UController from "components/Controller/UController";

/**
 * Special call for timeline construction
 * @return {Promise<{placeList: Array<Place>, dict: OptaEnumDict}>}
 */
export const fetchPlaceListWithDict = (): Promise<{
    placeList: Array<Place>,
    dict: OptaEnumDict
}> => Promise.all([
        UController.material.getAllActive(),
        UController.materialType.getDict(),
        UController.sector.getDict(true)
    ])
    .then(([materialArray: Array<Material>,
               materialTypeDict: Dict<MaterialType>,
               sectorDict: Dict<Sector>]) =>
        ({
            placeList: materialArray.map((m: Material): Place => new Place({
                id: m.id,
                title: Material.getCompoundName(m),
                stackItem: true,
                backgroundColor: sectorDict?.[m.sectorId]?.rgbColor,
                rawData: m,
            })),
            dict: {
                sector: sectorDict,
                materialType: materialTypeDict,
            }
        })
    );

/**
 *
 * @returns {Promise<OptaEnumDict>}
 */
export const fetchGlobalDictionary = (): Promise<OptaEnumDict> =>
    Promise.all([
            UController.materialType.getDict(),
            UController.sector.getDict()
        ])
        .then(([materialTypeDict, sectorDict]) => ({
            sector: sectorDict,
            materialType: materialTypeDict
        }));
