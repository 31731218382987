export type ModelId = (string | number);

class BasicModel {
    constructor(o: Object) {
        o = o || {};
        /**
         * @type {?ModelId} negative id means not registered in server
         */
        this.id = o.id;
        /**
         * ISO date time format
         * @type {string} empty if not determined
         */
        this.createdDateTime = o.createdDateTime || "";
        /**
         * ISO date time format
         * @type {string} empty if not determined
         */
        this.lastModifiedDateTime = o.lastModifiedDateTime || "";
        /**
         * <tt>false</tt> if this entity has been deleted
         * @type {boolean}
         */
        this.enabled = typeof o["enabled"] === "boolean" ? o["enabled"] : true;
    }
}

export default BasicModel;

export type BasicModelType = {
    id?: ModelId,
    createdDateTime: string,
    lastModifiedDateTime: string,
    enabled: boolean
}