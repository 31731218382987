import useSidebar from '@optalp/use-sidebar'
import ResourcesAPI from 'api/ResourcesAPI'
import WorkloadCalendarManagment from 'components/Calendars/WorkloadCalendarManagment'
import RegularStaffAvailabilitiesViewer from 'components/Viewers/RegularStaffAvailabilitiesViewer'
import HorizonCalendarViewer from 'components/utility/HorizonCalendarViewer'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils from 'react-day-picker/moment'
import styled from 'styled-components'
import { BottomContainer, Container, LeftSidebarContainer, TopContainer } from '../../assets/styled-components/CalendarContainer'
import UController, { ProfessionDescriptors, AVAILABILITY_SYSTEM } from "components/Controller/UController";
import Profession from "components/ClassWrapper/Profession";
import { addAllToMap, getFullNameWithPrefix } from 'Utils'

export const PopupContainer = styled('div')`
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 99;
    width: 40%;
    min-width: 800px;
    height: calc(100% - 50px);
    background: #fff;
    border-left: 1px solid #C6C6C6;
    overflow-y: auto;
    overflow-x: hidden;
`

function loadResourcesByProfessionIds(professionDict: Dict<Profession>): Promise<{id: String, title: String, children: {id: String, title: String, sectorId: number, professionId: number}[]}[]>{
    if (Object.keys(professionDict).length === 0) return Promise.resolve([]);
    return ResourcesAPI.getResourceByRsql("staff", {
        size: 9999,
        search: `profession.id=in=(${Object.keys(professionDict).join(",")});enabled==true`
    })
        .then((staffs) => Object.values(professionDict)
            .map(prof =>
            ({
                id: `profession_${prof.id}`,
                title: prof.name,
                children: staffs
                    .filter(staff => staff.professionId === prof.id)
                    .map(staff => ({
                        id: `staff_${staff.id}`,
                        title: getFullNameWithPrefix(staff),
                        sectorId: staff.sectorId,
                        professionId: staff.professionId
                    }))
            })
            )
        );
}

const TARGET_SYSTEM : AVAILABILITY_SYSTEM = "AVAILABILITY_STAFF";

const StaffAvailabilityManager = () => {

    const [isViewPattern] = useState(false)
    const availabilitiesCalendarRef = useRef(null);
    const [resources, setResources] = useState([]);
    const [selectedStaffMode, setSelectedStaffMode] = useState(false)
    const [selectedStaffList, setSelectedStaffList] = useState([])
    const [selectedStaff, setSelectedStaff] = useState(null)

    const { open } = useSidebar();

    const [horizon, setHorizon] = useState({
        from: moment().startOf('week'),
        to: moment().endOf('week')
    })

    // -------------------------- 
    // Resources management 
    // -------------------------- 

    useEffect(() => {
        Promise.all([
            UController.planning.getConfig(),
            UController.profession.getDict()
        ]).then(([planningConfig, professionDict]) => {
            // Filter profession
            const selectedProfessionLabels =
                Object.keys(ProfessionDescriptors)
                    .filter(professionEnum => planningConfig.professionAvailabilitySystem[professionEnum] === TARGET_SYSTEM
                        || (!planningConfig.professionAvailabilitySystem[professionEnum] && planningConfig.professionAvailabilitySystemDefault === TARGET_SYSTEM))
                    .map(professionEnum => ProfessionDescriptors[professionEnum].label);
            const filteredProfessionDict = addAllToMap(Object.values(professionDict).filter(p => selectedProfessionLabels.includes(p.name)));
            return loadResourcesByProfessionIds(filteredProfessionDict).then(setResources)
        })
    }, [])

    // -------------------------- 
    // Calendar management
    // -------------------------- 

    const handleUpdateHorizon = () => {
        let view = availabilitiesCalendarRef.current?.getHorizon()
        setHorizon({
            from: moment(view.activeStart),
            to: moment(view.activeEnd).subtract(1, "day")
        })
    }

    const handleLeftCalendarClick = day => {
        availabilitiesCalendarRef.current.goToDate(day)
        setHorizon({
            from: moment(day).startOf("week"),
            to: moment(day).endOf("week"),
        })
    }

    return (
        <>
            <Container fullHeight>
                <TopContainer>
                    {isViewPattern ?
                        <h4 style={{margin: 0}}>Semaine type</h4>
                        : 
                        <HorizonCalendarViewer
                            onPrevClick={() => {availabilitiesCalendarRef.current.goPrevWeek(); handleUpdateHorizon()}}
                            onNextClick={() => {availabilitiesCalendarRef.current.goNextWeek(); handleUpdateHorizon()}}
                            horizon={horizon}
                            currentViewMode="week"
                            translations={{
                                from:"De",
                                to:"A"
                            }}/>
                    }
                    {isViewPattern && 
                        <div>
                            {selectedStaffMode && <span className='text-underline' style={{cursor: 'pointer', marginRight: 20}} onClick={() => {setSelectedStaffMode(false); setSelectedStaffList([])}}>Annuler</span>}
                            <span 
                                onClick={() => {
                                    if(!selectedStaffMode)
                                        setSelectedStaffMode(true)
                                    else 
                                        open()
                                }}
                                className='text-underline' 
                                style={{cursor: 'pointer'}}>
                                    {selectedStaffMode ? `Ajouter des disponibilités pour les agents (${selectedStaffList.length})` : "Séléctionner des agents pour ajouter en groupe"}
                                    
                            </span>
                        </div>
                    }
                    {/* <div>
                        <span className={`label label-lg ${isViewPattern ? "" : "label-blue"} pointer noselect`} onClick={() => setIsViewPattern(false)}>Exceptionnel</span>
                        <span className={`label label-lg ${!isViewPattern ? "" : "label-blue"} pointer noselect`} onClick={() => setIsViewPattern(true)}>Régulier</span>
                    </div> */}
                </TopContainer>
                <BottomContainer>
                    {!isViewPattern && 
                    <LeftSidebarContainer>
                        <DayPicker
                            localeUtils={MomentLocaleUtils}
                            locale='fr'
                            todayButton="Aujourd'hui"
                            selectedDays={horizon.from.toDate()}
                            onTodayButtonClick={handleLeftCalendarClick}
                            onDayClick={handleLeftCalendarClick}
                            fixedWeeks />
                    </LeftSidebarContainer>
                    }
                    <WorkloadCalendarManagment
                        ref={availabilitiesCalendarRef} 
                        resources={resources}
                        horizon={horizon}
                        selectedStaff={selectedStaff}
                        handleSelectStaff={setSelectedStaff}
                        // selectedStaffMode={selectedStaffMode}
                        // selectedStaffList={selectedStaffList}
                        // handleChangeSelectedStaffList={setSelectedStaffList}
                        type={isViewPattern ? "regular" : "exceptional"}/>
                </BottomContainer>
                {!!selectedStaff && 
                    <PopupContainer>
                        <RegularStaffAvailabilitiesViewer 
                            staff={selectedStaff}
                            close={() => setSelectedStaff(null)}
                            closeAndReload={() => {setSelectedStaff(null); availabilitiesCalendarRef.current.reloadTimeline()}}/>
                    </PopupContainer>
                }
            </Container>
        </>
    )
}

export default StaffAvailabilityManager