import React, {useEffect, useState} from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import UController from "components/Controller/UController";

/**
 *
 * @param {function(Profession)} onProfessionChange
 * @param {number} initialProfessionId
 * @param {number} _key to reset
 * @param {Dict<Sector>?} providedDict provided the list instead of fetching from server
 * @return {React.Component}
 * @constructor
 */
const ProfessionPicker = ({onProfessionChange, initialProfessionId, _key, providedDict}) => {
    const [professionId, setProfessionId] = useState(initialProfessionId);
    const [professionDict, setProfessionDict] = useState({});
    useEffect(() => {
        if (!providedDict)
            UController.profession.getDict().then(setProfessionDict).catch(console.error);
        else setProfessionDict(providedDict);
    }, [providedDict]);
    useEffect(() => {
        setProfessionId(professionDict[initialProfessionId] ? initialProfessionId : Object.keys(professionDict)[0]);
    }, [initialProfessionId, professionDict, _key]);
    useEffect(() => {
        professionDict && professionDict[professionId] &&
        onProfessionChange(professionDict[professionId]);
    }, [onProfessionChange, professionId, professionDict]);
    return (
        <FormGroup>
            <Label className="pr-2" >
                Profession :
            </Label>
            {
                Object.keys(professionDict).length === 0 ?
                    <p>Chargement...</p> :
                    <select
                        className="d-inline-block w-auto form-control"
                        value={professionId}
                        onChange={event => setProfessionId(event.target.value)}>
                        {Object.keys(professionDict)
                            .map(id => <option value={id} key={id}>{professionDict[id].name}</option>)}
                    </select>
            }
        </FormGroup>
    );
};

export default ProfessionPicker;