import EnumModel from "components/ClassWrapper/EnumModel";

/**
 * @property {number} authority
 */
export default class Profession extends EnumModel {
    constructor(o: Object) {
        o = o  || {};
        super(o);
        this.authority = o[Profession.AUTHORITY];
    }

    static AUTHORITY = "authority";
}