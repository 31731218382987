import React from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, UncontrolledDropdown} from "reactstrap";
import {getFullNameWithPrefix} from "Utils";
import {AccountController} from "components/Controller/AuthProvider";
import { useKeycloak } from '@react-keycloak/web';
import { useCallback } from 'react';

const UncontrolledDropdownUserMenu= () => {

    const { keycloak } = useKeycloak();
    
    const logout = useCallback(() => {
        keycloak?.logout();
    }, [keycloak])

    return (
            <Nav className="align-items-center d-none d-md-flex"
                    navbar>
                <UncontrolledDropdown nav>
                    <DropdownToggle className="pr-0" nav>
                        <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        <i className="fas fa-user-alt"/>
                                    </span>
                            <Media className="ml-2 d-none d-lg-block">
                                        <span className="mb-0 text-sm font-weight-bold">
                                            {getFullNameWithPrefix(AccountController.getCurrentAccount().staff)}
                                        </span>
                            </Media>
                        </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem href="#pablo" onClick={logout}>
                            <i className="ni ni-user-run"/>
                            <span>Me déconnecter</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
    );
}

export default UncontrolledDropdownUserMenu;