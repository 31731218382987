import React from 'react'
import styled from 'styled-components'


const HorizonDateContainer = styled('div')`
    display: flex;
    align-items: center;
    color: #435F71;
    h4{
        margin: 0 !important;
    }
    span.action-arrow{
        cursor: pointer;
        i{
            font-size: 16px;
            display: flex;
            border-radius: 3px;
            padding: 0 3px;
            transition: .2s background ease-in-out;
            &:hover{
                background: rgba(0,0,0,.08);
            }
            &:active{
                color: #3498db;
            }
            &:first-of-type{
                margin-right: 10px;
            }
        }
    }
    span.week-number{
        background: #bccde0;
        color: #435F71;
        padding: 1px 5px;
        border-radius: 3px;
    }
    h4{
        padding: 0 10px;
        text-align: center;
        font-weight: 500;
    }
`

const HorizonCalendarViewer: React.FC<Props> = (props) => {

    const {onPrevClick, onNextClick, horizon, displayType, currentViewMode, translation, showYear, minDate, maxDate, disableButtons} = props
    return(
        <HorizonDateContainer>
            {!disableButtons && (!minDate || (!!minDate && !minDate.isSame(horizon.from, "day") && minDate.isBefore(horizon.from, "day"))) && <span className="action-arrow" onClick={onPrevClick}><i className="fas fa-chevron-left"/></span>}
            {!disableButtons && (!maxDate || (!!maxDate && !maxDate.isSame(horizon.to, "day") && maxDate.isAfter(horizon.to, "day"))) &&<span className="action-arrow" onClick={onNextClick}><i className="fas fa-chevron-right"/></span>}
            {/* {currentViewMode === "week" && <span className="week-number">S. {weekNumber}</span>} */}
            {currentViewMode === "month" ? <span>{horizon.from.format("MMMM YY")}</span> :
            <h4>
                <span className='font-light'>{!!translation ? translation.from : "Du"}</span> 
                &nbsp;{!!displayType && displayType === "literal" ? horizon.from.format("ddd D MMM") : horizon.from.format("DD/MM")} 
                &nbsp;<span className='font-light'>{!!translation ? translation.to : "au"}</span> 
                &nbsp;{!!displayType && displayType === "literal" ? horizon.to.format("ddd D MMM") : horizon.to.format("DD/MM")} 
                &nbsp;{!!showYear && horizon.to.format('YYYY')}
            </h4>}
        </HorizonDateContainer>
    )
}

export default HorizonCalendarViewer