import AccountForm from 'components/Forms/AccountForm';
import React from 'react';
import styled from "styled-components";
import { KeycloakUserInfo } from 'NewApp';

const Container = styled('div')`
    background: #435F71;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    form{
        width: 80%;
        padding: 20px;
        background: #fff;
    }
`

/**
 *
 * @param {any} keycloakUserInfo in ISO format
 * @param {void} handleSuccess to ignore the year
 * @return {ReactElement}
 */
const AccountInitiator = (props: {keycloakUserInfo: KeycloakUserInfo, handleSuccess: KeycloakUserInfo => void, identityProvider?: string}) => {
    const {keycloakUserInfo, handleSuccess, identityProvider} = props
    
    return (
        <Container>
            <AccountForm
                fromInitiator 
                account={{
                    username: keycloakUserInfo.preferred_username ?? keycloakUserInfo.username,
                    id: keycloakUserInfo.sub,
                    role: keycloakUserInfo.role,
                    staff: null
                }}
                identityProvider={identityProvider}
                handleSuccess={handleSuccess}/>

        </Container>
    )

}

export default AccountInitiator