import React, {useEffect, useState} from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";

/**
 *
 * @param {Object} props
 * @param {function(string)} props.onStartRecurChange take one arg representing the new start date
 * @param {string} props.initialStartRecur in ISO format
 * @param {number} props._key to reset
 * @return {React.Component}
 * @constructor
 */
const StartRecur = ({onStartRecurChange, initialStartRecur, _key}) => {
    const [start, setStart] = useState("");
    useEffect(() => {
        start && onStartRecurChange(start);
    }, [start, onStartRecurChange]);
    useEffect(() => {
        setStart(typeof initialStartRecur === "string" ? initialStartRecur : "");
    }, [initialStartRecur, _key]);
    return (
        <FormGroup>
            <Label>
                Début de récurrence:
            </Label>
            {" "}
            <input
                type="date"
                value={start}
                className="d-inline-block w-auto form-control"
                onChange={event => setStart(event.target.value)}
            />
        </FormGroup>
    );
};

export default StartRecur;