import BasicModel from "components/ClassWrapper/BasicModel";
import EnumModel from "components/ClassWrapper/EnumModel";
import {defVal} from "Utils";

export class SingleRdvPrescription extends BasicModel {
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         * @type {number} default 1
         */
        this.dayIndex = defVal(o["dayIndex"], 1);
        /**
         * @type {number | null} >=0. Number of days a RDV can be delayed max
         */
        this.daysDelayLimit = o.daysDelayLimit;
        /**
         * In minutes
         * @type {number} default 0
         */
        this.treatmentDuration = defVal(o["treatmentDuration"], 0);
        /**
         * In minutes
         * @type {number} default 0
         */
        this.medPrepDuration = defVal(o["medPrepDuration"], 0);
        /**
         *
         * @type {boolean | null}
         */
        this.consultation = defVal(o["consultation"], false);
        /**
         * @type {number | null}
         */
        this.consultationDuration = o["consultationDuration"];
        /**
         * @type {number | null}
         */
        this.installtationDuration = o["installtationDuration"];
        /**
         *
         * @type {boolean}
         */
        this.medicinePreMixAuthorized = defVal(o["medicinePreMixAuthorized"], false);
        /**
         * @type {boolean | null}
         */
        this.medPrepAfterInstallation = o.medPrepAfterInstallation;
        /**
         * @type {?SeatType}
         */
        this.seatTypeRequirement = o.seatTypeRequirement;
    }
}

class Protocol extends EnumModel {
    /**
     *
     * @param {Object} o source. Server response or constructed
     */
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         * @type {number} default -1
         */
        this.sectorId = defVal(o["sectorId"], -1);
        /**
         *
         * @type {Array<SingleRdvPrescription>}
         */
        this.prescriptions = defVal(o["prescriptions"], [])
            .sort((p1: SingleRdvPrescription, p2: SingleRdvPrescription) => p1.dayIndex - p2.dayIndex);
        /**
         * @type {ExternalId?}
         */
        this.externalId = o.externalId;
    }
}

export default Protocol;