import {authCaller as axiosInstance} from "../components/Controller/AuthProvider";

type RegisterAccountPayload = {
    staff: {
        id: number,
    },
    username: string,
    password: string,
    enabled: boolean
}

type RegisterExternalAccountPayload = {
    idpSource: "INTERNAL" | "EXTERNAL",
    idpValue: string,
    staffId: number,
    username: string,
    password: string,
    role: string
}
type PatchAccountPayload = {
    username?: string,
    password?: string,
}

function registerAccount(payload: RegisterAccountPayload): Promise<any>{
    return axiosInstance.post("/accounts/register", payload)
    .then(response => response.data)
}

function updateAccount(accountId: number, payload: PatchAccountPayload): Promise<any>{
    return axiosInstance.patch(`/accounts/${accountId}`, payload)
    .then(response => response.data)
}

function desactivateAccount(accountId: number): Promise<any>{
    return axiosInstance.delete(`/accounts/${accountId}`)
    .then(response => response.data)
}

function reactivateAccount(accountId: number): Promise<any>{
    return axiosInstance.post(`/accounts/${accountId}/enable`)
    .then(response => response.data)
}

function registerAccountFromExternal(payload: RegisterExternalAccountPayload): Promise<any>{
    return axiosInstance.post("/accounts/register/from-external", payload)
    .then(response => response.data)
}

const AccountsAPI = {
    registerAccount,
    updateAccount,
    desactivateAccount,
    reactivateAccount,
    registerAccountFromExternal
}

export default AccountsAPI;