import EnumModel from "components/ClassWrapper/EnumModel";

/**
 * @property {string} rgbColor hexcolor, 4 or 7 character including #
 * @property {ExternalId?} externalId
 */
class Sector extends EnumModel {

    constructor(o: Object) {
        o = o || {};
        super(o);
        this.rgbColor = o["rgbColor"];
        /**
         * @type {ExternalId?}
         */
        this.externalId = o.externalId;
    }
}

export default Sector;