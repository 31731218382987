import BasicModel from "components/ClassWrapper/BasicModel";

class Staff extends BasicModel {
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         *
         * @type {string}
         */
        this.lastName = o["lastName"];
        /**
         *
         * @type {string}
         */
        this.firstName = o["firstName"];
        /**
         *
         * @type {string} "F" or "M"
         */
        this.sex = o["sex"] === "F" ? "F" : "M";
        /**
         *
         * @type {number}
         */
        this.sectorId = o["sectorId"];
        /**
         *
         * @type {number}
         */
        this.professionId = o["professionId"];
        /**
         * @type {ExternalId}
         */
        this.externalId = o.externalId;
        /**
         * @type {string | null}
         */
        this.rgbColor = o.rgbColor;
    }
}

export default Staff;