/**
 * Describes works
 */
import {defVal} from "Utils";
import BasicModel from "components/ClassWrapper/BasicModel";

class Session extends BasicModel {
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         * @type {number}
         */
        this.professionId = defVal(o["professionId"], -1);
        /**
         * @type {number}
         */
        this.sectorId = defVal(o["sectorId"], -1);
        /**
         * @type {Array<CompressedTimeInterval>}
         */
        this.intervals = [];
        // Reformat intervals to cut loose of seconds (XX:XX)
        o["intervals"].forEach(i => {
            i.start = i.start.substring(0, 5);
            i.end = i.end.substring(0, 5)
        });
        // Sort before merging, in increasing order of start then end
        o["intervals"].sort((i1, i2) =>
            i1["start"] < i2["start"] ? -1 :
                i1["start"] > i2["start"] ? 1 :
                    i1["end"] < i2["end"] ? -1 :
                        i1["end"] > i2["end"] ? 1 :
                            0
        );
        o["intervals"].forEach((interval, index) => {
            if (this.intervals.length > 0) {
                let lastInterval = o["intervals"][index - 1];
                if (lastInterval.start === interval.start && lastInterval.end === interval.end) {
                    this.intervals[this.intervals.length - 1].effective++;
                    return;
                }
            }
            this.intervals.push({
                start: interval["start"],
                end: interval["end"],
                effective: 1,
            });
        });
        /**
         *
         * @type {{horizon: GenericTimeInterval, daily: GenericTimeInterval}}
         */
        this.range = {
            /**
             * @type {GenericTimeInterval} day of start and end for all occurrences
             */
            horizon: {
                start: o["range"]["start"],
                end: o["range"]["end"],
            },
            /**
             * @type {GenericTimeInterval} time of start and end for one occurrence
             */
            daily: {
                start: this.intervals[0].start + "",
                end: null,
            },
        };
        this.range.daily.end = this.intervals[0].end;
        // Find the latest interval end
        this.intervals.forEach(interval => {
            if (this.range.daily.end < interval.end)
                this.range.daily.end = interval.end + "";
        });
        /**
         * In conform of RFC-5545. Without UNTIL and DTSTART
         * @type {string}
         */
        this.recurrenceRule = o["recurrenceRule"];
        /**
         * Can be empty
         * @type {Array<string>} dates in ISO format
         */
        this.exdates = o["exdates"];
        /**
         * @type {?string}
         */
        this.name = o.name;
        /**
         * @type {?string}
         */
        this.description = o.description;
    }
}

export default Session;
