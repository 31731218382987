import { FormGroup, MenuItem, Select, Input, Button, Radio, FormControlLabel, RadioGroup } from "@material-ui/core"
import { REGEX_FULLSTRING, ROLE_TRANSLATE } from "Constants";
import ResourcesAPI from "api/ResourcesAPI"
import StaffsAPI from "api/StaffsAPI";
import AccountsAPI from "api/AccountsAPI";
import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Label
} from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import StaffPicker from "components/picker/StaffPicker";
import { styled } from 'styled-components';
import Staff from "components/ClassWrapper/Staff";
import Account from "components/ClassWrapper/Account";
import { FormattedStaff } from "NewApp";

const ROLE_LIST = ["NURSE", "PHARMACIST", "DOCTOR", "CARE_GIVER", "SHARED_ACCESS", "SECRETARIAT"]

const StaffPickerContainer = styled('div')`
    padding: 20px 0;
    .text-explaination{
        margin: 0;
        font-weight: 500;
        text-decoration: underline;
    }
    .picker{
        display: flex;
        align-items: center;
        .react-autosuggest__container{
            width: 40%;
        }
        .selected-staff-text{
            padding-left: 20px;
            margin: 0;
        }
    }
`

/**
     *
     * @param {StaffPayload} staff in ISO format
     * @param {any} account in ISO format
     * @param {boolean} isEdit in ISO format
     * @param {boolean} fromInitiator in ISO format
     * @return {string}  
    */
function formValidator(staff, account, isEdit, fromInitiator) {
    if (!REGEX_FULLSTRING.test(staff.firstName)
        || !REGEX_FULLSTRING.test(staff.lastName)
        || !REGEX_FULLSTRING.test(staff.sex)
        || !staff.sector
        || !staff.profession
        || !((isEdit || fromInitiator) || (!!account.password && !!account.passwordConfirm))
    )
        return "Veuillez remplir tout le formulaire, et vérifier les valeurs des champs."

    if (!REGEX_FULLSTRING.test(account.username))
        return "L'adresse email n'est pas valide"

    if (!fromInitiator && account.password !== account.passwordConfirm)
        return "Les 2 mots de passes ne sont pas identiques"

    return "OK"
}

type Props = {
    account: {
        staff: FormattedStaff;
        username: string;
        password?: string;
        passwordConfirm?: string;
        enabled: boolean;
        id: string;
    },
    handleSuccess: () => void,
    fromInitiator?: boolean,
    identityProvider?: string
}

const AccountForm = (props: Props) => {

    const [error, setError] = useState("")
    const [sectors, setSectors] = useState([])
    const [professions, setProfessions] = useState([])
    const { keycloak } = useKeycloak();

    const [staff, setStaff] = useState({
        firstName: "",
        lastName: "",
        sex: null,
        sector: null,
        profession: null
    })

    const [account, setAccount] = useState({
        staff: null,
        username: null,
        password: null,
        passwordConfirm: null,
        enabled: true,
        role: null,
        id: null
    })

    useEffect(() => {
        Promise.all([
            ResourcesAPI.getResourceByRsql("sector", { search: "enabled==true" }),
            ResourcesAPI.getProfessions()
        ])
            .then(([sectors, professions]) => {
                setSectors(sectors)
                setProfessions(professions)
            })
    }, [])

    useEffect(() => {
        if (!!props.account) {
            setAccount(account => ({
                ...account,
                staff: props.account.staff,
                id: props.account.id,
                username: props.account.username,
                role: props.account?.role
            }))
            if (!!props.account?.staff)
                setStaff(staff => ({
                    ...staff,
                    firstName: props.account.staff.firstName,
                    lastName: props.account.staff.lastName,
                    sex: props.account.staff.sex,
                    sector: props.account.staff.sectorId,
                    profession: props.account.staff.professionId,
                    externalId: {
                        value: props.account.staff.externalId?.value
                    }
                }))
        }
    }, [props.account]);

    const handleInputChange = (e, name, type: "staff" | "account" | "externalId") => {
        switch (type) {
            case 'account':
                setAccount({ ...account, [name]: e.currentTarget.value })
                break;
            case 'staff':
                setStaff({ ...staff, [name]: e.currentTarget.value })
                break;
            case 'externalId':
                setStaff({ ...staff, externalId: { [name]: e.currentTarget.value } })
                break;
            default:
                break;
        }
    }

    function createStaffAndAccount(staff: Staff, account: Account) {
        StaffsAPI.createStaff({
            ...staff,
            sector: `/api/sectors/${staff.sector}`,
            profession: `/api/professions/${staff.profession}`,
        })
            .then(staff =>
                AccountsAPI.registerAccount({
                    staff: {
                        id: staff.id
                    },
                    role: `${account.role}`,
                    username: account.username,
                    password: account.password,
                    enabled: true
                })
                    .then(props.handleSuccess)
                    .catch(() => setError("Impossible de créer le compte associé au profil"))
            )
            .catch(() => setError("Impossible de créer le profil de l'utilisateur"))
    }

    function createStaffFromExternal(staff, account) {
        if (staff.id) {
            StaffsAPI.patchStaff(staff.id, {
                ...staff,
                sector: `/api/sectors/${staff.sector}`,
                profession: `/api/professions/${staff.profession}`,
            }).then(staff => {
                setStaff(staff);
                return AccountsAPI.registerAccountFromExternal({
                    idpSource: "EXTERNAL",
                    idpValue: props.account.id,
                    staffId: staff.id,
                    username: account.username,
                    role: `${props.account.role}`
                })
                    .then(props.handleSuccess)
                    .catch(() => setError("Impossible de créer le compte associé au profil"))
            }
            )
                .catch(() => setError("Impossible de mettre à jour le profil de l'utilisateur"))
        } else {
            StaffsAPI.createStaff({
                ...staff,
                sector: `/api/sectors/${staff.sector}`,
                profession: `/api/professions/${staff.profession}`,
            })
                .then(staff => {
                    setStaff(staff);
                    return AccountsAPI.registerAccountFromExternal({
                        idpSource: "EXTERNAL",
                        idpValue: props.account.id,
                        staffId: staff.id,
                        username: account.username,
                        role: `${props.account.role}`
                    })
                        .then(props.handleSuccess)
                        .catch(() => setError("Impossible de créer le compte associé au profil"))
                })
                .catch(() => setError("Impossible de créer le profil de l'utilisateur"))
        }
    }


    function patchStaffAndAccount(staff, account) {
        StaffsAPI.patchStaff(props.account.staff.id, {
            ...staff,
            sector: `/api/sectors/${staff.sector}`,
            profession: `/api/professions/${staff.profession}`,
        })
            .then(staff =>
                AccountsAPI.updateAccount(props.account.id, {
                    role: `${account.role}`,
                    username: account.username,
                    password: account?.password ?? undefined,
                })
                    .then(props.handleSuccess)
                    .catch(() => setError("Impossible de modifier le compte associé au profil"))
            )
            .catch(() => setError("Impossible de modifier le profil de l'utilisateur"))
    }

    const handleSubmit = () => {
        setError(null)
        let _isEdit = !!props.account?.id && !!props.account?.staff?.id

        if (!staff.firstName || !staff.lastName || !staff.profession || !staff.sector || !staff.sex || !account.username || !((_isEdit || props.fromInitiator) || (!!account.password && !!account.passwordConfirm))) {
            setError("Veuillez remplir tous les champs du formulaire")
            return
        }

        let _validator = formValidator(staff, account, _isEdit, props.fromInitiator)

        if (_validator !== "OK") {
            setError(_validator)
        } else {
            if (_isEdit) /** Edit current staff and account  */
                patchStaffAndAccount(staff, account)
            else  /** Create staff and account after  */
                if (props.fromInitiator)
                    createStaffFromExternal(staff, account)
                else
                    createStaffAndAccount(staff, account)
        }
    }

    const handleLogout = () => {
        sessionStorage.removeItem('lastLocation');
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("id_token");
        keycloak.logout();
    }

    const handleLinkWithExistingStaff = (staff: Staff) => {
        setStaff({
            firstName: staff.firstName,
            lastName: staff.lastName,
            sex: staff.sex,
            sector: staff.sectorId,
            profession: staff.professionId,
            id: staff.id,
            externalId: {
                value: staff.externalId.value
            }
        })
    }

    return (
        <form>
            {!!props.fromInitiator &&
                <>
                    <Button variant="contained" onClick={handleLogout}>Retour</Button>
                    <h3 className='text-center medium'>Ceci est votre première connexion. Pour accéder à l'application, veuillez remplir vos informations de compte</h3>
                    <StaffPickerContainer>
                        <p className="text-explaination">Votre profile est peut-être déjà créé sans être lié à votre identifiant, effectuez une recherche au préalable.</p>
                        <div className="picker">
                            <StaffPicker
                                handleSelect={handleLinkWithExistingStaff} />
                        </div>
                    </StaffPickerContainer>
                </>
            }
            {!!error && <p style={{ background: "#f5365c", padding: 5, color: "#fff", textAlign: 'center', fontWeight: 400 }}>{error}</p>}
            <h2>Agent</h2>
            <Row>
                <Col md={4}>
                    <FormGroup style={{ marginBottom: 20 }}>
                        <Label className="form-field-required">Prénom</Label>
                        <Input
                            value={staff.firstName}
                            onChange={e => handleInputChange(e, "firstName", "staff")} />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup style={{ marginBottom: 20 }}>
                        <Label className="form-field-required">Nom</Label>
                        <Input
                            required
                            variant="outlined"
                            value={staff.lastName}
                            onChange={e => handleInputChange(e, "lastName", "staff")} />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup style={{ marginBottom: 20 }}>
                        <Label className="form-field-required">Sexe</Label>
                        <RadioGroup row={true} aria-label="gender" name="gender1" color="primary" value={staff.sex} onChange={e => handleInputChange(e, "sex", "staff")} aria-required>
                            <FormControlLabel value="M" control={<Radio color="primary" />} label="Homme" />
                            <FormControlLabel value="F" control={<Radio color="primary" />} label="Femme" />
                        </RadioGroup>
                    </FormGroup>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-field-required">Spécialité</Label>
                        <Select
                            value={staff.sector}
                            onChange={e => setStaff({ ...staff, sector: e.target.value })}>
                            {sectors.map(s => (
                                <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                            ))}
                        </Select>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-field-required">Profession</Label>
                        <Select
                            value={staff.profession}
                            onChange={e => setStaff({ ...staff, profession: e.target.value })}>
                            {professions.map(s => (
                                <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                            ))}
                        </Select>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>Transcodage</Label>
                        <Input
                            variant="outlined"
                            value={staff?.externalId?.value}
                            onChange={e => handleInputChange(e, "value", "externalId")} />
                    </FormGroup>
                </Col>
            </Row>
            <br /><br />
            <h2>Compte</h2>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-field-required">Identifiant de connexion</Label>
                        <Input
                            value={account.username}
                            disabled={props.fromInitiator}
                            onChange={e => handleInputChange(e, "username", "account")} />
                    </FormGroup>
                </Col>
                {!props.fromInitiator &&
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-field-required">Rôle</Label>
                            <Select
                                value={account.role}
                                onChange={e => setAccount({ ...account, role: e.target.value })}>
                                {ROLE_LIST.map(role => (
                                    <MenuItem key={role} value={role}>{ROLE_TRANSLATE[role]}</MenuItem>
                                ))}
                            </Select>

                        </FormGroup>
                    </Col>
                }
            </Row>
            <br />
            {!props.fromInitiator &&
                <>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Mot de passe</Label>
                                <Input
                                    value={account.password}
                                    type='password'
                                    onChange={e => handleInputChange(e, "password", "account")} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Confirmation mot de passe</Label>
                                <Input
                                    value={account.passwordConfirm}
                                    type='password'
                                    onChange={e => handleInputChange(e, "passwordConfirm", "account")} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {!!props.account && !props.fromInitiator &&
                        <h3>Laisser vide si inchanger</h3>
                    }
                </>
            }
            <Button
                color='primary'
                variant="contained"
                className="center"
                onClick={handleSubmit}
                style={{ marginTop: 20 }}>
                {props.fromInitiator ? "Valider mon compte"
                    : !!props.account
                        ? "Modifier le compte"
                        : "Ajouter le compte"}
            </Button>
        </form>
    )

}

export default AccountForm