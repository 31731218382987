import {parseSingleTimeStrToMinutes, stringifyTimeToHourMin} from "Utils";
import Protocol, {SingleRdvPrescription} from "../ClassWrapper/Protocol";
import { BED } from "../ClassWrapper/SeatType";
import Patient from "components/ClassWrapper/Patient";
import OptaConfig from "components/ClassWrapper/OptaConfig";

/**
 * @property {number} id to order in same cycle
 * @property {number} treatmentDuration in minutes
 * @property {number} medPrepDuration in minutes
 * @property {boolean} consultation
 * @property {boolean} medPrePrep
 * @property {number} dayIndex in days
 * @property {string} comment
 * @property {?SeatType} seatTypeRequirement
 */
class RdvPlanningRequest {
}

/**
 * @property {number} id to order in same cycle
 * @property {string} treatmentDuration in hours and minutes
 * @property {string} medPrepDuration in hours and minutes
 * @property {boolean | null} consultation
 * @property {number | null} consultationDuration
 * @property {boolean} consultationInPlace
 * @property {boolean} medPrePrep
 * @property {number} dayIndex in days
 * @property {number | null} daysDelayLimit number of days a rdv can be delayed
 * @property {string} comment
 * @property {?boolean} bedRequirement
 * @property {number | null} preferredSeatGroupId seat group prefered to assign
 */
class RdvPlanningRequestStringified {
    /**
     *
     * @param req
     * @return {RdvPlanningRequest} but duration is transformed to
     */
    static parse = (req: RdvPlanningRequestStringified): RdvPlanningRequest => ({
        ...req,
        consultationDuration: parseSingleTimeStrToMinutes(req.consultationDuration),
        treatmentDuration: parseSingleTimeStrToMinutes(req.treatmentDuration),
        medPrepDuration: parseSingleTimeStrToMinutes(req.medPrepDuration),
        seatTypeRequirement: req.bedRequirement === true ? "BED" : undefined,
    });
}

/**
 * Array of {@link RdvPlanningRequest}
 * @property {Patient} patient
 * @property {Protocol} protocol
 * @property {Motive} motive
 * @property {?string} comment
 * @property {RdvPlanningRequest[]} rdvRequests
 * @property {Staff | null} referentDoctor
 */
class CyclePlanningRequest {
    static getInitialRdvRequestsStringifiedFrom = (chosenProtocol: Protocol, patient: Patient, config: OptaConfig): RdvPlanningRequestStringified[] =>
        chosenProtocol.prescriptions.map((p: SingleRdvPrescription, index: number) => ({
            id: index,
            treatmentDuration: stringifyTimeToHourMin(p.treatmentDuration),
            medPrepDuration: stringifyTimeToHourMin(p.medPrepDuration),
            consultation: p.consultation,
            consultationDuration: p.consultationDuration > 0 ? p.consultationDuration : !!p.consultation ? config.consultationLength : 0,
            consultationInPlace: true,
            medPrePrep: p.medicinePreMixAuthorized,
            medPrepAfterInstallation: p.medPrepAfterInstallation,
            dayIndex: p.dayIndex,
            daysDelayLimit: p.daysDelayLimit,
            comment: "",
            bedRequirement: p.seatTypeRequirement === BED || patient.seatTypeRequirement === BED
        }));
}

export {CyclePlanningRequest as default, RdvPlanningRequest, RdvPlanningRequestStringified};