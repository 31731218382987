import { Button } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { getLocalDateStrFromISO } from 'Utils';
import AvailabilitiesAPI from "api/AvailabilitiesAPI";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import styled from "styled-components";
import Col from "reactstrap/es/Col";
import { FormGroup, Input } from "@material-ui/core"
import {Label} from "reactstrap";

const Container = styled('div')`
    padding: 10px;
    .text-explaination{
        text-align: center;
        font-style: italic;
    }
    .year-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        .year-controller{
            display: flex;
            align-items: center;
            i{
                font-size: .8rem;
                cursor: pointer;
            }
            span{
                margin-left: 5px;
                font-weight: 600;
                font-size: 1.1rem;
            }
        }
    }
    .holidays-table{
        width: 100%;
        tbody, thead{
            tr{
                height: 50px;
                border-bottom: 1px solid #e0e0e0;
            }
        }
        th.centered, td.centered{
            text-align: center;
        }
        td.actions-column {
            svg{
                cursor: pointer;
            }
        }
    }
`

const PublicHolidaysManager = () => {

    const [currentYear, setCurrentYear] = useState(moment().year())
    const [publicHolidaysList, setPublicHolidaysList] = useState([])

    const [publicHolidayAdd, setPublicHolidayAdd] = useState({
        date: moment().format("YYYY-MM-DD"),
        title: null,
    })

    const [isFormOpen, setIsFormOpen] = useState(false)

    const handleUpdateHorizon = (type: "previous" | "next") => {
        if (currentYear === moment().year() && type === "previous")
            return
        setCurrentYear(type === "next" ? currentYear + 1 : currentYear - 1)
    }

    function loadHolidays(currentYear: number) {
        setPublicHolidaysList([])
        Promise.all([
            AvailabilitiesAPI.getAvailabilityAbsenceExceptionalByQuery(`interval.start=ge=${currentYear}-01-01T00:00:00;interval.end=lt=${currentYear + 1}-01-01T00:00:00`),
            AvailabilitiesAPI.getPublicHolidaysFromPublicAPI(currentYear)
        ])
            .then(([absenceExceptionals, publicHolidays]) => {
                let absenceMap = {};
                absenceExceptionals.forEach(absence => {
                    let date = absence.interval.start.substring(0, 10);
                    absenceMap[date] = {
                        date,
                        title: absence.comment,
                        validated: true,
                        id: absence.id,
                        formattedDate: getLocalDateStrFromISO(date, true),
                        isCustomHoliday: absence.comment.includes("[P]")
                    };
                });
                Object.entries(publicHolidays)
                    .filter(([date, title]) => !absenceMap[date])
                    .forEach(([date, title]) => {
                        absenceMap[date] = {
                            date,
                            title,
                            validated: false,
                            id: undefined,
                            formattedDate: getLocalDateStrFromISO(date, true)
                        }
                    });
                setPublicHolidaysList(Object.keys(absenceMap).sort().map(date => absenceMap[date]))
            })
    }

    useEffect(() => {
        if (!!currentYear) {
            loadHolidays(currentYear)
        }
    }, [currentYear])

    function removedAvailabilityAbsence(id?: number, key: number, isCustomHoliday?: boolean) {
        if (!!id)
            AvailabilitiesAPI.deleteAvailabilityAbsenceExceptional(id)
                .then(() => {
                    if(isCustomHoliday){
                        loadHolidays(currentYear)
                        return
                    }
                    let _currentList = [...publicHolidaysList]
                    _currentList[key] = {
                        ..._currentList[key],
                        validated: false
                    }
                    setPublicHolidaysList(_currentList)
                })
    }


    function validatedHoliday(date: string, comment: string, key: number) {
        AvailabilitiesAPI.createAvailabilityAbsenceExceptional({
            comment,
            sectorId: null,
            enabled: true,
            interval: {
                start: `${date}T00:00:00`,
                end: `${date}T23:59:59`
            }
        })
            .then(availabilityAbsence => {
                let _currentList = [...publicHolidaysList]
                _currentList[key] = {
                    ..._currentList[key],
                    id: availabilityAbsence.id,
                    validated: true
                }
                setPublicHolidaysList(_currentList)
            })
    }

    const handleAddPublicHoliday = () => {
        AvailabilitiesAPI.createAvailabilityAbsenceExceptional({
            comment: `[P] ${publicHolidayAdd.title}`,
            sectorId: null,
            enabled: true,
            interval: {
                start: `${publicHolidayAdd.date}T00:00:00`,
                end: `${publicHolidayAdd.date}T23:59:59`
            }
        })
        .then(() => {
            setPublicHolidayAdd({
                date: moment().format("YYYY-MM-DD"),
                title: null,
            })
            setIsFormOpen(false)
            loadHolidays(parseInt(publicHolidayAdd.date.substring(0,4)))
        })
    }

    return (
        <>
            <Col xs={12} lg={6} className="mb-4">
                <Card>
                    <CardHeader>
                        <h2 className="text-white mr-auto">
                            Gestion des jours fériés
                        </h2>
                    </CardHeader>
                    <Container>
                        <p className="text-explaination">Depuis cette interface, vous pouvez importer les jours fériés et valider ceux que vous souhaitez implémenter ou non.</p>
                        <div className="year-container">
                            <div className="year-controller">
                                <Button color='primary' variant="text" onClick={() => handleUpdateHorizon("previous")} style={{ minWidth: 30 }}>
                                    <i className='fas fa-arrow-left' />
                                </Button>
                                <Button color='primary' variant="text" onClick={() => handleUpdateHorizon("next")} style={{ minWidth: 30 }}>
                                    <i className='fas fa-arrow-right' />
                                </Button>
                                <span>Année {currentYear}</span>
                            </div>
                            <Button color='primary' variant="text" onClick={() => setIsFormOpen(!isFormOpen)} style={{ minWidth: 30 }}>
                                Ajout personnalisé
                            </Button>
                        </div>

                        <table className="holidays-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Titre</th>
                                    <th className="centered">Statut</th>
                                    <th className="centered">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {publicHolidaysList.map((holiday, key) => (
                                    <tr key={holiday.date}>
                                        <td>{holiday.formattedDate}</td>
                                        <td>{holiday.title}</td>
                                        <td className="centered">{!holiday.validated ? "Non validé" : "Validé"}</td>

                                        <td className="centered actions-column">
                                            <Button color={!holiday.validated ? "primary" : "secondary"} variant="text" onClick={() => !holiday.validated ? validatedHoliday(holiday.date, holiday.title, key) : removedAvailabilityAbsence(holiday.id, key, holiday.isCustomHoliday)} style={{ minWidth: 30 }}>
                                                {!holiday.validated ? <AddCircleOutline /> : <RemoveCircleOutline />}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <br/>
                        <p className="text-explaination">[P] = Ajout personnalisé</p>
                    </Container>
                </Card>
            </Col>
            {isFormOpen && 
                <Col xs={12} lg={4}>
                    <Card>
                        <CardHeader>
                            <h2 className="text-white mr-auto">
                                Ajouter un jour férié personnalisé
                            </h2>
                        </CardHeader>
                        <Container>
                            <FormGroup>
                                <Label>Date</Label>
                                <Input 
                                    type="date"
                                    value={publicHolidayAdd.date}
                                    onChange={e => setPublicHolidayAdd({...publicHolidayAdd, date: e.currentTarget.value})}/>
                            </FormGroup>
                            <br/>
                            <FormGroup>
                                <Label>Titre</Label>
                                <Input 
                                    value={publicHolidayAdd.title}
                                    onChange={e => setPublicHolidayAdd({...publicHolidayAdd, title: e.currentTarget.value})}/>
                            </FormGroup>
                            <Button 
                                color='primary' 
                                variant="contained" 
                                className="center" 
                                onClick={handleAddPublicHoliday}
                                style={{marginTop: 20}}>Ajouter</Button>
                        </Container>
                    </Card>
                </Col>
            }
        </>
    )
}

export default PublicHolidaysManager

