/**
 * Status of an appointment
 */
export class Status {
    /**
     * SHOULD NOT BE USED OUTSIDE OF STATIC CONTEXT
     * @param id unique id
     * @param name in french to show on text
     * @param icon to symbolize status
     * @private
     */
    constructor(id: string,
                name: string,
                icon: string | string[]) {
        /**
         * @type {string}
         */
        this.id = id;
        /**
         * @type {string} in french to show on text
         */
        this.name = name;
        /**
         * @type {string[]} encoded symbolized status
         */
        this.icon = [];
        if (typeof icon === "string")
            this.icon = [icon];
        else
            this.icon = icon;
    }
}

/**
 * All status of an appointment
 * @readonly
 * @type {Object<String, Status>}
 */
const RdvStatus = {
    CANCELED: new Status("CANCELED", "Annulé", ["fas fa-user-slash"]),
    NOT_ARRIVED: new Status("NOT_ARRIVED", "Non arrivé", ["fas fa-clock"]),
    ON_WAIT: new Status("ON_WAIT", "En attente", ["fas fa-user-clock"]),
    ON_CONSULT: new Status("ON_CONSULT", "En consultation", ["fas fa-stethoscope"]),
    DONE_CONSULT: new Status("DONE_CONSULT", "Fin de consultation", ["fas fa-stethoscope", "fas fa-check"]),
    ON_INSTALL: new Status("ON_INSTALL", "En installation", ["fas fa-user-nurse"]),
    DONE_INSTALL: new Status("DONE_INSTALL", "Fin de l'installation", ["fas fa-user-nurse", "fas fa-check"]),
    ON_MED_PREP: new Status("ON_MED_PREP", "Médicament en fabrication", ["fas fa-vial", "fas fa-clock"]),
    DONE_MED_PREP: new Status("DONE_MED_PREP", "Médicament en livraison", ["fas fa-truck-loading"]),
    DONE_MED_RECEPTION: new Status("DONE_MED_RECEPTION", "Médicament reçu", ["fas fa-vials", "fas fa-check"]),
    ON_TREATMENT: new Status("ON_TREATMENT", "En traitement", ["fas fa-procedures"]),
    DONE_TREATMENT: new Status("DONE_TREATMENT", "Fin de la traitement", ["fas fa-bed", "fas fa-check"]),
    FINISHED: new Status("FINISHED", "Sortie", ["fas fa-door-open"]),
    UNKNOWN: new Status("UNKNOWN", "Etat inconnu", ["fas fa-question"]),
};
export default RdvStatus;

const RdvStatusReduced = {};
Object.keys(RdvStatus).forEach(s => RdvStatusReduced[s] = RdvStatus[s].name);
export {RdvStatusReduced};
