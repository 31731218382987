import React, {useState, useEffect, forwardRef, useImperativeHandle, useRef, useCallback} from 'react'
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import moment from "moment"
import { EventContainer, MainContainer } from 'assets/styled-components/CalendarContainer';
import useSidebar from "@optalp/use-sidebar"
import StaffAvailabilitiesForm from 'components/Forms/StaffAvailabilitiesForm';
import AvailabilitiesAPI from 'api/AvailabilitiesAPI';
import { HeaderContent, timelineToFcEvents } from './calendarTools';

const WorkloadCalendarManagment = forwardRef((props, ref) => {

    const {type, resources, horizon, selectedStaff, handleSelectStaff} = props
    const calendarRef = useRef(null)
    const [events, setEvents] = useState([])
    const { Sidebar, isShowing, open, close } = useSidebar();
    const [selectedSlot, setSelectedSlot] = useState(null)

    const loadTimeline = useCallback(() => {
        Promise.all([
            type !== "regular" ? AvailabilitiesAPI.getAvailabilitiesByQuery("exceptional", `interval.end=gt=(${horizon.from.toISOString(true).substring(0, 19)});interval.start=lt=(${horizon.to.toISOString(true).substring(0, 19)})`) : null,
            AvailabilitiesAPI.getAvailabilitiesByQuery("regular"),
        ])
        .then(([exceptionalEvents, regularEvents]) => {
            setEvents([
                ...timelineToFcEvents(type, "exceptional", exceptionalEvents),
                ...timelineToFcEvents(type, "regular", regularEvents),
            ])
        })
    }, [type, horizon, setEvents])

    useEffect(() => {
        if(!!type && !!resources.length && !!horizon.from && !!horizon.to){
           loadTimeline()
        }
    }, [resources, type, horizon, loadTimeline])

    useImperativeHandle(ref, () => ({
        goPrevWeek(){
            calendarRef.current.getApi().prev()
        },
        goNextWeek(){
            calendarRef.current.getApi().next()
        },
        goToDate(date: any){
            calendarRef.current.getApi().gotoDate(date)
        },
        getHorizon(): any{
            return calendarRef.current.getApi().view;
        },
        reloadTimeline(){
            loadTimeline();
        }
    }), [calendarRef, loadTimeline]);

    const handleDateClick = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect()
        let _resource = selectInfo.resource._resource

        //Disabled add for previous date in exceptional
        if(type === "exceptional" && moment(selectInfo.end).isBefore()){ 
            return;
        }
        

        if(type === "regular"){ 
            //If Regular, disabled add if event overlap 2 days
            if(!moment(selectInfo.start).isSame(moment(selectInfo.end), 'day')){ 
                alert("En mode régulier, il n'est pas possible d'ajouter un évenement qui chevauche 2 jours.")
                return
            }
            createEvent(_resource, selectInfo.start, selectInfo.end, "regular")
        } else {
            //Add Event info for sidebar in exceptional mode 
            setSelectedSlot({ 
                interval: {
                    start: moment(selectInfo.start).add(7,"hour").toDate(),
                    end: moment(selectInfo.end).subtract(4,"hour").toDate()
                },
                resource: _resource
            })
            open()
        }
    }

    function createEvent(resource, start, end, type, comment = null){
        AvailabilitiesAPI.createAvailability(type, {
            staffId: resource.id.split("_")[1],
            sectorId: resource.extendedProps.sectorId,
            capacity: 1,
            comment: comment,
            interval: {
                dayOfWeek: type === "regular" ? moment(start).day() : null,
                start: type === "regular" ? moment(start).format("HH:mm") : start,
                end: type === "regular" ? moment(end).format("HH:mm") : end,
            }
        })
        .then(event => {
            setEvents([
                ...events,
                {
                    color: event.capacity === 1 ? "#3ab350" : "#e94c4c",
                    title: event.comment ?? "",
                    id: event.id,
                    resourceId: `staff_${event.staffId}`,
                    daysOfWeek: type === "exceptional" ? null : [event.interval.dayOfWeek],
                    startTime: type === "exceptional" ? null : event.interval.start,
                    endTime: type === "exceptional" ? null : event.interval.end,
                    start: type === "regular" ? null : event.interval.start,
                    end: type === "regular" ? null : event.interval.end,
                    extendedProps: {
                        eventType: type
                    }
                }
            ])
        })
    }

    function updateEvent(newEvent){
        setEvents(events.map(event => {
            return event.id === newEvent.id ? {
                ...event,
                daysOfWeek: type === "exceptional" ? null : [newEvent.interval.dayOfWeek],
                startTime: type === "exceptional" ? null : newEvent.interval.start,
                endTime: type === "exceptional" ? null : newEvent.interval.end,
                start: type === "regular" ? null : newEvent.interval.start,
                end: type === "regular" ? null : newEvent.interval.end
            } : event
        }))
    }

    function handleDeleteEvent(eventId, eventType){
        AvailabilitiesAPI.deleteAvailability(eventType, eventId)
        .then(() => setEvents(events.filter(event => event.id !== parseInt(eventId))))
    }

    function renderEventContent(eventInfo) {
        const {event} = eventInfo
        return (
            <EventContainer color={event.color}>
                {!(type === "exceptional" && event._def.extendedProps.eventType === "regular") && <b>{moment(event.startStr).format("HH:mm")} - {moment(event.endStr).format("HH:mm")}</b>}
                    {!(type === "exceptional" && event._def.extendedProps.eventType === "regular") &&
                    <div className="event-tooltip">
                        <div className="icons-container">
                            {type === "exceptional" && 
                                <span>
                                    <i className="fas fa-pen" onClick={() => {
                                        setSelectedSlot({ 
                                            id: event._def.publicId,
                                            comment: event.title,
                                            capacity: event._def.extendedProps.capacity,
                                            interval: {
                                                start: event.startStr,
                                                end: event.endStr
                                            },
                                            resource: {
                                                id: event._def.resourceIds,
                                                sectorId: null
                                            }
                                        })
                                        open()
                                    }}/>
                                </span>
                            }
                            <span> 
                                <i className="fa fa-trash" onClick={() => handleDeleteEvent(event._def.publicId, event._def.extendedProps.eventType)} />
                            </span>
                        </div>
                        <b><i>{event.title}</i></b>
                    </div>
                    }   
            </EventContainer>
        )
    }

    function handleEventDropOrResize(e) {
        let _event = e.event._def,
            _start = e.event.startStr,
            _end = e.event.endStr

        if(type === "regular" && !moment(_start).isSame(moment(_end), 'day')){ 
            alert("En mode régulier, il n'est pas possible de modifier un évenement qui chevauche 2 jours.")
            loadTimeline()
            return
        }
        AvailabilitiesAPI.patchAvailability(type, _event.publicId, {
            staffId:_event.resourceIds[0].split("_")[1],
            interval: {
                dayOfWeek: type === "regular" ? moment(_start).day() : undefined,
                start: type === "regular" ? moment(_start).format("HH:mm") : _start.substring(0, 16),
                end: type === "regular" ? moment(_end).format("HH:mm") : _end.substring(0, 16),
            }
        })
        .then(event => updateEvent({
            id: event.id,
            interval: event.interval
        }))
    }

    const resourceContent = (r) => {

        let _isParent = !r.resource._resource.parentId,
            _staffId = !_isParent ?  r.resource._resource.id.split('_')[1] : null,
            _isStaffSelected = selectedStaff?.id === _staffId
 
        let _style = _isParent ? null : {
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: _isStaffSelected ? 700 : 400
        }
    
        return(
            <span className='fc-resource-custom-span' style={{fontSize: 14}}>
                <span 
                    style={_style}
                    onClick={() => handleSelectStaff(_isStaffSelected ? null : {id: _staffId, sector: r.resource.extendedProps.sectorId, fullName: r.fieldValue})}>
                        {r.fieldValue}
                </span>
                <span className='fc-resource-custom-span-square' style={{background: r.resource._resource.extendedProps.color}}></span>
            </span>
        )
    }

    return (
        <>
            <MainContainer>
                <FullCalendar 
                    ref={calendarRef}
                    schedulerLicenseKey={"0806187184-fcs-1601304732"}
                    locale="fr"
                    plugins={[resourceTimelinePlugin, interactionPlugin]}
                    resources={resources}
                    events={events}
                    height={'100%'}
                    resourceAreaWidth="15%"
                    initialView="resourceTimelineWeek"
                    viewClassNames="fc_calendar_timeline"
                    resourceLabelContent={resourceContent}
                    slotLabelContent={e => HeaderContent(e, type)}
                    eventContent={renderEventContent}
                    eventResourceEditable={false}
                    nowIndicator
                    firstDay={1}
                    dayMinWidth={0}
                    headerToolbar={{
                        left: '',
                        center: '',
                        right: ''
                    }}
                    selectable
                    editable
                    droppable
                    select={handleDateClick}
                    eventDrop={handleEventDropOrResize}
                    eventResize={handleEventDropOrResize}
                    // slotMinTime="07:00:00"
                    // slotMaxTime="20:00:00"
                    slotDuration="24:00:00"
                    slotMinWidth={0}
                    eventMinWidth={0}
                />
            </MainContainer>
            <Sidebar
                    isShowing={isShowing}
                    hide={() => {close()}}
                    widthPercentage={40}
                    title={"Disponibilié"}>
                        {!!isShowing && !!selectedSlot && 
                            <StaffAvailabilitiesForm
                             selectedSlot={selectedSlot}
                             closeAndReload={() => {
                                setSelectedSlot(null);
                                close();
                                loadTimeline()
                             }}
                             close={() => {
                                setSelectedSlot(null);
                                close()
                             }}/>
                        }
                </Sidebar>
        </>
    )

})

export default WorkloadCalendarManagment