import {authCaller as axiosInstance} from "../components/Controller/AuthProvider";

export const urlFormEncodedConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
}

function setAxiosToken(token: string) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer '+token;
}

const AuthAPI = {
  setAxiosToken,
}

export default AuthAPI;