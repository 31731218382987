import React from 'react';
import MaterialTable from "material-table";
import type { Filter } from 'material-table';
import Protocol from "components/ClassWrapper/Protocol";
import type { Dict } from "Utils";
import { MATERIAL_TABLE_LOCALIZATION_FR, throwTypeError, parseSingleTimeStrToMinutes } from "Utils";
import UController from "components/Controller/UController";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ProtocolForm from 'components/ProtocolForm';
import { BED } from 'components/ClassWrapper/SeatType';
import OptaConfig from 'components/ClassWrapper/OptaConfig';

const toHoursAndMinutes = (totalMinutes: Number): String => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${minutes > 0 ? `${minutes}m` : ""}`;
}

const _LIMITER = " ";

const _limiterRegex = RegExp(`${_LIMITER}+`, "g");

/**
 *
 * @type {RegExp}
 * @private
 */
const _dayIndexesPatternRegex = RegExp(`J1(${_LIMITER}+J\\d+)*`, "i");
/**
 *
 * @type {RegExp}
 * @private
 */
const _booleanArrayRegex = RegExp(/[ON]+/i);


/**
 *
 * @type {RegExp}
 * @private
 */
const _timeStrRegex = RegExp(`\\d+(h\\d*)?(${_LIMITER}+\\d+(h\\d*)?)*`, "i");

/**
 *
 * @param timePatternStr
 * @returns {number[]}
 * @private
 * @throws {TypeError} if input string does not follow pattern
 */
const _parseTimePatternStr = (timePatternStr: string): number[] =>
    !timePatternStr || !_timeStrRegex.test(timePatternStr.trim())
        ? throwTypeError(`L'entrée '${timePatternStr}' ne conforme pas au format '${_timeStrRegex.toString()}'`)
        : timePatternStr.trim().split(_limiterRegex).map(parseSingleTimeStrToMinutes);

/**
 *
 * @param booleanArrayStr
 * @returns {Array<boolean>}
 * @private
 * @throws {TypeError} if input string does not follow format
 */
const _parseBooleanPatternStr = (booleanArrayStr: string): Array<boolean> => !booleanArrayStr || !_booleanArrayRegex.test(booleanArrayStr.trim())
    ? throwTypeError(`L'entrée '${booleanArrayStr}' ne conforme pas au format '${_booleanArrayRegex.toString()}'`)
    : booleanArrayStr.trim().split("").map(c => c === "O");

const _parseDayIndexesStr = (dayIndexesStr: string): Array<number> => {
    if (!dayIndexesStr || !_dayIndexesPatternRegex.test(dayIndexesStr.trim()))
        throwTypeError(`Les indices des jours '${dayIndexesStr}' ne conforme pas au format '${_dayIndexesPatternRegex.toString()}'`);
    let dayIndexes = dayIndexesStr.trim()
        .split(_limiterRegex)
        .map(j => j.replace(/J/gi, ""))
        .map(j => parseInt(j, 10));
    if (dayIndexes.some((v, idx) => idx > 0 && dayIndexes[idx - 1] >= v))
        throwTypeError(`Les indices des jours '${dayIndexesStr}' doit dans l'ordre croissant`);
    return dayIndexes;
};


/**
 * Flatten version of Protocol
 */
type ProtocolFlatten = {
    id: number,
    name: string,
    sectorId: number,
    /**
     * In html
     */
    dayIndex: string,
    consultation: string,
    medPrePrep: string,
    treatmentDuration: string,
    medPrepDuration: string,
}

const getPrescriptionsFrom = ({
    dayIndex,
    consultation,
    medPrePrep,
    medPrepDuration,
    treatmentDuration
}: ProtocolFlatten): Array<SingleRdvPrescription> => {
    let dayIndexes = _parseDayIndexesStr(dayIndex),
        consultations = _parseBooleanPatternStr(consultation),
        medPrePreps = _parseBooleanPatternStr(medPrePrep),
        medPrepDurations = _parseTimePatternStr(medPrepDuration),
        treatmentDurations = _parseTimePatternStr(treatmentDuration);
    if (dayIndexes.length !== consultations.length)
        throwTypeError("Le motif de consultations ne correspond pas au motif de traitement");
    if (dayIndexes.length !== medPrePreps.length)
        throwTypeError("Le motif de préparations de médicament ne correspond pas au motif de traitement");
    if (dayIndexes.length !== medPrepDurations.length)
        throwTypeError("Le motif de durées de préparation de médicament ne correspond pas au motif de traitement");
    if (dayIndexes.length !== consultations.length)
        throwTypeError("Le motif de durées de traitement ne correspond pas au motif de traitement");
    return dayIndexes.map((_, idx) => new SingleRdvPrescription({
        dayIndex: dayIndexes[idx],
        treatmentDuration: treatmentDurations[idx],
        medPrepDuration: medPrepDurations[idx],
        consultation: consultations[idx],
        medicinePreMixAuthorized: medPrePreps[idx]
    }));
};

const flatten = (protocol: Protocol): ProtocolFlatten => {
    let presecriptions = protocol.prescriptions.sort((a, b) => a.dayIndex - b.dayIndex), _arr = []
    presecriptions.forEach((session, sessionIdx) => {
        if (sessionIdx > 0 && (presecriptions[sessionIdx].dayIndex - presecriptions[sessionIdx - 1].dayIndex) > 2) {
            _arr.push(<div className="protocol-item-main"><span className="protocol-day-item concat-item">...</span></div>)
        }
        _arr.push(
            <div className="protocol-item-main">
                <span
                    data-for={`protocol-${session.id}-tip-day-${session.dayIndex}`}
                    className={`protocol-day-item${sessionIdx === 0 ? " first-item" : ""}${sessionIdx === presecriptions.length - 1 ? " last-item" : ""}`}>{session.dayIndex}</span>
                <div className="protocol-tooltip">
                    {(!!session.consultation || session.consultationDuration > 0) && <span>Consultation</span>}
                    {!!session.medicinePreMixAuthorized && <span>Anticipé</span>}
                    <span>Prep : <strong>{toHoursAndMinutes(session.medPrepDuration)}</strong></span>
                    <span>Durée : <strong>{toHoursAndMinutes(session.treatmentDuration)}</strong> {session.seatTypeRequirement === BED && <i className="fas fa-bed"/>}</span>
                    {session.daysDelayLimit !== null && <span>Délai toléré: {session.daysDelayLimit}J</span>}
                </div>
            </div>
        )
    })
    return ({
        ...protocol,
        dayIndex: _arr,
        // consultation: _stringifyBooleanPattern(prescriptions.map(s => s.consultation)),
        // medPrePrep: _stringifyBooleanPattern(prescriptions.map(s => s.medicinePreMixAuthorized)),
        // medPrepDuration: _stringifyTimeArray(prescriptions.map(s => s.medPrepDuration)),
        // treatmentDuration: _stringifyTimeArray(prescriptions.map(s => s.treatmentDuration)),
    })
};

/**
 * Interactive list for protocols management
 */
class SyncProtocolsList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tableRef: React.createRef(),
            modal: false,
            selectedProtocol: null
        }
        this.toggle = this.toggle.bind(this);
    }



    props: {
        sectorDict: Dict<string>,
        config: OptaConfig,
    };

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    static defaultProps = {};

    convertTableDataIntoProtocol = (data: Object): Protocol =>
    ({
        id: data.id,
        name: data.name,
        sectorId: parseInt(data["sectorId"], 10),
        prescriptions: getPrescriptionsFrom(data),
    });

    onRowAdd = (newData: Object) => Promise.resolve(newData)
        .then(this.convertTableDataIntoProtocol)
        .then(UController.post)
        .catch(error => {
            alert(error.message);
            return Promise.reject(error);
        });

    onRowDelete = (oldData: Protocol) => UController.delete(oldData);

    /**
     *
     * @param newData old data with changes applied, must have the same id, but no method preserved
     * @return {Promise<Protocol | never>} the newly version of entity
     */
    onRowUpdate = (newData: Object) => Promise.resolve(newData)
        .then(this.convertTableDataIntoProtocol)
        .then(UController.post)
        .catch(error => {
            alert(error.message);
            return Promise.reject(error);
        });

    onDataQuery = (query: {
        pageSize: number,
        page: number,
        orderBy: { field: string },
        orderDirection: string,
        filters: Filter[]
    }): Promise<{
        data: ProtocolFlatten[],
        page: number,
        totalCount: number
    }> => {
        let filters = query.filters;
        // Build search query
        let searchQuery = [];
        if (filters.length > 0)
            filters.forEach(filter => {
                switch (filter.column.field) {
                    case "name":
                        // Find all protocol including given input
                        searchQuery.push("name=eqnc=\"*" + filter.value.trim().replace(/\s+/, "*") + "*\"");
                        break;
                    case "sectorId":
                        if (filter.value.length > 0)
                            searchQuery.push("sector.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "externalId.value":
                        if (filter.value.length > 0)
                            searchQuery.push("externalId.value=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    default:
                        break;
                }
            });
        return UController.protocol.iQuery(query, searchQuery)
            .then(protocolPage => ({
                data: protocolPage.data.map(flatten),
                page: protocolPage.page,
                totalCount: protocolPage.totalCount,
            }));
    };

    title = "Protocoles";

    render() {
        // noinspection JSUnresolvedFunction
        return (
            <>
                <MaterialTable
                    title={this.title}
                    columns={[
                        {
                            field: "id",
                            hidden: true,
                        },
                        {
                            field: "name",
                            title: "Nom",
                            searchable: true,
                            filtering: true,
                        },
                        {
                            field: "sectorId",
                            title: "Spécialité",
                            emptyValue: "",
                            sorting: false,
                            lookup: this.props.sectorDict,
                        },
                        {
                            field: "dayIndex",
                            title: "Déroulement (Jours)",
                            initialEditValue: "J1 J2 J3",
                            filtering: false,
                            sorting: false,
                        },
                        {
                            field: "externalId.value",
                            title: "Transcodage",
                            type: "string",
                            searchable: true,
                        }
                    ]}
                    data={this.onDataQuery}
                    tableRef={this.state.tableRef}
                    editable={{
                        onRowDelete: this.onRowDelete,
                    }}
                    options={{
                        filtering: true,
                        grouping: false,
                        search: false,
                        debounceInterval: 1000,
                        draggable: false,
                    }}
                    actions={[
                        {
                            icon: "description",
                            tooltip: "Exporter",
                            isFreeAction: true,
                            onClick: () => UController.data.downloadProtocols({ filename: "Protocoles.csv", charset: "ISO-8859-1", separator: ";" })
                        },
                        {
                            icon: "refresh",
                            tooltip: "Rafraîchir",
                            isFreeAction: true,
                            onClick: () => this.state.tableRef.current && this.state.tableRef.current.onQueryChange()
                        },
                        {
                            icon: "add",
                            tooltip: "Ajouter",
                            isFreeAction: true,
                            onClick: () => this.setState({ selectedProtocol: null }, this.toggle)
                        },
                        (rowData) => {
                            return {
                                icon: "edit",
                                tooltip: "Modifier",
                                onClick: (event, rowData) => this.setState({ selectedProtocol: rowData }, this.toggle)
                            };
                        }
                    ]}
                    localization={MATERIAL_TABLE_LOCALIZATION_FR}
                />
                <Modal style={{ maxWidth: '1200px', width: '100%' }} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Ajouter un protocole</ModalHeader>
                    <ModalBody>
                        <ProtocolForm
                            sectorDict={this.props.sectorDict}
                            config={this.props.config}
                            protocol={this.state.selectedProtocol}
                            handleClose={() => {
                                this.toggle();
                                this.state.tableRef.current && this.state.tableRef.current.onQueryChange()
                            }} />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

/**
 * Asynchronize protocol management list
 */
class ProtocolsList extends React.PureComponent {
    render() {
        return (
            <AsyncLoaderWrapper loader={() => Promise.all([
                UController.sector.getNameDict(),
                UController.planning.getConfig(),
            ]).then(([sectorDict, config]) => ({ sectorDict, config }))}
                onLoadingMessage={"En cours de chargement de protocoles"}
                onErrorMessage={"Erreur de chargement de protocoles"}>
                <SyncProtocolsList />
            </AsyncLoaderWrapper>
        );
    }
}

export default ProtocolsList;