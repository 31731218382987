/**
 * A patient getting treatment
 */
import BasicModel from "components/ClassWrapper/BasicModel";

class Patient extends BasicModel {
    constructor(o: Object) {
        o = o || {};
        super(o);
        /**
         * @type {string?}
         */
        this.firstName = o.firstName;
        /**
         * @type {string?}
         */
        this.lastName = o.lastName;
        /**
         * @type {string?}
         */
        this.partnerName = o.partnerName;
        /**
         * @type {import("./TimeClasses").DateString?}
         */
        this.birthday = o.birthday;
        /**
         * @type {string?}
         */
        this.sex = o.sex;
        /**
         * @type {import("./ExternalId").ExternalId}
         */
        this.externalId = o.externalId;
        /**
         * @type {import("./ContactInfo").ContactInfo?}
         */
        this.contactInfo = o.contactInfo;
        /**
         * @type {import("./SeatType").SeatType?}
         */
        this.seatTypeRequirement = o.seatTypeRequirement;
        /**
         * @type {string?}
         */
        this.comment = o.comment;
        /**
         * @type {import("./Staff").Staff?}
         */
        this.referentDoctor = o.referentDoctor;
    }
}

export default Patient;