import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import {getStompClient} from "components/Controller/WebSocket";
import {isDevEnabled, throwTypeError} from "Utils";
import optaConfig from "config";
import type CycleBatchEvent from "components/ClassWrapper/CycleBatchEvent";
import { Client } from "@stomp/stompjs";

/**
 * @type {M} type of entity to receive as live feed
 */
export interface LiveSubscription {
    /**
     * Should only be called once
     */
    unsubscribe: () => void
}

// eslint-disable-next-line no-unused-vars
export class LiveFeed<M> {

    subscribe = (onNextValue: ((nextValue: M) => void), onError: ?((e: any) => void), onComplete: ?((m: any) => void)): LiveSubscription =>
        throwTypeError("Subclass should implement this method");

    close = (): void => throwTypeError("Subclass must implement this method");
}

type BrokerEndpoint = {
    brokerEndpoint: String,
    messageBodyTransformer: ?((str: String) => T)
};

type Observer<T> = (nextValue: T) => void;

type ObservableBrokerEndpoint<T> = {
    endpoint: BrokerEndpoint,
    observers: Observer<T>[],
}

/**
 * Emit latest changes from server. Late subscriber should make a rest call to get the current state of appoints
 */
class LiveFeedImpl<T> extends LiveFeed<T> {
    // Sharing stomp client
    static _stompClient : Client;
    static _observableEndpoints : ObservableBrokerEndpoint<any>[] = [];
    static initStompClient = () => {
        if (LiveFeedImpl._stompClient) return;
        LiveFeedImpl._stompClient = getStompClient({
            debug: isDevEnabled() ? console.log : () => {}
        });
        LiveFeedImpl._stompClient.beforeConnect = () => {
            LiveFeedImpl._stompClient.configure({
                connectHeaders: {
                    "access-token": localStorage.getItem("token")
                }
            });
        };
    }
    static registerObservableEndpoint = (endpoint: BrokerEndpoint, observers: Observer<T>[]) => {
        LiveFeedImpl.initStompClient();
        LiveFeedImpl._observableEndpoints.push({endpoint, observers});
        // Reset onConnect function
        LiveFeedImpl._stompClient.onConnect = () => {
            LiveFeedImpl._observableEndpoints.forEach(oe => {
                LiveFeedImpl._stompClient.subscribe(oe.endpoint.brokerEndpoint, ({body} : {body: String}) => {
                    const t = oe.endpoint.messageBodyTransformer ? oe.endpoint.messageBodyTransformer(body) : body;
                    oe.observers.forEach(s => s(t));
                });
            })
        };
    }
    /**
     * Should not be used outside
     */
    constructor(endpoint: BrokerEndpoint) {
        super();
        this._observers = [];
        LiveFeedImpl.registerObservableEndpoint(endpoint, this._observers);
    }

    /**
     *
     * @param onNextValue what to do when receive an update
     * @param _ ignored
     * @param __ ignored
     * @returns {LiveSubscription}
     */
    subscribe = (onNextValue: (nextValue: T) => void, _, __): LiveSubscription => {
        this._observers.push(onNextValue);
        if (!LiveFeedImpl._stompClient.active) LiveFeedImpl._stompClient.activate();
        let subscription = {
            _closed: false,
            unsubscribe: undefined,
        };
        subscription.unsubscribe = (): void => {
            if (subscription._closed) return;
            subscription._closed = true;
            this._observers.splice(this._observers.indexOf(onNextValue), 1);
        }
        return subscription;
    };

    /**
     * Detach all observers and close the connection with serer
     */
    close = () => {
        this._observers.length = 0; // Clear all observers
    }

    static close = () => {
        LiveFeedImpl._stompClient.deactivate();
        LiveFeedImpl._observableEndpoints.length = 0; // Clear all endpoints
    }
}

export default class ULiveFeed {
    constructor() {
        throw new TypeError("Unsupported operation");
    }

    static todayRdvs : LiveFeed<CompleteRdv> = new LiveFeedImpl({
        brokerEndpoint: optaConfig.websocket.liveFeed.appoints,
        messageBodyTransformer: body => CompleteRdv.fromServer(JSON.parse(body))
    });

    static cycleBatchUpdates : LiveFeed<CycleBatchEvent> = new LiveFeedImpl({
        brokerEndpoint: optaConfig.websocket.liveFeed.cycleBatch,
        messageBodyTransformer: body => JSON.parse(body)
    })

    static closeAllLiveFeed = () => {
        ULiveFeed.todayRdvs.close();
        ULiveFeed.cycleBatchUpdates.close();
        LiveFeedImpl.close();
    }
}